import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'help-dialog',
    templateUrl: './help-dialog.component.html',
    standalone: true,
    imports: [MatDialogModule, MatButtonModule]
})
// @ts-ignore
export class HelpDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<HelpDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dialogRef.updateSize('300vw', '300vw')
    }
}
