<mat-card>
  <mat-card-header>
    <mat-card-title>Overzicht van te claimen periodes</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="searchForm">
      <mat-form-field>
        <input matInput type="text" name="title" placeholder="titel" formControlName="title">
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-content>
    <table mat-table [dataSource]="fairPeriods" class="mat-elevation-z4">
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Periode</th>
        <td mat-cell *matCellDef="let period">{{ period.description }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let period">
          <app-fair-period-status-badge [period]="period"></app-fair-period-status-badge>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstPayment">
        <th mat-header-cell *matHeaderCellDef>Richtdatum eerste betaling</th>
        <td mat-cell *matCellDef="let period">{{ period.firstPayment | date }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['description', 'status', 'firstPayment']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['description', 'status', 'firstPayment'];"></tr>
    </table>
  </mat-card-content>
</mat-card>
