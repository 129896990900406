export enum DocumentType {
    ID = 'ID', KVK = 'KVK', CONFIRMATION = 'CONFIRMATION', DEBIT_CARD = 'DEBIT_CARD',
    SETTLEMENT_AGREEMENT = 'SETTLEMENT_AGREEMENT', SUMMONS = 'SUMMONS', JUDGMENT = 'JUDGMENT',
    CONFLICT_DOCUMENT = 'CONFLICT_DOCUMENT', REQUEST_ADVISOR = 'REQUEST_ADVISOR', CLAIM_PROOF = 'CLAIM_PROOF'
}

// export namespace DocumentType {
//     export function keys() {
//         return Object.keys(DocumentType).filter(k => !isNaN(Number(k)));
//     }
// }
