import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RightHolder } from '../../shared/model/rightholder';
import { ApiUtil } from '../../shared/util/api.util';
import { Asset } from '../../shared/model/asset';
import { ToastrService } from '../../shared/service/toastr.service';
import { DocumentType } from '../../shared/enums/document-type.enum';
import { FinancialData } from '../../shared/model/financialdata';
import { catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class RightHolderService {

    private endpoint = `${environment.api_endpoint}/rightholders`;

    constructor(private httpClient: HttpClient, private toastrService: ToastrService) {
    }

    public findById(id: number): Observable<RightHolder> {
        return this.httpClient.get<RightHolder>(`${this.endpoint}/${id}`);
    }

    public update(rightHolderId: number, rightholder: RightHolder): Observable<number> {
        return this.httpClient.put<number>(`${this.endpoint}/${rightHolderId}`, rightholder).pipe(catchError(err => {
            this.toastrService.error(`Error [${err.status}] tijdens het opslaan`, 'Rechthebbende');
            console.error(`[RightHolderEditComponent] could not save rightholder ${err.error}`);
            return of(0);
        }));
    }

    public approveWithReason(rightHolderId: number, rightHolder: RightHolder): Observable<number> {
        return this.httpClient.put<number>(`${this.endpoint}/approvewithreason/${rightHolderId}`, rightHolder);
    }

    public resubmit(rightHolderId: number, rightHolder: RightHolder): Observable<number> {
        return this.httpClient.put<number>(`${this.endpoint}/resubmit/${rightHolderId}`, rightHolder);
    }

    public approve(rightHolderId: number, rightHolder: RightHolder): Observable<number> {
        return this.httpClient.put<number>(`${this.endpoint}/approve/${rightHolderId}`, rightHolder);
    }

    public updateFinancialData(rightHolderId: number, changedFinancialData: FinancialData): Observable<number> {
        return this.httpClient.put<number>(`${this.endpoint}/${rightHolderId}/financialdata`, changedFinancialData);
    }

    public approveOrRejectChangesForAuditor(rightHolderId: number, status: FinancialData): Observable<number> {
        return this.httpClient.put<number>(`${this.endpoint}/${rightHolderId}/auditor`, status);
    }

    public approveOrRejectChangesForAccountmanager(rightHolderId: number, status: FinancialData): Observable<number> {
        return this.httpClient.put<number>(`${this.endpoint}/${rightHolderId}/accountmanager`, status);
    }

    public findByDescription(description: string, productionhouses: boolean): Observable<RightHolder[]> {
        return this.httpClient.get<RightHolder[]>(`${this.endpoint}/bydescription/${description}/${productionhouses}`);
    }

    public childs(id: number): Observable<RightHolder[]> {
        return this.httpClient.get<RightHolder[]>(`${this.endpoint}/${id}/childs`);
    }

    public unlinkChild(agent: number, child: RightHolder): Observable<void> {
        const httpParams = new HttpParams().append('agent', agent.toString()).append('child', child.id.toString());
        return this.httpClient.put<void>(`${this.endpoint}/child/unlink`, child, {
            params: httpParams
        });
    }

    public isUnderAgentTomorrow(rightHolder: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.endpoint}/${rightHolder}/underagenttomorrow`);
    }

    public parents(id: number): Observable<RightHolder[]> {
        return this.httpClient.get<RightHolder[]>(`${this.endpoint}/${id}/parents`);
    }

    public findConceptRightHoldersAmount(isFair:boolean): Observable<number> {
        let fair = isFair ? "1" : "0"
        return this.httpClient.get<number>(`${this.endpoint}/conceptamount/${fair}`);
    }
    public findResubmittedRightHoldersAmount(isFair:boolean): Observable<number> {
        let fair = isFair ? "1" : "0"
        return this.httpClient.get<number>(`${this.endpoint}/resubmittedamount/${fair}`);
    }
    public findAmpRightHoldersAmount(isFair:boolean): Observable<number> {
        let fair = isFair ? "1" : "0"
        return this.httpClient.get<number>(`${this.endpoint}/ampquantity/${fair}`);
    }
    public findAllAgents(): Observable<RightHolder[]> {
        return this.httpClient.get<RightHolder[]>(`${this.endpoint}/agents`);
    }

    public findAllActiveRightHoldersByAgent(id: number): Observable<RightHolder[]> {
        return this.httpClient.get<RightHolder[]>(`${this.endpoint}/agent/${id}/activerightholders`);
    }

    public createManager(id: number, rightHolder: RightHolder): Observable<number> {
        return this.httpClient.post<number>(`${this.endpoint}/${id}/manager`, rightHolder);
    }

    public assets(id: number): Observable<Asset[]> {
        return this.httpClient.get<Asset[]>(`${this.endpoint}/${id}/assets`);
    }

    public upload(rightHolderId: number, documentType: DocumentType, file: any): Observable<Asset> {
        const form = new FormData();
        form.append('file', file, file.name);

        return this.httpClient.post<Asset>(`${this.endpoint}/${rightHolderId}/upload?type=${documentType}`, form);
    }

    public removeAsset(rightHolderId: number, asset: Asset): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.endpoint}/${rightHolderId}/assets/${asset.uuid}`);
    }

    public download(rightHolderId: number, asset: Asset) {
        this.toastrService.info('Bezig met ophalen document', '', {
            showDuration: '0',
            hideDuration: '0',
            timeOut: '0',
            extendedTimeOut: '0'
        });

        this.httpClient.get(`${this.endpoint}/${rightHolderId}/assets/${asset.uuid}`, {
            responseType: 'blob',
            observe: 'response',
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).subscribe(response => {
            ApiUtil.saveToFileSystem(response);
            this.toastrService.clear();
            timer(400).subscribe(() => {
                this.toastrService.success('Document opgehaald', null);
            });

        }, response => {
            console.error(response);
        });
    }

    public report(rightHolderId: number, reportName: string) {
        this.toastrService.info('Bezig met ophalen rapport', '', {
            showDuration: '0',
            hideDuration: '0',
            timeOut: '0',
            extendedTimeOut: '0'
        });

        this.httpClient.get(`${this.endpoint}/${rightHolderId}/report`, {
            responseType: 'blob',
            observe: 'response',
            params: {
                name: reportName
            },
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).subscribe(response => {
            ApiUtil.saveToFileSystem(response);
            this.toastrService.clear();
            timer(400).subscribe(() => {
                this.toastrService.success('Rapport is opgehaald', null);
            });

        }, response => {
            console.error(response);
        });
    }
}
