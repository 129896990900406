export enum SpecificationStatus {
    NA = 'NA',
    SHOW = 'SHOW',
    HIDDEN = 'HIDDEN',
    CONCEPT = 'CONCEPT',
    APPROVED = 'APPROVED',
    ACCEPTED = 'ACCEPTED',
    BOOKKEEPING = 'BOOKKEEPING',
    LEDGERUPDATED = 'LEDGERUPDATED',
    PROCESSED = 'PROCESSED',
    PAID_VIA_FAB = 'PAID_VIA_FAB',
    ADMINISTRATIVE_REASON = 'ADMINISTRATIVE_REASON',
    ERROR = 'ERROR',
    FOREIGN = 'FOREIGN',
    LOCKED = 'LOCKED'
}
