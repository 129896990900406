export enum FairPeriodStatus {
    NA = 'NA',
    CREATED = 'CREATED',
    CREATION_FAILED = 'CREATION_FAILED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    RECREATED = 'RECREATED',
    DELETED = 'DELETED',
    CLAIMABLE = 'CLAIMABLE',
    IN_REPARTITION = 'IN_REPARTITION',
    DONE = 'DONE'
}

