<app-loading-shade [loading]="loading"></app-loading-shade>
<table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let work"> {{work.id}} </td>
    </ng-container>

    <ng-container matColumnDef="isan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ISAN</th>
        <td mat-cell *matCellDef="let work"> {{work.isan}} </td>
    </ng-container>

    <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="originalTitle.title">Titel</th>
        <td mat-cell *matCellDef="let work"> {{work.originalTitle?.title}} </td>
    </ng-container>

    <ng-container matColumnDef="subtitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="originalTitle.subTitle">Subtitel</th>
        <td mat-cell *matCellDef="let work"> {{work.originalTitle?.subTitle}} </td>
    </ng-container>

    <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="level">Werk niveau</th>
        <td mat-cell *matCellDef="let work"> {{workUtil.workLevelString(work.level)}} </td>
    </ng-container>

    <ng-container matColumnDef="productionYear">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Productiejaar</th>
        <td mat-cell *matCellDef="let work"><span translate>{{work.productionYear}}</span></td>
    </ng-container>

    <ng-container matColumnDef="genre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="genre.description">Genre</th>
        <td mat-cell *matCellDef="let work"><span translate> {{work.genre.description | uppercase}} </span></td>
    </ng-container>

    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="category.description">Categorie</th>
        <td mat-cell *matCellDef="let work"><span translate>{{work.category.description | uppercase}}</span></td>
    </ng-container>

    <ng-container matColumnDef="workType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="workType.description">Werktype</th>
        <td mat-cell *matCellDef="let work"><span translate>{{work.workType.description | uppercase}}</span></td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let work"><span translate>LABEL.WORKSTATUS.{{work.status}}</span></td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>&nbsp;</th>
        <td mat-cell *matCellDef="let work">
            <button mat-stroked-button type="button" *ngFor="let action of actions" (click)="onAction(work, action);$event.stopPropagation()">{{action.label}}</button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick(row)"></tr>
</table>
<mat-paginator [length]="dataSource.records" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
