import { DecimalPipe, NgStyle } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FairPeriodBFactor } from 'src/app/shared/model/fair-period-bfactor';
import { FairRepertoireItem } from 'src/app/shared/model/fair-repertoire-item';

@Component({
    selector: 'app-edit-item-dialog',
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatInputModule, MatFormField, MatSelectModule, MatButtonModule, NgStyle,
        DecimalPipe, FormsModule],
    templateUrl: './edit-item-dialog.component.html',
    styleUrl: './edit-item-dialog.component.scss'
})
export class EditItemDialogComponent {
    item: FairRepertoireItem;
    options: FairPeriodBFactor[];
    selected: FairPeriodBFactor;

    constructor(public dialogRef: MatDialogRef<EditItemDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { item: FairRepertoireItem, options: FairPeriodBFactor[] }) {
        this.item = data.item;
        this.options = data.options;
    }

    cancel() {
        this.dialogRef.close(null);
    }

    adjust() {
        if (this.selected != null) {
            this.item.newPeriodBFactor = this.selected;
            this.item.correctedPoints = Math.min(this.item.limitMinutes, this.item.totalMinutes) * this.item.newPeriodBFactor.value;
        }
        console.log(this.item.title);
        this.dialogRef.close(this.item);
    }

}
