<app-loading-shade [loading]="loading"></app-loading-shade>
<table mat-table [dataSource]="lineDatasource">
    <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef translate>LABEL.BROADCAST.TITLE</th>
        <td mat-cell *matCellDef="let item">{{workTitleMap.get(item.workId)?.title}}</td>
    </ng-container>

    <ng-container matColumnDef="subTitle">
        <th mat-header-cell *matHeaderCellDef translate>LABEL.BROADCAST.SUBTITLE</th>
        <td mat-cell *matCellDef="let item">{{workTitleMap.get(item.workId)?.subTitle}}</td>
    </ng-container>

    <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef translate>LABEL.BROADCAST.COUNTRY</th>
        <td mat-cell *matCellDef="let item" translate>{{item.broadcast.countryDescription | uppercase}}</td>
    </ng-container>

    <ng-container matColumnDef="channel">
        <th mat-header-cell *matHeaderCellDef>Zender</th>
        <td mat-cell *matCellDef="let item">{{item.broadcast?.channelDescription}}</td>
    </ng-container>

    <ng-container matColumnDef="timeStart">
        <th mat-header-cell *matHeaderCellDef translate>LABEL.BROADCAST.TIMESTART</th>
        <td mat-cell *matCellDef="let item">{{item.broadcast.timeStart | date: 'short'}}</td>
    </ng-container>

    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef translate>LABEL.BROADCAST.DURATION</th>
        <td mat-cell *matCellDef="let item">{{item.broadcast.duration}}</td>
    </ng-container>

    <ng-container matColumnDef="corporation">claimForRepartitionMap.get
        <th mat-header-cell *matHeaderCellDef>Uitz.org</th>
        <td mat-cell *matCellDef="let item">{{item.broadcast?.broadcastingCorporation}}</td>
    </ng-container>

    <ng-container matColumnDef="rightType">
        <th mat-header-cell *matHeaderCellDef translate>LABEL.RIGHTHOLDERRIGHTTYPE.RIGHTTYPE</th>
        <td mat-cell *matCellDef="let item" translate>
            {{claimForRepartitionMap.get(item.claimForRepartitionId)?.rightTypeDescription | uppercase}}
        </td>
    </ng-container>

    <ng-container matColumnDef="percentage">
        <th mat-header-cell *matHeaderCellDef>Claim (%)</th>
        <td mat-cell *matCellDef="let item">{{claimForRepartitionMap.get(item.claimForRepartitionId)?.percentage}}</td>
    </ng-container>

    <ng-container matColumnDef="showAmount">
        <th mat-header-cell *matHeaderCellDef translate>Bedrag (&euro;)</th>
        <td mat-cell *matCellDef="let item">{{item.showAmount | currency: 'EUR'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="showLinesColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: showLinesColumns"></tr>
</table>
<mat-paginator [length]="lineDatasource.records" [pageSizeOptions]="[10,20,50]" showFirstLastButtons></mat-paginator>