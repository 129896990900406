import {ListFilter} from '../model/list-filter';
import {HttpParams} from '@angular/common/http';
import saveAs from 'file-saver';

export class ApiUtil {
    public static create(listFilter: ListFilter): HttpParams {
        const filter = new ListFilter();

        const keys = Object.keys(listFilter);
        keys.filter(key => {
            return listFilter[key] && listFilter[key] != null;
        }).forEach(key => {
            filter[key] = listFilter[key];
        });

        return new HttpParams({fromObject: filter});
    }

    public static saveToFileSystem(response) {
        const contentDispositionHeader: string = response.headers.get('Content-Disposition');
        const parts: string[] = contentDispositionHeader.split(';');
        const filename = this.trimByChar(parts[1].split('=')[1], '"');
        const blob = new Blob([response.body]);
        saveAs(blob, filename);
    }

    public static trimByChar(origString, charToTrim) {
        charToTrim = charToTrim.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        const regEx = new RegExp('^[' + charToTrim + ']+|[' + charToTrim + ']+$', 'g');
        return origString.replace(regEx, '');
    }

    public static arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}
