import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, retry, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FairPeriod } from '../model/fair-period';
import { FairRepertoireRule } from '../model/fair-repertoire-rule';
import { ReportService } from './report.service';
import { ReportParams } from '../model/report-params';
import { FairPeriodBFactor } from '../model/fair-period-bfactor';
import { ToastrService } from './toastr.service';
import { ApiUtil } from '../util/api.util';
import { HttpHeaders } from '@angular/common/http';
import { Claim } from '../model/claim';
@Injectable({
    providedIn: 'root'
})
export class FairService {

    private endpoint = `${environment.api_endpoint}/fair`;

    constructor(private httpClient: HttpClient,
        private reportService: ReportService,
        private toastrService: ToastrService
    ) {
    }

    public createRepertoire(period: FairPeriod): Observable<FairPeriod> {
        return this.httpClient.post<FairPeriod>(`${this.endpoint}/createrepertoire`, period).pipe(retry(0));
    }

    public reCreateRepertoire(period: FairPeriod): Observable<FairPeriod> {
        return this.httpClient.put<FairPeriod>(`${this.endpoint}/recreaterepertoire`, period).pipe(retry(0));
    }

    reject(period: FairPeriod) {
        return this.httpClient.put<FairPeriod>(`${this.endpoint}/rejectperiod`, period).pipe(retry(0));
    }

    public getRepertoireRules(): Observable<FairRepertoireRule[]> {
        return this.httpClient.get<FairRepertoireRule[]>(`${this.endpoint}/repertoirerules`);
    }

    public getAllPeriods(): Observable<FairPeriod[]> {
        return this.httpClient.get<FairPeriod[]>(`${this.endpoint}/fairperiods`);
    }

    public getAllPeriodByClaimableStatus(): Observable<FairPeriod[]> {
        return this.httpClient.get<FairPeriod[]>(`${this.endpoint}/fairperiods/claimable`);
    }

    public getItemReport(period: FairPeriod): Promise<Boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.toastrService.info(`Bezig met ophalen titelrapport`, '', {
                showDuration: '0',
                hideDuration: '0',
                timeOut: '0',
                extendedTimeOut: '0'
            });

            this.httpClient.get(`${this.endpoint}/fairrepertoireitems/${period.id}`, {
                responseType: 'blob',
                observe: 'response',
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).subscribe(response => {
                ApiUtil.saveToFileSystem(response);
                this.toastrService.clear();
                resolve(true);
                timer(250).subscribe(() => {
                    this.toastrService.success('Rapport opgehaald', null);
                });
            }, response => {
                this.toastrService.clear();
                reject(response.statusText);
                timer(300).subscribe(() => {
                    this.toastrService.error('Rapport kon niet worden opgehaald', 'Fout');
                });
            });
        });
    }

    getClaimReport(period: FairPeriod) {
        return new Promise<boolean>((resolve, reject) => {
            this.toastrService.info(`Bezig met ophalen claimrapport`, '', {
                showDuration: '0',
                hideDuration: '0',
                timeOut: '0',
                extendedTimeOut: '0'
            });

            this.httpClient.get(`${this.endpoint}/claims/${period.id}`, {
                responseType: 'blob',
                observe: 'response',
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).subscribe(response => {
                ApiUtil.saveToFileSystem(response);
                this.toastrService.clear();
                resolve(true);
                timer(250).subscribe(() => {
                    this.toastrService.success('Rapport opgehaald', null);
                });
            }, response => {
                this.toastrService.clear();
                reject(response.statusText);
                timer(300).subscribe(() => {
                    this.toastrService.error('Rapport kon niet worden opgehaald', 'Fout');
                });
            });
        });
    }

    public update(period: FairPeriod): Observable<FairPeriod> {
        return this.httpClient.put<FairPeriod>(`${this.endpoint}/period`, period);
    }

    public createWorksFor(period: FairPeriod): Observable<FairPeriod> {
        return this.httpClient.post<FairPeriod>(`${this.endpoint}/createworks`, period);
    }

    createImportFileFor(period: FairPeriod): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.toastrService.info(`Bezig met ophalen importbestand`, '', {
                showDuration: '0',
                hideDuration: '0',
                timeOut: '0',
                extendedTimeOut: '0'
            });

            this.httpClient.get(`${this.endpoint}/createimportfile/${period.id}`, {
                responseType: 'text',
                observe: 'response',
                headers: new HttpHeaders({
                    'Content-Type': 'text/csv'
                })
            }).subscribe(response => {
                ApiUtil.saveToFileSystem(response);
                this.toastrService.clear();
                resolve(true);
                timer(250).subscribe(() => {
                    this.toastrService.success('importbestand opgehaald', null);
                });
            }, response => {
                console.error(`[ReportService] error getting importbestand`, response.error);
                this.toastrService.clear();
                reject(response.statusText);
                timer(300).subscribe(() => {
                    this.toastrService.error('importbestand kon niet worden opgehaald', 'Fout');
                });
            });
        });
    }

    public getAllPeriodBFactorsByPeriodId(periodId: number): Observable<FairPeriodBFactor[]> {
        return this.httpClient.get<FairPeriodBFactor[]>(`${this.endpoint}/bfactorsforperiod/${periodId}`);
    }


    public makeClaim(claim: Claim): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.endpoint}/makeClaim`, claim);
    }


}
