<p style="margin-left: 16px;font-size: large;">
    Item {{item.id}} aanpassen
</p>
<mat-dialog-content>
    <div id="edit">
        <div>
            <mat-form-field id="title" appearance="outline">
                <mat-label>Titel</mat-label>
                <input type="text" matInput [(ngModel)]="item.title">
            </mat-form-field>
        </div>
        <div class="input-fields">
            <mat-form-field appearance="outline">
                <mat-label>Huidige B-factor</mat-label>
                <input type="text" readonly matInput value="{{item.periodBFactor.bfactor.name}}">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Nieuwe B-factor</mat-label>
                <mat-select panelWidth="null" [(value)]="selected">
                    @for (opt of options; track $index) {
                    <mat-option [value]="opt">{{opt.bfactor.name}} ({{opt.value | number: '1.0-1'}})</mat-option>
                    }

                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button (click)="cancel()">Annuleren</button>
    <button mat-stroked-button color="primary" (click)="adjust()">Pas aan</button>
</mat-dialog-actions>