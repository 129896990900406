import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { DateObject } from '../../repartition/repartition-create/model/date-object';
import { BaseService } from '../base/base-service';
import { Broadcast } from '../model/broadcast';
import { MaintenanceResult } from '../../management-and-control/shared/maintenance-result';
import { SearchRequest } from '../../shared/base/search/search-request';
import { ApiUtil } from '../../shared/util/api.util';
import { ToastrService } from '../../shared/service/toastr.service';

@Injectable({
    providedIn: 'root'
})
export class BroadcastService extends BaseService<Broadcast> {
    constructor(httpClient: HttpClient, private toastrService: ToastrService) {
        super(httpClient, 'broadcasts');
    }

    create(): Broadcast {
        return {};
    }

    public hasBroadcastsInImportFileBeforeAndAfterVatTransitionDate(importfile: number, dateObject: DateObject): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.endpoint}/hasBroadcastsInTransition/${importfile}`, dateObject);
    }

    public moveBroadcast(work: number, broadcast: number): Observable<void> {
        const httpParams = new HttpParams().append('workid', work.toString()).append('broadcastid', broadcast.toString());
        return this.httpClient.put<void>(`${this.endpoint}/move`, {}, {
            params: httpParams
        });
    }

    public moveBroadcasts(file: any): Observable<MaintenanceResult> {
        const form = new FormData();
        form.append('file', file);
        return this.httpClient.post<MaintenanceResult>(`${this.endpoint}/bulkMove`, form);
    }

    public getTotalImportFileRecordPts(importfile: number): Observable<number> {
        return this.httpClient.get<number>(`${this.endpoint}/importFileTotalRecordPts/${importfile}`);
    }

    public downloadTableExport(searchRequest: SearchRequest): Promise<boolean> {
        this.toastrService.info("Ophalen resultaten", "Download");
        return new Promise<boolean>((resolve, reject) => {
            this.httpClient.post(`${this.endpoint}/tableExport`, searchRequest, {
                responseType: 'blob',
                observe: 'response',
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).subscribe(response => {
                ApiUtil.saveToFileSystem(response);
                this.toastrService.clear();
                resolve(true);
                timer(400).subscribe(() => {
                    this.toastrService.success('Uitzendingen opgehaald', null);
                });

            }, response => {
                this.toastrService.clear();
                reject(response.statusText);
                timer(400).subscribe(() => {
                    this.toastrService.error('Uitzendingenlijst kon niet worden opgehaald', `[${response.status}] Error`);
                });
            });
        });
    }

}
