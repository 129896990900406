import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {LedgerService} from '../../../repartition/service/ledger.service';
import { MatButtonModule } from '@angular/material/button';
import { OnlyNumberDirective } from '../../directives/only-number.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    templateUrl: 'create-ledger-dialog.component.html',
    styleUrls: ['../dialog.component.scss', 'create-ledger-dialog.component.scss'],
    standalone: true,
    imports: [MatToolbarModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, OnlyNumberDirective, MatButtonModule]
})
export class CreateLedgerDialogComponent implements OnInit {
    editForm: UntypedFormGroup;

    constructor(public dialogRef: MatDialogRef<CreateLedgerDialogComponent>,
                private ledgerService: LedgerService,
                @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.createForm();
    }

    ngOnInit() {
    }

    createForm() {
        this.editForm = new UntypedFormGroup({
            name: new UntypedFormControl(),
            accountNr: new UntypedFormControl(),
            accountAscription: new UntypedFormControl(),
            contraAccountNr: new UntypedFormControl(),
            contraAccountAscription: new UntypedFormControl(),
            accountAmount: new UntypedFormControl(),
            contraAccountAmount: new UntypedFormControl(),
            comments: new UntypedFormControl(),
            status: new UntypedFormControl('NA')
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    save() {
        this.ledgerService.save(this.editForm.value).subscribe(ledger => {
            this.dialogRef.close(ledger);
        });
    }
}
