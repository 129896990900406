import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './shared/service/user.service';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterLink, RouterOutlet } from '@angular/router';
import { Roles } from './shared/enums/roles.enum';
import { SessionService } from './shared/service/session.service';
import { KeycloakService } from 'keycloak-angular';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MenuUserComponent } from './menu/menu-user/menu-user.component';
import { NgIf } from '@angular/common';
import { MenuDefaultComponent } from './menu/menu-default/menu-default.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MenuRightHolderManagedComponent } from './menu/menu-right-holder-managed/menu-right-holder-managed.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [MatToolbarModule, MenuUserComponent, RouterOutlet, RouterLink, NgIf, MenuDefaultComponent, MatProgressBarModule, MenuRightHolderManagedComponent]
})
export class AppComponent {
    roles: typeof Roles = Roles;
    title = 'fredApp';
    loading = false;
    isFair = false;

    get isManaged(): boolean {
        return this.sessionService.isManagedRightHolder;
    }

    external(link) {
        (window as any).open(link, "_blank");
    }

    constructor(
        private userService: UserService,
        public sessionService: SessionService,
        private router: Router,
        private translateService: TranslateService,
        private keycloakService: KeycloakService
    ) {
        translateService.setDefaultLang('nl');
        translateService.use('nl');

        this.keycloakService.loadUserProfile().then(userProfile => {
            if (this.sessionService.hasRole(Roles.FAIR_RIGHTHOLDER) || this.sessionService.hasRole(Roles.FAIR_MANAGER)) {
                this.isFair = true
            }
        });


        this.router.events.subscribe((event: Event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = true;
                    break;
                }
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading = false;
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }
}
