import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ClaimForRepartition} from '../model/claim-for-repartition';

@Injectable({
    providedIn: 'root'
})
export class ClaimForRepartitionService {
    private endpoint = `${environment.api_endpoint}/claimforrepartition`;

    constructor(private httpClient: HttpClient) {
    }

    public findByIds(ids: Set<number>): Observable<ClaimForRepartition[]> {
        const cfrIds = Array.from(ids).map(value => '' + value);
        return this.httpClient.get<ClaimForRepartition[]>(`${this.endpoint}/by/ids`, {
            params: {
                id: cfrIds
            }
        });
    }
}
