import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {SessionService} from '../service/session.service';
import {Observable} from 'rxjs';
import {Roles} from '../enums/roles.enum';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard  {
    constructor(
        private sessionService: SessionService,
        protected router: Router,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const roles: Roles[] = route.data.roles;
        if (roles != null && roles.length > 0) {
            const hasRole = roles.some(value => this.sessionService.hasRole(value));
            if (!hasRole) {
                this.router.navigate(['/oops', 403]);
            }
            return hasRole;
        }
        return true;
    }
}
