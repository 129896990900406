import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Repartition} from '../../shared/model/repartition';
import {AdditionalRepartition} from '../model/additional-repartition';
import {RepartionRunnigStatus} from '../../shared/model/repartionRunnigStatus';
import {BaseService} from '../../shared/base/base-service';

@Injectable({
    providedIn: 'root'
})
export class RepartitionService extends BaseService<Repartition> {
    constructor(httpClient: HttpClient) {
        super(httpClient, 'repartitions');
    }

    create(): Repartition {
        return {};
    }

    public findAllForReRepartition(): Observable<Repartition[]> {
        return this.httpClient.get<Repartition[]>(`${this.endpoint}/rerepartion`);
    }

    public doReRepartition(repartitions: Repartition[]): Observable<void> {
        return this.httpClient.post<void>(`${this.endpoint}/dorerepartition`, repartitions);
    }

    public findDataForAdditionalRepartition(id: number): Observable<AdditionalRepartition> {
        return this.httpClient.get<AdditionalRepartition>(`${this.endpoint}/additional/${id}`);
    }

    public save(repartition: Repartition): Observable<Repartition> {
        return this.httpClient.post<Repartition>(this.endpoint, repartition);
    }

    public start(reparttion: Repartition): Observable<void> {
        return this.httpClient.get<void>(`${this.endpoint}/start/${reparttion.id}`);
    }

    public fillRepartitionlines(reparttion: Repartition): Observable<void> {
        return this.httpClient.get<void>(`${this.endpoint}/fillrepartitionlines/${reparttion.id}`);
    }

    public buildSpecifications(reparttion: Repartition): Observable<void> {
        return this.httpClient.get<void>(`${this.endpoint}/buildspecifications/${reparttion.id}`);
    }

    public isARepartitionRunning(): Observable<RepartionRunnigStatus> {
        return this.httpClient.get<RepartionRunnigStatus>(`${this.endpoint}/running`);
    }

    hideRepartition(id: number): Observable<Repartition> {
        return this.httpClient.get<Repartition>(`${this.endpoint}/hide/${id}`);
    }
    unHideRepartition(id: number): Observable<Repartition> {
        // hide is a toggle on the backend
        return this.httpClient.get<Repartition>(`${this.endpoint}/hide/${id}`);
    }
}
