import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Ledger} from '../../shared/model/ledger';

@Injectable({
    providedIn: 'root'
})
export class LedgerService {
    private endpoint = `${environment.api_endpoint}/ledgers`;

    constructor(private httpClient: HttpClient) {
    }

    public findAll(): Observable<Ledger[]> {
        return this.httpClient.get<Ledger[]>(`${this.endpoint}`);
    }

    public save(ledger: Ledger) {
        return this.httpClient.post<Ledger[]>(`${this.endpoint}`, ledger);
    }
}
