<mat-card appearance="raised">
    <mat-card-header>
        <mat-card-title>{{conflict.mainWork.originalTitle.title}} ({{conflict.mainWork.workType.description | uppercase | translate}})</mat-card-title>
        <mat-card-subtitle>Geblokkeerd: {{conflict.blockedAmount | currency: 'EUR'}}</mat-card-subtitle>
        <app-conflict-status-badge [conflict]="conflict"></app-conflict-status-badge>
        <span class="spacer"></span>
        <button mat-icon-button (click)="help('conflict.help')" class="mat-elevation-z1" matTooltip="Uitleg">
            <mat-icon>help</mat-icon>
        </button>
        <button mat-icon-button (click)="closeDialog()" class="mat-elevation-z1" matTooltip="Sluiten">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-card-header>
    <app-conflict-claim-search-results (clickedRowEvent)="onRowClicked($event)" [conflict]="conflict" [rightHolder]="rightHolder">
    </app-conflict-claim-search-results>

    <app-claim-decision *ngIf="canClaim" [rightHolder]="rightHolder" [conflict]="conflict" (claimUpdateEvent)="onClaimUpdated($event)"></app-claim-decision>

    <app-conflict-files *ngIf="isSBF && !canClaim" [conflict]="conflict" [rightHolder]="rightHolder" (assetEvaluateEvent)="onAssetEvaluated($event)"></app-conflict-files>

    <app-conflict-history *ngIf="isSBF && !canClaim" [conflict]="conflict"></app-conflict-history>

</mat-card>