import { HttpClient } from "@angular/common/http";
import { BaseService } from "../base/base-service";
import { FairRepertoireItem } from "../model/fair-repertoire-item";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FairItemService extends BaseService<FairRepertoireItem> {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'fairrepertoireitems');
    }

    create(): FairRepertoireItem {
        throw new Error("Method not implemented.");
    }
}