export const environment = {
    production: true,
    api_endpoint: '/api',
    keycloak: {
        config: {
            url: 'https://login.sbfonline.org/auth',
            realm: 'SBF',
            clientId: 'sbf-frontend'
        },
        initOptions: {
            onLoad: 'login-required'
        },
        enableBearerInterceptor: true,
    }
};

