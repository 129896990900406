import {SearchField} from '../base/search/search-field';
import {SearchFieldGroup} from '../base/search/search-field-group';
import {Pagination} from '../model/pagination';
import {SearchRequest} from '../base/search/search-request';

export class RestSearchUtil {

    public static createSearchFieldGroup(searchFields: SearchField[]): SearchFieldGroup {
        return {
            type: 'AND',
            fields: searchFields,
            groups: [],
        };
    }

    public static createMatSearchRequest(pagination: Pagination, filter: SearchFieldGroup): SearchRequest {
        let order;
        if (pagination.orderBy && pagination.direction === 'desc') {
            order = `-${pagination.orderBy}`;
        } else {
            order = pagination.orderBy;
        }
        return this.createSearchRequest(pagination.page - 1, pagination.limit, order, filter);
    }

    public static createSearchRequest(pageIndex: number, pageSize: number, sort: string, filter: SearchFieldGroup): SearchRequest {
        return {
            pageIndex,
            pageSize,
            sort: [sort],
            filter
        };
    }

}
