import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { RepartitionConflict } from '../../model/repartition-conflict';
import { RightHolder } from '../../model/rightholder';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConflictData } from '../../model/conflict-data';
import { ConflictClaimSearchResultsComponent } from '../../component/conflict-claim-search-results/conflict-claim-search-results.component';
import { Router } from '@angular/router';
import { SessionService } from '../../../shared/service/session.service';
import { Roles } from '../../../shared/enums/roles.enum';
import { HelpDialogComponent } from "./help-dialog/help-dialog.component";
import { TemplateService } from "../../service/template.service";
import { SearchFieldGroup } from "../../base/search/search-field-group";
import { SearchOperator } from "../../base/search/search-operator.enum";
import { SearchField } from "../../base/search/search-field";
import { RestSearchUtil } from "../../util/rest-search-util";
import { Pagination } from "../../model/pagination";
import { ConflictHistoryComponent } from './conflict-history/conflict-history.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConflictFilesComponent } from './conflict-files/conflict-files.component';
import { ClaimDecisionComponent } from './claim-decision/claim-decision.component';
import { NgIf, UpperCasePipe, CurrencyPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ConflictStatusBadgeComponent } from '../../component/conflict-status-badge/conflict-status-badge.component';
import { MatCardModule } from '@angular/material/card';

/*  NB:
    This component is used only by SBF-users.
    The component used by rightholders is rightholder/rightholder-edit/rightholder-conflict-panel/conflict-dialog/conflict-dialog.component.ts
    Both components use shared/component/conflict-claim-search-results/conflict-claim-search-results.component.ts
    to display conflict claim data.
*/

@Component({
    selector: 'app-conflict-claim-dialog',
    templateUrl: './conflict-claim-dialog.component.html',
    styleUrls: ['./conflict-claim-dialog.component.scss'],
    standalone: true,
    imports: [MatCardModule, ConflictStatusBadgeComponent, MatButtonModule, MatTooltipModule, MatIconModule, ConflictClaimSearchResultsComponent, NgIf, ClaimDecisionComponent, ConflictFilesComponent, ConflictHistoryComponent, UpperCasePipe, CurrencyPipe, TranslateModule]
})
export class ConflictClaimDialogComponent implements OnInit {

    conflict: RepartitionConflict;
    rightHolder: RightHolder;

    @ViewChild(ConflictClaimSearchResultsComponent)
    conflictClaimSearchResultsComponent: ConflictClaimSearchResultsComponent;

    @ViewChild(ConflictHistoryComponent)
    conflictHistoryComponent: ConflictHistoryComponent;

    constructor(@Inject(MAT_DIALOG_DATA) conflictData: ConflictData,
        private dialogRef: MatDialogRef<ConflictClaimDialogComponent>,
        private router: Router,
        private dialog: MatDialog,
        private templateService: TemplateService,
        private sessionService: SessionService) {

        this.conflict = conflictData.conflict;
        this.rightHolder = conflictData.rightHolder;
    }

    ngOnInit(): void {
    }

    closeDialog() {
        this.dialogRef.close();
    }

    onRowClicked(workId: number) {
        this.dialogRef.close();
        this.router.navigateByUrl(`/works/${workId}`);
    }

    onClaimUpdated(repClaimId: number) {
        this.conflictClaimSearchResultsComponent.search();
    }

    get canClaim() {
        if (this.sessionService.hasRole(Roles.SBF_CLAIMMANAGEMENT)) {
            return this.sessionService.isManagedRightHolder;
        } else {
            return this.sessionService.hasRole(Roles.WORK_CLAIMS_EDIT);
        }
    }

    get isSBF() {
        return this.sessionService.isSBF;
    }

    prepareRestSearch(slug: string): SearchFieldGroup {
        const group: SearchFieldGroup = {
            type: 'AND',
            fields: [],
            groups: []
        };

        group.fields.push({
            fieldName: 'slug',
            operator: SearchOperator.EQ,
            value: slug,
            caseInsensitive: true
        } as SearchField);

        return group;
    }

    defaultPagination(): Pagination {
        return new Pagination(0, 1, 'id', 'asc');
    }

    help(slug: string) {
        let searchRequest = RestSearchUtil.createMatSearchRequest(this.defaultPagination(), this.prepareRestSearch(slug));

        this.templateService.findByRequest(searchRequest).subscribe(result => {
            if (result.records == 1) {
                console.log(result)
                this.dialog.open(HelpDialogComponent, {
                    data: {
                        'text': result.data[0].content
                    }
                });
            }
        });
    }

    onAssetEvaluated(done: boolean) {
        if (done) {
            this.conflictHistoryComponent.search();
        }
    }
}
