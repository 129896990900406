import { Component, Input, OnInit } from '@angular/core';
import { RepartitionConflictActionLogService } from '../../../service/repartition-conflict-action-log.service';
import { RepartitionConflict } from '../../../model/repartition-conflict';
import { RepartitionConflictActionLog } from 'src/app/shared/model/repartition-conflict-action-log';
import { ReportService } from 'src/app/shared/service/report.service';
import { ReportParams } from 'src/app/shared/model/report-params';
import { BaseSearchComponent } from 'src/app/shared/base/base-search-component';
import { UntypedFormGroup } from '@angular/forms';
import { SearchFieldGroup } from 'src/app/shared/base/search/search-field-group';
import { Pagination } from 'src/app/shared/model/pagination';
import { UntypedFormBuilder } from '@angular/forms';
import { CacheService } from 'src/app/shared/service/cache.service';
import { SearchOperator } from 'src/app/shared/base/search/search-operator.enum';
import { SearchField } from 'src/app/shared/base/search/search-field';
import { ConflictParty } from 'src/app/shared/enums/conflict-party.enum';
import { DatePipe } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'app-conflict-history',
    templateUrl: './conflict-history.component.html',
    styleUrls: ['./conflict-history.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, MatTableModule, MatSortModule, MatButtonModule, MatPaginatorModule, DatePipe]
})

export class ConflictHistoryComponent extends BaseSearchComponent<RepartitionConflictActionLog> implements OnInit {
    partyLabels: Map<ConflictParty, string> = new Map([
        [ConflictParty.SBF, 'SBF'],
        [ConflictParty.RIGHTHOLDER, 'RH'],
        [ConflictParty.EVENT_TRIGGER, 'Systeem'],
        [ConflictParty.NA, '?']
    ]);

    displayedColumns = [
        'date',
        'actiondesc',
        'party',
        'user',
        'forRh',
        'endstate'
    ];
    pageSizeOptions: number[] = [5, 10];

    @Input() conflict: RepartitionConflict;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private logService: RepartitionConflictActionLogService,
        private reportService: ReportService,
        cacheService: CacheService) {
        super(logService, cacheService, 'conflict-log');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.search();
    }

    defaultListForm(): UntypedFormGroup {
        return this.formBuilder.group({
            conflict: this.conflict.id
        });
    }

    defaultPagination(): Pagination {
        return new Pagination(0, 5, 'id', "desc");
    }

    prepareRestSearch(values: any): SearchFieldGroup {
        const group: SearchFieldGroup = {
            type: 'AND',
            fields: [],
            groups: []
        };

        group.fields.push({
            fieldName: 'conflict.id',
            operator: SearchOperator.EQ,
            value: this.conflict.id,
        } as SearchField);


        return group;
    }

    downloadLog(): void {
        const reportParams: ReportParams = {
            conflict: this.conflict.id.toString()
        };
        this.reportService.downloadReport('conflictLog', reportParams);
    }

}
