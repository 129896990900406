import { Component, OnInit, ViewChild, forwardRef } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { CreatePeriodDialogComponent } from './create-period-dialog/create-period-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { FairPeriod } from '../shared/model/fair-period';
import { FairService } from '../shared/service/fair.service';
import { ActivatedRoute } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FairPeriodStatus } from '../shared/enums/fair-period-status.enum';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { PeriodComponent } from './period/period.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-fair',
  standalone: true,
  imports: [MatCardModule, ReactiveFormsModule, MatButtonModule, PeriodComponent, MatInputModule, MatSelectModule,
    MatOptionModule, MatIconModule],
  templateUrl: './fair.component.html',
  styleUrl: './fair.component.scss'
})
export class FairComponent implements OnInit {

  periods: FairPeriod[];

  filterPeriods: FairPeriod[];
  filterForm: FormGroup;

  @ViewChild(PeriodComponent) periodComponent: PeriodComponent;

  constructor(public dialog: MatDialog,
    private fairService: FairService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.periods = this.route.snapshot.data.fairperiods;
    this.setFilterPeriods();
    this.createFilterForm();
  }

  setFilterPeriods() {
    this.filterPeriods = this.periods.sort((a, b) => {
      if (a.lastModified > b.lastModified) {
        return -1;
      }
      if (a.lastModified < b.lastModified) {
        return 1;
      }
      return 0;
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreatePeriodDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      // receives a bool if the dialog fetches data from servers or nothing if dialog was cancelled, 
      // false means an error, but we still want to refresh
      if (result || !result) {
        this.fetchPeriods();
      }
    });
  }

  fetchPeriods() {
    this.fairService.getAllPeriods().subscribe(values => {
      this.periods = values;
      this.setFilterPeriods();
    })
  }

  get statuses() {
    return Object.values(FairPeriodStatus);
  }

  statusLabel(status: FairPeriodStatus): string {
    switch (status) {
      case FairPeriodStatus.NA:
        return 'n/a';
      case FairPeriodStatus.CREATED:
        return 'Aangemaakt';
      case FairPeriodStatus.CREATION_FAILED:
        return 'Aanmaakfout';
      case FairPeriodStatus.APPROVED:
        return 'Goedgekeurd';
      case FairPeriodStatus.REJECTED:
        return 'Afgekeurd';
      case FairPeriodStatus.RECREATED:
        return 'Opnieuw aangemaakt';
      case FairPeriodStatus.DELETED:
        return 'Verwijderd';
      case FairPeriodStatus.CLAIMABLE:
        return 'Claimfase';
      case FairPeriodStatus.IN_REPARTITION:
        return 'In repartitie';
      case FairPeriodStatus.DONE:
        return 'Afgerond';
    }
  }


  filter() {
    if (this.filterForm.value.year) {
      this.filterPeriods = this.filterPeriods.filter(p => this.isSameYear(p.start, this.filterForm.value.year));
    }

    if (this.filterForm.value.status) {
      this.filterPeriods = this.filterPeriods.filter(p => p.status === this.filterForm.value.status);
    }

    if (this.filterForm.value.description) {
      this.filterPeriods = this.filterPeriods.filter(p => p.description.toLowerCase().includes(this.filterForm.value.description.toLowerCase()));
    }
    console.warn(this.filterForm.value);

  }

  isSameYear(date: Date, year: number): boolean {
    const newDate = new Date(date); //needed, otherwise getFullYear() will not work
    return newDate.getFullYear() === year;
  }

  reset() {
    this.setFilterPeriods();
    this.createFilterForm();
  }

  createFilterForm() {
    this.filterForm = this.formBuilder.group({
      year: new FormControl(null),
      status: new FormControl(null),
      description: new FormControl(null),
    })
  }
}
