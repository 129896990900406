import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RepartitionConflictClaim } from '../../model/repartition-conflict-claim';
import { BaseSearchComponent } from '../../base/base-search-component';
import { UntypedFormGroup, UntypedFormBuilder, FormsModule } from '@angular/forms';
import { SearchFieldGroup } from '../../base/search/search-field-group';
import { Pagination } from '../../model/pagination';
import { RepartitionConflict } from '../../model/repartition-conflict';
import { RightHolder } from '../../model/rightholder';
import { SearchOperator } from '../../base/search/search-operator.enum';
import { SearchField } from '../../base/search/search-field';
import { RepartitionConflictClaimService } from '../../service/repartition-conflict-claim.service';
import { CacheService } from 'src/app/shared/service/cache.service';
import { WorkUtil } from '../../../shared/util/work-util';
import { SessionService } from "../../service/session.service";
import { Roles } from "../../enums/roles.enum";
import { Router } from "@angular/router";
import { ConflictStatus } from '../../enums/conflict-status.enum';
import { ConflictUtil } from '../../util/conflict-util';
import { ConflictClaimUtil } from '../../util/conflict-claim.util';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { OnlyNumberDirective } from '../../directives/only-number.directive';
import { MatInputModule } from '@angular/material/input';
import { NgClass, NgStyle, DatePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';

@Component({
    selector: 'app-conflict-claim-search-results',
    templateUrl: './conflict-claim-search-results.component.html',
    styleUrls: ['./conflict-claim-search-results.component.scss'],
    standalone: true,
    imports: [MatTableModule, NgClass, NgStyle, MatInputModule, FormsModule, OnlyNumberDirective, MatButtonModule, MatIconModule, DatePipe]
})
export class ConflictClaimSearchResultsComponent extends BaseSearchComponent<RepartitionConflictClaim> implements OnInit {

    isJustSet: boolean = false;
    isWorkSeries: boolean;

    @Input() rightHolder: RightHolder;
    @Input() conflict: RepartitionConflict;

    @Output() clickedRowEvent: EventEmitter<number> = new EventEmitter();

    displayedColumns: string[] = [];

    constructor(cacheService: CacheService,
        private conflictClaimService: RepartitionConflictClaimService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private sessionService: SessionService) {
        super(conflictClaimService, cacheService, 'conflict-claims');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.search();
        this.isWorkSeries = this.conflict.mainWork.workType.id === 3;
        this.displayedColumns = this.initColumns();
    }

    initColumns(): string[] {
        if (this.rightHolder?.id > 0) {
            if (this.isWorkSeries) {
                return ['rh', 'percentage', 'level', 'subtitle', 'action', 'actionDate'];
            } else {
                return ['rh', 'percentage', 'action', 'actionDate'];
            }
        } else if (this.isSBF) {
            if (this.isWorkSeries) {
                return ['rh', 'percentage', 'level', 'subtitle', 'from', 'until', 'action', 'newPercentage',];
            } else {
                return ['rh', 'percentage', 'from', 'until', 'action', 'newPercentage',];
            }
        }

    }

    defaultListForm(): UntypedFormGroup {
        return this.formBuilder.group({
            rightHolderId: this.rightHolder?.id,
            conflictId: this.conflict.id
        });
    }

    //NB: cannot be null or empty even if pagination is not used on frontend
    defaultPagination(): Pagination {
        return new Pagination(0, 10, 'id', 'asc');
    }

    prepareRestSearch(values: any): SearchFieldGroup {
        const group: SearchFieldGroup = {
            type: 'AND',
            fields: [],
            groups: []
        };

        group.fields.push({
            fieldName: 'conflict.id',
            operator: SearchOperator.EQ,
            value: values.conflictId
        } as SearchField);

        return group;
    }

    get workUtil() {
        return WorkUtil;
    }

    get conflictUtil() {
        return ConflictUtil;
    }

    get conflictClaimUtil() {
        return ConflictClaimUtil;
    }

    goToWork(conflictClaim: RepartitionConflictClaim) {
        if (this.isSBF) {
            this.clickedRowEvent.emit(conflictClaim.mainClaim.workRightType.work.id);
        }
    }

    get isSBF() {
        return this.sessionService.isSBF;
    }

    get isPhase2() {
        return this.conflictUtil.isPhaseTwo(this.conflict);
    }

    get isSolved() {
        return this.conflict.conflictStatus.conflictStatus === ConflictStatus.SOLVED;
    }

    get canClaim() {
        if (this.sessionService.hasRole(Roles.SBF_CLAIMMANAGEMENT)) {
            return this.sessionService.isManagedRightHolder;
        } else {
            return this.sessionService.hasRole(Roles.WORK_CLAIMS_EDIT);
        }
    }

    get justSet() {
        return this.isJustSet;
    }

    setSbfPercentage(conflictClaim) {
        this.conflictClaimService.setSbfPercentage(conflictClaim).subscribe(value => {
            console.log(value)
            this.isJustSet = true;
        });
    }

    sbfStyle(): string {
        if (this.isSBF) {
            return 'sbf-only';
        }
    }
}
