<form [formGroup]="filterForm" (ngSubmit)="search()">
    <mat-card appearance="raised">
        <mat-card-header>
            <mat-card-title>Aanpassen B-factor bij periode: {{periodDescription}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div style="display: flex;align-items: baseline; gap:16px">
                <mat-form-field appearance="outline">
                    <mat-label>Item Id</mat-label>
                    <input matInput type="number" formControlName="id">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Titel</mat-label>
                    <input matInput type="text" formControlName="title">
                </mat-form-field>

                <button mat-stroked-button type="submit" color="primary" class="mat-elevation-z1">Zoeken</button>
                <button mat-stroked-button type="button" (click)="reset()" class="mat-elevation-z1">Reset</button>
            </div>
        </mat-card-content>
    </mat-card>
</form>

<mat-card>
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Item Id</th>
            <td mat-cell *matCellDef="let item">{{item.id}}</td>
        </ng-container>

        <ng-container matColumnDef="basedOnWork">
            <th mat-header-cell *matHeaderCellDef>Werk Id</th>
            <td mat-cell *matCellDef="let item">
                {{item.basedOnWorkId}}</td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Titel</th>
            <td mat-cell *matCellDef="let item">{{item.title }}</td>
        </ng-container>


        <ng-container matColumnDef="totalMinutes">
            <th mat-header-cell *matHeaderCellDef>Duur</th>
            <td mat-cell *matCellDef="let item">{{item.totalMinutes}}</td>
        </ng-container>

        <ng-container matColumnDef="limitMinutes">
            <th mat-header-cell *matHeaderCellDef>Limiet</th>
            <td mat-cell *matCellDef="let item">{{item.limitMinutes}}</td>
        </ng-container>

        <ng-container matColumnDef="periodBFactorName">
            <th mat-header-cell *matHeaderCellDef>B-Factor</th>
            <td mat-cell *matCellDef="let item">{{item.periodBFactor.bfactor.name}}</td>
        </ng-container>

        <ng-container matColumnDef="periodBFactorValue">
            <th mat-header-cell *matHeaderCellDef>BF-waarde</th>
            <td mat-cell *matCellDef="let item">{{item.periodBFactor.value| number: '1.0-1'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="points">
            <th mat-header-cell *matHeaderCellDef>Punten</th>
            <td mat-cell *matCellDef="let item">{{item.points | number: '1.0-2'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="newPeriodBFactorName">
            <th mat-header-cell *matHeaderCellDef>Nieuwe B-Factor</th>
            <td mat-cell *matCellDef="let item">{{item.newPeriodBFactor?.bfactor.name}}</td>
        </ng-container>

        <ng-container matColumnDef="newPeriodBFactorValue">
            <th mat-header-cell *matHeaderCellDef>Nieuwe BF-waarde</th>
            <td mat-cell *matCellDef="let item">{{item.newPeriodBFactor?.value | number: '1.0-1'}}</td>
        </ng-container>

        <ng-container matColumnDef="correctedPoints">
            <th mat-header-cell *matHeaderCellDef>Nieuwe Punten</th>
            <td mat-cell *matCellDef="let item">{{item.correctedPoints | number: '1.0-2'}}</td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="adjust(row)"></tr>
    </table>
    <mat-paginator [length]="dataSource.records" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</mat-card>