<div class="dialog">
    <mat-toolbar color="primary">
        <h1 class="mat-headline-6">Grootboekrekening aanmaken</h1>
    </mat-toolbar>
    <div class="dialog-body">
        <form [formGroup]="editForm">
            <mat-form-field>
                <mat-label>Naam grootboek administratie</mat-label>
                <input formControlName="name" matInput required>
            </mat-form-field>
            <div>
                <mat-form-field>
                    <mat-label>Grootboekrekeningnummer</mat-label>
                    <input formControlName="accountNr" matInput required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Bedrag grootboekrekening</mat-label>
                    <input appOnlyNumber="decimal" formControlName="accountAmount" matInput required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Naam grootboekrekeningnummer</mat-label>
                    <input formControlName="accountAscription" matInput required>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Tegenrekeningnummer</mat-label>
                    <input formControlName="contraAccountNr" matInput required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Bedrag tegenrekening</mat-label>
                    <input appOnlyNumber="decimal" formControlName="contraAccountAmount" matInput required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Naam tegenrekening</mat-label>
                    <input formControlName="contraAccountAscription" matInput required>
                </mat-form-field>
            </div>
            <mat-form-field>
                <mat-label>Opmerkingen</mat-label>
                <input formControlName="comments" matInput>
            </mat-form-field>
        </form>
    </div>
    <div class="dialog-actions">
        <button mat-flat-button (click)="cancel()">Annuleren</button>
        <button mat-flat-button class="mat-primary" (click)="save()" [disabled]="!(editForm.valid && editForm.dirty)">Opslaan</button>
    </div>
</div>
