<app-loading-shade [loading]="loading"></app-loading-shade>
<table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let element">{{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Titel</th>
        <td mat-cell *matCellDef="let element">{{element.title}}</td>
    </ng-container>

    <ng-container matColumnDef="subTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Subtitel</th>
        <td mat-cell *matCellDef="let element">{{element.subTitle}}</td>
    </ng-container>

    <ng-container matColumnDef="channel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="channel.code">Kanaal</th>
        <td mat-cell *matCellDef="let element">{{element.channel?.code}}</td>
    </ng-container>

    <ng-container matColumnDef="timeStart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Starttijd</th>
        <td mat-cell *matCellDef="let element">{{element.timeStart | date:'short'}}</td>
    </ng-container>

    <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duur</th>
        <td mat-cell *matCellDef="let element">{{element.duration}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element"><span translate>LABEL.BROADCASTSTATUS.{{element.status}}</span></td>
    </ng-container>

    <ng-container matColumnDef="statusIcon">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
            <mat-icon>{{statusIcon(element.status)}}</mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Land</th>
        <td mat-cell *matCellDef="let element"><span translate>{{element.country?.description | uppercase}}</span></td>
    </ng-container>

    <ng-container matColumnDef="broadcastType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="broadcastType.type">Uitzendtype</th>
        <td mat-cell *matCellDef="let element">{{element.broadcastType?.type}}</td>
    </ng-container>

    <ng-container matColumnDef="work">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="work.id">Werk</th>
        <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
            <button mat-button mat-stroked-button [routerLink]="['/works/', element.work.id]">
                {{element.work?.id}}
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="repartitionIds">
        <th mat-header-cell *matHeaderCellDef>Repartities</th>
        <td mat-cell *matCellDef="let element">{{showRepartitionIds(element.repartitionIds)}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/broadcasts',row.id]"></tr>
</table>
<mat-paginator [length]="dataSource.records" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
