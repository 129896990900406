
export enum ConflictStatus {
    NA = 'NA',
    REPARTITION_CREATED = 'REPARTITION_CREATED',
    DETECTED = 'DETECTED',
    PHASE_ONE = 'PHASE_ONE',
    PHASE_TWO_SBF = 'PHASE_TWO_SBF',
    PHASE_TWO_ADVISOR = 'PHASE_TWO_ADVISOR',
    PHASE_TWO_LAWSUIT = 'PHASE_TWO_LAWSUIT',
    SOLVED = 'SOLVED',
    PENDING_ADVISOR = 'PENDING_ADVISOR',
    IGNORED = 'IGNORED',
    UNDER_SBF = 'UNDER_SBF',
    FINAL_SOLVED = 'FINAL_SOLVED',
    SOLVED_BY_SBF = 'SOLVED_BY_SBF',
    EXPIRED_UNRESOLVED_PHASE1 = 'EXPIRED_UNRESOLVED_PHASE1',
    PENDING_LAWSUIT = 'PENDING_LAWSUIT',
    EXPIRED_UNRESOLVED_PHASE2 = 'EXPIRED_UNRESOLVED_PHASE2'
}
