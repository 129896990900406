import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ListFilter} from '../model/list-filter';
import {ListResult} from '../model/list-result';
import {Pagination} from '../model/pagination';
import {ApiUtil} from '../util/api.util';
import {BaseModel} from './base-model';
import {SearchResult} from './search/search-result';
import {SearchRequest} from './search/search-request';

export abstract class BaseService<M extends BaseModel> {
    protected endpoint: string;

    protected constructor(
        protected httpClient: HttpClient,
        path: string
    ) {
        this.endpoint = `${environment.api_endpoint}/${path}`;
    }

    abstract create(): M;

    public findAll(): Observable<M[]> {
        return this.httpClient.get<M[]>(this.endpoint);
    }

    public findById(id: number): Observable<M> {
        return this.httpClient.get<M>(`${this.endpoint}/${id}`).pipe(map(value => {
            return Object.assign(this.create(), value);
        }));
    }

    public findByRequest(searchRequest: SearchRequest): Observable<SearchResult<M>> {
        return this.httpClient.post<SearchResult<M>>(`${this.endpoint}/search`, searchRequest);
    }

    public save(model: M): Observable<M> {
        return this.httpClient.post<M>(`${this.endpoint}`, model).pipe(map(value => {
            return Object.assign(this.create(), value);
        }));
    }

    public update(model: M): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.endpoint}/${model.id}`, model);
    }

    public delete(nr: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.endpoint}/${nr}`);
    }
}
