import { Injectable } from '@angular/core';
import { BaseService } from '../base/base-service';
import { RepartitionConflictClaim } from '../model/repartition-conflict-claim';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from './toastr.service';
import { RightHolder } from '../model/rightholder';
import { Observable } from 'rxjs';
import { RepartitionConflict } from '../model/repartition-conflict';

@Injectable({
    providedIn: 'root'
})
export class RepartitionConflictClaimService extends BaseService<RepartitionConflictClaim>{

    constructor(httpClient: HttpClient, private toastrService: ToastrService) {
        super(httpClient, 'conflictclaims');
    }

    create(): RepartitionConflictClaim {
        return {
            conflict: null,
            mainClaim: {
                percentage: 0
            },
            newPercentage: null,
            sbfPercentage: null,
            chosenAction: null
        };
    }

    getClaimFor(rightHolder: RightHolder, conflict: RepartitionConflict): Observable<RepartitionConflictClaim> {
        return this.httpClient.get<RepartitionConflictClaim>(`${this.endpoint}/rh/${rightHolder.id}/conflict/${conflict.id}`);
    }

    retract(conflictClaim: RepartitionConflictClaim): Observable<RepartitionConflictClaim> {
        return this.httpClient.put<RepartitionConflictClaim>(`${this.endpoint}/retract`, conflictClaim);
    }

    // upload(choice:string, conflictClaim: RepartitionConflictClaim): Observable<RepartitionConflictClaim> {
    //     return this.httpClient.put<RepartitionConflictClaim>(`${this.endpoint}/upload/${choice}`, conflictClaim);
    // }

    // maintain(choice: string, conflictClaim: RepartitionConflictClaim): Observable<RepartitionConflictClaim> {
    //     return this.httpClient.put<RepartitionConflictClaim>(`${this.endpoint}/maintain/` + choice, conflictClaim);
    // }

    maintainSBF(conflictClaim: RepartitionConflictClaim): Observable<RepartitionConflictClaim> {
        return this.httpClient.put<RepartitionConflictClaim>(`${this.endpoint}/maintainsbf`, conflictClaim);
    }

    maintainAdvisor(conflictClaim: RepartitionConflictClaim): Observable<RepartitionConflictClaim> {
        return this.httpClient.put<RepartitionConflictClaim>(`${this.endpoint}/maintainadvisor`, conflictClaim);
    }

    maintainLawsuit(conflictClaim: RepartitionConflictClaim): Observable<RepartitionConflictClaim> {
        return this.httpClient.put<RepartitionConflictClaim>(`${this.endpoint}/maintainlawsuit`, conflictClaim);
    }

    setNewPercentage(conflictClaim: RepartitionConflictClaim): Observable<RepartitionConflictClaim> {
        return this.httpClient.put<RepartitionConflictClaim>(`${this.endpoint}/newpercentage`, conflictClaim);
    }

    setSbfPercentage(conflictClaim: RepartitionConflictClaim): Observable<RepartitionConflictClaim> {
        return this.httpClient.put<RepartitionConflictClaim>(`${this.endpoint}/sbfpercentage`, conflictClaim);
    }

    findActiveConflictCount(rightHolder: RightHolder): Observable<number> {
        return this.httpClient.get<number>(`${this.endpoint}/rh/${rightHolder.id}/conflictcount`);
    }
}
