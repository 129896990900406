import { DecimalPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BaseSearchComponent } from 'src/app/shared/base/base-search-component';
import { SearchField } from 'src/app/shared/base/search/search-field';
import { SearchFieldGroup } from 'src/app/shared/base/search/search-field-group';
import { SearchOperator } from 'src/app/shared/base/search/search-operator.enum';
import { FairRepertoireItem } from 'src/app/shared/model/fair-repertoire-item';
import { Pagination } from 'src/app/shared/model/pagination';
import { CacheService } from 'src/app/shared/service/cache.service';
import { FairItemService } from 'src/app/shared/service/fair-item.service';
import { EditItemDialogComponent } from './edit-item-dialog/edit-item-dialog.component';
import { FairPeriodBFactor } from 'src/app/shared/model/fair-period-bfactor';
import { FairService } from 'src/app/shared/service/fair.service';

@Component({
    selector: 'app-repertoire-list',
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatInputModule, MatButtonModule, MatFormFieldModule, MatTableModule,
        MatPaginatorModule, DecimalPipe],
    templateUrl: './repertoire-list.component.html',
    styleUrl: './repertoire-list.component.scss'
})
export class RepertoireListComponent extends BaseSearchComponent<FairRepertoireItem> implements OnInit {

    periodId: number;
    periodDescription: string;
    updatedId: number;
    periodBFactors: FairPeriodBFactor[];

    private route = inject(ActivatedRoute);

    displayedColumns = [
        'id',
        'basedOnWork',
        'title',
        'totalMinutes',
        'limitMinutes',
        'periodBFactorName',
        'periodBFactorValue',
        'points',
        'newPeriodBFactorName',
        'newPeriodBFactorValue',
        'correctedPoints',
    ];

    constructor(
        cacheService: CacheService,
        private formBuilder: FormBuilder,
        private itemService: FairItemService,
        private fairService: FairService,
        public dialog: MatDialog,
    ) {
        super(itemService, cacheService, 'repartition-list');
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.periodId = params['id'];
            this.periodDescription = params['description'];
            this.fairService.getAllPeriodBFactorsByPeriodId(this.periodId).subscribe((values) => this.periodBFactors = values);
        });
        super.ngOnInit();
        this.search();
    }

    defaultListForm(): FormGroup {
        // if we had successful update, search on Id to confirm update
        // else return standard empty form
        if (this.updatedId) {
            const id = this.updatedId;
            this.updatedId = null;
            return this.formBuilder.group({
                id: id,
                title: null,
            });
        }

        return this.formBuilder.group({
            id: null,
            title: null,
        });
    }

    defaultPagination(): Pagination {
        return new Pagination(0, 10, 'id', 'asc');
    }

    prepareRestSearch(values: any): SearchFieldGroup {
        const group: SearchFieldGroup = {
            type: 'AND',
            fields: [],
            groups: []
        };

        group.fields.push({
            fieldName: 'period.id',
            operator: SearchOperator.EQ,
            value: this.periodId
        });

        if (this.updatedId) {
            group.fields.push({
                fieldName: 'id',
                operator: SearchOperator.EQ,
                value: this.updatedId
            });
        }

        if (values.id) {
            group.fields.push({
                fieldName: 'id',
                operator: SearchOperator.EQ,
                value: values.id
            } as SearchField);
        }

        if (values.title) {
            group.fields.push({
                fieldName: 'title',
                operator: SearchOperator.CONTAINS,
                value: values.title
            } as SearchField);
        }

        return group;
    }

    adjust(item: FairRepertoireItem) {
        const dialogRef = this.dialog.open(EditItemDialogComponent, {
            data: {
                item: item,
                options: this.periodBFactors
            }
        });

        dialogRef.afterClosed().subscribe((item) => {
            console.log("Item: " + item.id);
            if (item) {
                this.updatedId = item.id;
                this.itemService.update(item).subscribe((ok) => {
                    //this.search();
                });
            } else {
                this.updatedId = null;
            }
        });

    }
}
