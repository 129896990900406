import {ListFilter} from './list-filter';

export class Pagination {
    orderBy: string;
    direction: string;
    page: number;
    limit: number;

    constructor(pageIndex?: number, pageSize?: number, orderBy?: string, direction?: string) {
        this.page = pageIndex;
        this.limit = pageSize;
        this.orderBy = orderBy;
        this.direction = direction;
    }

    getOrder(): string {
        if (this.orderBy && this.direction === 'desc') {
            const orderParts = this.orderBy.split(',');
            return orderParts.map(value => `-${value}`).join(',');
        } else {
            return this.orderBy;
        }
    }

    toListFilter(): ListFilter {
        const listFilter = new ListFilter();
        const order = this.getOrder();
        if (order) {
            listFilter.order = order;
        }
        if (this.page) {
            listFilter.page = '' + this.page;
        }
        if (this.limit) {
            listFilter.limit = '' + this.limit;
        }
        return listFilter;
    }

}
