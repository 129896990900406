import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {Pagination} from '../../shared/model/pagination';
import {SpecificationLine} from '../../shared/model/specification-line';
import {SpecificationService} from '../service/specification.service';

export class SpecificationLineDatasource implements DataSource<SpecificationLine> {
    private dataSubject = new BehaviorSubject<SpecificationLine[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(true);

    public allDataPaged: SpecificationLine[][];

    public page: number;
    public records: number;

    private pageSize: number;

    constructor(private specificationService: SpecificationService) {
    }

    public get loading(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }

    public get data$(): Observable<SpecificationLine[]> {
        return this.dataSubject.asObservable();
    }

    connect(collectionViewer: CollectionViewer): Observable<SpecificationLine[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }

    load(repartitionId: number, pagination: Pagination) {
        this.loadingSubject.next(true);

        if (this.allDataPaged) {
            // Recalculate the paged array if pageSize has changed
            if (!this.pageSize || this.pageSize !== pagination.limit) {
                const value = [];
                this.allDataPaged.forEach(page => {
                    page.forEach(value1 => value.push(value1));
                });
                this.allDataPaged = this.createPagedArray(value, pagination.limit);
            }

            // Set the new page
            this.dataSubject.next(this.allDataPaged[pagination.page - 1]);
        } else {
            // First time initialize de dataSource
            this.specificationService.getSpecificationLines(repartitionId).subscribe(value => {
                this.records = value.length;
                this.pageSize = pagination.limit;
                this.allDataPaged = this.createPagedArray(value, pagination.limit);
                this.loadingSubject.next(false);

                // Set the new page
                this.dataSubject.next(this.allDataPaged[pagination.page - 1]);
            });
        }


    }

    private createPagedArray(data: any[], pageSize: number) {
        const result = [];
        for (let pageIndex = 0, len = data.length; pageIndex < len; pageIndex += pageSize) {
            result.push(data.slice(pageIndex, pageIndex + pageSize));
        }
        return result;
    }
}
