<div class="dialog">
    <mat-toolbar color="primary">
      <h1 class="mat-headline-6">{{data.title}}</h1>
    </mat-toolbar>
  <div class="dialog-body">
    {{data.message}}
  </div>
  <div class="dialog-actions">
      <button mat-flat-button (click)="onNo()">Nee</button>
      <button mat-flat-button class="mat-primary" (click)="onYes()">Ja</button>
  </div>
</div>
