import { AfterViewInit, Directive, OnInit, ViewChild } from '@angular/core';
import { BaseModel } from './base-model';
import { BaseDatasource } from './base-datasource';
import { BaseService } from './base-service';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { Pagination } from '../model/pagination';
import { SearchFieldGroup } from './search/search-field-group';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { CacheService } from '../service/cache.service';

@Directive()
export abstract class BaseSearchComponent<M extends BaseModel> implements OnInit, AfterViewInit {
    dataSource: BaseDatasource<M>;
    filterForm!: UntypedFormGroup;
    loading = false;
    defaultValues;

    pagination: Pagination;

    @ViewChild(MatSort, { static: true }) sort?: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;

    /*
    * Caching has been disabled for now, due to functionality in certain files.
    * TODO: figure out a way to have caching enabled and keep special functionality
    * */
    protected constructor(
        private baseService: BaseService<M>,
        private cacheService: CacheService,
        private cachePrefix: string
    ) {
        this.dataSource = new BaseDatasource<M>(baseService);
    }

    abstract defaultListForm(): FormGroup;

    abstract defaultPagination(): Pagination;

    abstract prepareRestSearch(values: any): SearchFieldGroup;

    ngOnInit() {
        this.dataSource.loading$.subscribe(value => this.loading = value);

        // this.filterForm = this.cacheService.get(`${this.cachePrefix}-filter`, this.defaultListForm())?.item;
        this.filterForm = this.defaultListForm();
        this.defaultValues = { ...this.defaultListForm().value };

        // const pagination: Pagination = this.cacheService.get(`${this.cachePrefix}-pagination`, this.defaultPagination())?.item;
        this.pagination = this.defaultPagination();

        if (this.paginator) {
            this.paginator.pageIndex = this.pagination.page - 1;
            this.paginator.pageSize = this.pagination.limit;
        }
        if (this.sort) {
            this.sort.active = this.pagination.orderBy;
            this.sort.direction = this.pagination.direction as SortDirection;
        }
    }

    ngAfterViewInit() {
        if (this.sort) {
            this.sort.sortChange.subscribe((sortEvent: Sort) => this.doSort(sortEvent));
        }

        if (this.paginator) {
            this.paginator.page.subscribe((pageEvent: PageEvent) => this.doPage(pageEvent));
        }
    }

    doSort(sortEvent: Sort) {
        // const pagination: Pagination = this.cacheService.get(`${this.cachePrefix}-pagination`, new Pagination())?.item;
        this.pagination.orderBy = sortEvent.active;
        this.pagination.direction = sortEvent.direction;
        this.loadData(this.pagination);
    }

    doPage(pageEvent: PageEvent) {
        // const pagination: Pagination = this.cacheService.get(`${this.cachePrefix}-pagination`, new Pagination())?.item;
        this.pagination.page = pageEvent.pageIndex + 1;
        this.pagination.limit = pageEvent.pageSize;
        this.loadData(this.pagination);
    }


    loadData(pagination: Pagination) {
        this.dataSource.load(this.prepareRestSearch({ ...this.filterForm.getRawValue() }), pagination);
    }

    search() {
        // const pagination: Pagination = this.cacheService.get(`${this.cachePrefix}-pagination`, this.defaultPagination())?.item;
        if (this.paginator) {
            this.paginator.pageIndex = 0;
        }
        this.pagination.page = 1;
        this.loadData(this.pagination);
    }

    reset() {
        // this.cacheService.delete(`${this.cachePrefix}-filter`);
        this.filterForm.reset(this.defaultValues);
        this.search();
    }
}
