import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {YesNoDialogComponent} from './yes-no/yes-no-dialog.component';
import {Ledger} from '../model/ledger';
import {CreateLedgerDialogComponent} from './create-ledger/create-ledger-dialog.component';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(private dialog: MatDialog) {

    }

    public showCreateLedgerDialog(): Promise<Ledger> {
        return new Promise<Ledger>((resolve, reject) => {
            const dialogRef = this.dialog.open(CreateLedgerDialogComponent, {
                panelClass: 'dialog-service'
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result === false) {
                    reject();
                } else {
                    resolve(result);
                }
            });
        });
    }

    public routeGuardDialog(): Observable<boolean> {
        return this.showYesNoRef(
            'Wijzigingen gedetecteerd',
            'Wilt u terug zonder uw wijzigingen op te slaan?'
        ).afterClosed();
    }

    public showYesNoRef(title: string, message: string): MatDialogRef<YesNoDialogComponent> {
        return this.dialog.open(YesNoDialogComponent, {
            panelClass: 'dialog-service',
            minWidth: '12rem',
            data: {
                title,
                message
            }
        });
    }

    public showYesNo(title: string, message: string): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            const dialogRef = this.showYesNoRef(title, message);

            dialogRef.afterClosed().subscribe(result => {
                resolve(result);
            });
        });
    }
}
