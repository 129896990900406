import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {timer} from 'rxjs';

@Component({
    selector: 'app-switch-rightholder',
    template: '',
    standalone: true
})
export class SwitchComponent implements AfterViewInit {

    private url;

    constructor(private route:ActivatedRoute,private router: Router) {
        this.url = route.snapshot.params.url;
    }

    ngAfterViewInit(): void {
        // Removing the timer will result in an error: ExpressionChangedAfterItHasBeenCheckedError
        timer(5).subscribe(value => {
            this.redirect();
        });
    }

    redirect() {
        if ( ( typeof this.url === 'undefined') || !this.url || this.url=='undefined') {
            this.url = '/'
        }
        this.router.navigate([ this.url ], {preserveFragment: false, skipLocationChange: false}).catch(() => {
        });
    }

}
