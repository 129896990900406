import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RightHolder } from '../model/rightholder';
import { ApiUtil } from '../util/api.util';
import { ToastrService } from './toastr.service';
import { ReportParams } from '../model/report-params';
import { Specification } from '../model/specification';
import { MatDialog } from "@angular/material/dialog";
import { timer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ReportService {
    private endpoint = `${environment.api_endpoint}/report`;

    constructor(
        private httpClient: HttpClient,
        private toastrService: ToastrService,
        private dialog: MatDialog
    ) {
    }

    public downloadFredCheck(fredCheckProvider: string, stopKdh: string, stopReserve: string, startDate: string, endDate: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.toastrService.info(`Bezig met ophalen rapport`, '', {
                showDuration: '0',
                hideDuration: '0',
                timeOut: '0',
                extendedTimeOut: '0'
            });

            let httpParams = new HttpParams().set('fredCheckProvider', fredCheckProvider);
            if (stopKdh != null) {
                httpParams = httpParams.append('stopKdh', stopKdh);
            }
            if (stopReserve != null) {
                httpParams = httpParams.append('stopReserve', stopReserve);
            }
            if (startDate != null) {
                httpParams = httpParams.append('startDate', startDate);
            }
            if (endDate != null) {
                httpParams = httpParams.append('endDate', endDate);
            }

            this.httpClient.get(`${this.endpoint}/fredCheck`, {
                responseType: 'blob',
                observe: 'response',
                params: httpParams,
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).subscribe(response => {
                ApiUtil.saveToFileSystem(response);
                this.toastrService.clear();
                resolve(true);
                timer(400).subscribe(() => {
                    this.toastrService.success('FredCheck opgehaald', null);
                });

            }, response => {
                console.error(`[ReportService] error getting fredCheck`, response.error);
                this.toastrService.clear();
                reject(response.statusText);
                timer(400).subscribe(() => {
                    this.toastrService.error('FredCheck kon niet worden opgehaald', 'Fout');
                });
            });
        });
    }


    public downloadReport(reportName: string, reportParams?: ReportParams): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.toastrService.info(`Bezig met ophalen rapport`, '', {
                showDuration: '0',
                hideDuration: '0',
                timeOut: '0',
                extendedTimeOut: '0'
            });

            let httpParams = new HttpParams().set('name', reportName);
            if (reportParams != null) {
                if (reportParams.repartition != null) {
                    httpParams = httpParams.append('repartition', reportParams.repartition);
                }
                if (reportParams.rightholder != null) {
                    httpParams = httpParams.append('rightholder', reportParams.rightholder);
                }
                if (reportParams.specification != null) {
                    httpParams = httpParams.append('specification', reportParams.specification);
                }
                if (reportParams.work != null) {
                    httpParams = httpParams.append('work', reportParams.work);
                }
                if (reportParams.start != null) {
                    httpParams = httpParams.append('start', reportParams.start);
                }
                if (reportParams.end != null) {
                    httpParams = httpParams.append('end', reportParams.end);
                }
                if (reportParams.conflict != null) {
                    httpParams = httpParams.append('conflict', reportParams.conflict);
                }
                if (reportParams.fairPeriod != null) {
                    httpParams = httpParams.append('fairperiod', reportParams.fairPeriod);
                }
            }

            let url = this.endpoint;
            if (reportName === 'reportDoubleClaims') {
                url += '/doubleclaims';
            }

            if (reportName === 'additionalRepartition') {
                url += '/additionalRepartition';
            }

            if (reportName === 'NPO-NCP') {
                url += '/nponcp';
            }

            if (reportName === 'conflictLog') {
                url += '/conflictlog';
            }

            if (reportName === 'reportOverspend') {
                url += "/overspend"
            }

            if (reportName === 'allConflictsReport') {
                url += '/allconflicts'
            }

            if (reportName === 'allFairRepertoireItems') {
                url += '/fairrepertoireitems'
            }

            console.log(url)
            this.httpClient.get(url, {
                responseType: 'blob',
                observe: 'response',
                params: httpParams,
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).subscribe(response => {
                ApiUtil.saveToFileSystem(response);
                this.toastrService.clear();
                resolve(true);
                timer(400).subscribe(() => {
                    this.toastrService.success('Rapport opgehaald', null);
                });

            }, response => {
                console.error(`[ReportService] error getting report: ${reportName}`, response.error);
                this.toastrService.clear();
                reject(response.statusText);
                timer(400).subscribe(() => {
                    this.toastrService.error('Rapport kon niet worden opgehaald', 'Fout');
                });
            });
        });
    }

    public downloadConfirmationPDF(rightHolder: RightHolder) {
        return this.httpClient.post(`${this.endpoint}/confirmation`, rightHolder, {
            responseType: 'blob',
            observe: 'response',
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    public downloadSpecificationPdf(specification: Specification): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.httpClient.get(`${this.endpoint}/specification/${specification.id}`, {
                responseType: 'blob',
                observe: 'response',
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).subscribe(response => {
                ApiUtil.saveToFileSystem(response);
                this.toastrService.clear();
                resolve(true);
                timer(400).subscribe(() => {
                    this.toastrService.success('PDF opgehaald', 'Succes');
                });

            }, response => {
                console.error(`[ReportService] error getting specification(${specification.id}) PDF`, response.error);
                this.toastrService.clear();
                reject(response.statusText);
                timer(400).subscribe(() => {
                    this.toastrService.error('PDF kon niet worden gegenereerd', 'Fout');
                });
            });
        });
    }
}
