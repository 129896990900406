export enum Roles {
    /*Dashboad*/
    DASHBOARD_PROSPECTS = 'dashboard.prospects',
    DASHBOARD_RIGHTHOLDERS_NEW = 'dashboard.rightholders.new',
    DASHBOARD_RIGHTHOLDERS_MODIFIED = 'dashboard.rightholders.modified',
    DASHBOARD_CLAIMS = 'dashboard.claims',

    /*Work*/
    WORK_SEARCH = 'work.search',
    WORK_VIEW = 'work.view',
    WORK_EDIT = 'work.edit',
    WORK_COUNTRY_VIEW = 'work.country.view',
    WORK_COUNTRY_EDIT = 'work.edit',
    WORK_PARTICIPANT_VIEW = 'work.participant.view',
    WORK_PARTICIPANT_EDIT = 'work.edit',
    WORK_ALTERNATIVETITLES_VIEW = 'work.alternativeTitles.view',
    WORK_ALTERNATIVETITLES_EDIT = 'work.alternativeTitles.edit',
    WORK_CLAIMS_RIGHTHOLDER = 'work.claims.rightHolder',
    WORK_CLAIMS_SBF = 'work.claims.sbf',
    WORK_CLAIMS_EDIT = 'work.claims.edit',
    WORK_CLAIMS_VIEW = 'work.claims.view',
    WORK_CHILDREN_VIEW = 'work.children.view',
    WORK_BROADCASTS_VIEW = 'work.broadcast.view',
    WORK_SOURCES_EDIT = 'work.sources.edit',
    WORK_PROSPECTS_EDIT = 'work.prospects.edit',
    WORK_MAINTENANCE_EDIT = 'work.maintenance.edit',

    /*Claims*/
    CLAIMS_LIST = 'claims.list',
    CLAIMS_VIEW = 'claims.view',
    CLAIMS_EDIT = 'claims.edit',

    /*Repartition*/
    REPARTITION_SEARCH = 'repartition.search',
    REPARTITION_EDIT = 'repartition.edit',
    REPARTITION_VIEW = 'repartition.view',
    REPARTITION_LEDGER_VIEW = 'repartition.ledger.view',
    REPARTITION_ORGANISATIONS_VIEW = 'repartition.organistations.view',
    REPARTITION_REPORTS_VIEW = 'repartition.reports.view',
    REPARTITION_SPECIFICATIONS_VIEW = 'repartition.specifications.view',
    REPARTITION_SPECIFICATIONS_EDIT = 'repartition.specifications.edit',
    REPARTITION_REREPARTITION = 'repartition.rerepartition',
    REPARTITION_CREATE = 'repartition.create',

    /*RightHolder*/
    RIGHTHOLDER_SEARCH = 'rightholder.search',
    RIGHTHOLDER_MANAGE = 'rightholder.manage',
    RIGHTHOLDER_SWITCH = 'rightholder.switch',
    RIGHTHOLDER_VIEW = 'rightholder.view',
    RIGHTHOLDER_NAME_EDIT = 'rightholder.name.edit',
    RIGHTHOLDER_TYPE_EDIT = 'rightholder.type.edit',
    RIGHTHOLDER_AGICOA_EDIT = 'rightholder.agicoa.edit',
    RIGHTHOLDER_CREDITOR_EDIT = 'rightholder.creditor.edit',
    RIGHTHOLDER_AGENT_EDIT = 'rightholder.agent.edit',
    RIGHTHOLDER_AMERICAN_JAPANESE_EDIT = 'rightholder.americanJapanese.edit',
    RIGHTHOLDER_SEKAM_EDIT = 'rightholder.acceptSekamExpl.edit',
    RIGHTHOLDER_INITIALS_EDIT = 'rightholder.initials.edit',
    RIGHTHOLDER_NAMEFIRST_EDIT = 'rightholder.nameFirst.edit',
    RIGHTHOLDER_NAMEBETWEEN_EDIT = 'rightholder.nameBetween.edit',
    RIGHTHOLDER_NAMELAST_EDIT = 'rightholder.nameLast.edit',
    RIGHTHOLDER_GENDER_EDIT = 'rightholder.gender.edit',
    RIGHTHOLDER_EMAIL_EDIT = 'rightholder.email.edit',
    RIGHTHOLDER_PHONENUMBER_EDIT = 'rightholder.phoneNumber.edit',
    RIGHTHOLDER_CELLNUMBER_EDIT = 'rightholder.cellNumber.edit',
    RIGHTHOLDER_FINANCIALDATA_VIEW = 'rightholder.financialData.view',
    RIGHTHOLDER_FINANCIALDATA_EDIT = 'rightholder.financialData.edit',
    RIGHTHOLDER_PROSPECTS = 'rightholder.prospects',
    RIGHTHOLDER_DOCUMENTS_VIEW = 'rightholder.documents.view',
    RIGHTHOLDER_DOCUMENTS_EDIT = 'rightholder.documents.edit',
    RIGHTHOLDER_CONTACTS_VIEW = 'rightholder.contacts.view',
    RIGHTHOLDER_CONTACTS_EDIT = 'rightholder.contacts.edit',
    RIGHTHOLDER_CONTACTS_GENDER_EDIT = 'rightholder.contacts.gender.edit',
    RIGHTHOLDER_CONTACTS_PHONENUMBER_EDIT = 'rightholder.contacts.phoneNumber.edit',
    RIGHTHOLDER_CONTACTS_CELLNUMBER_EDIT = 'rightholder.contacts.cellNumber.edit',
    RIGHTHOLDER_SPECIFICATIONS_VIEW = 'rightholder.specifications.view',
    RIGHTHOLDER_SPECIFICATIONS_DOWNLOAD = 'rightholder.specifications.download',

    /*RightHolder Maintenance*/
    RIGHTHOLDER_MAINTENANCE_VIEW = 'rightholder.maintenance.view',
    RIGHTHOLDER_MAINTENANCE_SBF = 'rightholder.maintenance.sbf',
    RIGHTHOLDER_MAINTENANCE_CLAIMS = 'rightholder.maintenance.claims',
    RIGHTHOLDER_MAINTENANCE_RELATION = 'rightholder.maintenance.relation',
    RIGHTHOLDER_MAINTENANCE_MEMBERSHIPS = 'rightholder.maintenance.memberships',
    RIGHTHOLDER_MAINTENANCE_DOCUMENTS_VIEW = 'rightholder.maintenance.documents.view',
    RIGHTHOLDER_MAINTENANCE_DOCUMENTS_EDIT = 'rightholder.maintenance.documents.edit',
    RIGHTHOLDER_MAINTENANCE_CHANGES = 'rightholder.maintenance.changes',
    RIGHTHOLDER_MAINTENANCE_REQUIREDACTION = 'rightholder.maintenance.requiredAction',
    RIGHTHOLDER_MAINTENANCE_ACCOUNTACTIONS = 'rightholder.maintenance.accountActions',

    /*Importfiles*/
    IMPORTFILES_VIEW = 'importfiles.view',
    IMPORTFILES_EDIT = 'importfiles.edit',

    /*Broadcasts*/
    BROADCAST_SEARCH = 'broadcast.search',
    BROADCAST_VIEW = 'broadcast.view',
    BROADCAST_EDIT = 'broadcast.edit',

    /*Exports*/
    EXPORT_VIEW = 'export.view',
    EXPORT_VIDEMA = 'export.videma',

    /*Management & Control*/
    MAINTENANCE = 'maintenance.maintenance',
    MAINTENANCE_REPORTS = 'maintenance.reports',

    /*General User Roles*/
    SBF_ACCOUNTMANAGEMENT = 'sbf.usertype.accountManagement',
    SBF_DATAMANAGEMENT = 'sbf.usertype.dataManagement',
    SBF_DATAENTRY = 'sbf.usertype.dataEntry',
    SBF_AUDITOR = 'sbf.usertype.auditor',
    SBF_CLAIMMANAGEMENT = 'sbf.usertype.claimManagement',
    SBF_GENERALMANAGEMENT = 'sbf.usertype.generalManagement',
    // RIGHTHOLDER_CONCEPT = 'rightHolder.usertype.concept', //UNUSED?
    RIGHTHOLDER_MANAGER = 'rightHolder.usertype.manager',
    RIGHTHOLDER_FINANCIAL = 'rightHolder.usertype.financial',
    RIGHTHOLDER_CLAIMS = 'rightHolder.usertype.claims',

    FAIR_RIGHTHOLDER = 'fair.rightholder',
    FAIR_MANAGER = 'fair.manager',

    /*Fair RH*/
    FAIRCBO_PERIOD_LIST = 'fair.period.list',
    FAIRCBO_PERIOD_CLAIM = 'fair.period.claim'
}
