import {Component, Input, OnInit} from '@angular/core';
import {FinancialData} from '../../model/financialdata';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, UpperCasePipe } from '@angular/common';

@Component({
    selector: 'app-financial-data',
    templateUrl: './financial-data.component.html',
    styleUrls: ['./financial-data.component.scss'],
    standalone: true,
    imports: [NgIf, MatIconModule, UpperCasePipe, TranslateModule]
})
export class FinancialDataComponent implements OnInit {
    @Input() financialdata: FinancialData;

    constructor() {
    }

    ngOnInit(): void {
    }

}
