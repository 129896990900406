<p style="margin-left: 16px;font-size: large;">
    Periode aanmaken
</p>
<form [formGroup]="periodForm" (ngSubmit)="createPeriod()">
    <mat-dialog-content class="fair-dialog">
        <div class="column">

            <div class="row">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Periode vanaf tot</mat-label>
                    <mat-date-range-input [rangePicker]="picker" required>
                        <input matStartDate placeholder="Vanaf" formControlName="start">
                        <input matEndDate placeholder="Tot" formControlName="end">
                    </mat-date-range-input>

                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Omschrijving</mat-label>
                    <input type="text" matInput required formControlName="description">
                </mat-form-field>
            </div>

            <div class="row">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Punten minimaal</mat-label>
                    <input matInput type="number" required formControlName="minPoints">
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Kijkdichtheid</mat-label>
                    <input matInput type="number" required formControlName="minKDH">
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Rekenbedrag</mat-label>
                    <span matTextPrefix>€&nbsp;</span>
                    <input matInput type="number" required formControlName="calcAmount" [min]="10000">
                </mat-form-field>
            </div>

            <div class="row">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Selecteer regel</mat-label>
                    <mat-select formControlName="repertoireRule" required>
                        @for (rule of rules; track rule) {
                        <mat-option [value]="rule">{{rule.description}}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Eerste betaalronde</mat-label>
                    <input matInput [min]="minDate" [matDatepicker]="picker2" required formControlName="firstPayment">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

            </div>

            @if (creating) {
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            }

        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-stroked-button type="button" (click)="cancel()" [disabled]="creating">Annuleren</button>
        <button mat-stroked-button type="button" (click)="resetForm()" [disabled]="creating">Reset</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="periodForm.invalid || creating">Maak
            aan</button>
    </mat-dialog-actions>

</form>