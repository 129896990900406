import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SessionService} from '../service/session.service';

@Injectable({
    providedIn: 'root'
})
export class RightHolderInterceptor implements HttpInterceptor {

    constructor(
        private inj: Injector
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isApiCall(req)) {
            const rightHolderId = this.getRightHolderId();
            if (rightHolderId) {
                req = req.clone({
                    setHeaders: {
                        'X-RightHolder': rightHolderId
                    }
                });
            }
        }
        return next.handle(req);
    }

    private isApiCall(req: HttpRequest<any>): boolean {
        if (req.url.startsWith('/api')) {
            return true;
        }

        // Add more conditions if needed

        return false;
    }

    private getRightHolderId(): string {
        const sessionService = this.inj.get(SessionService);
        if (sessionService && sessionService.rightHolder) {
            return '' + sessionService.rightHolder.id;
        }
        return null;
    }
}
