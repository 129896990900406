import { Injectable } from '@angular/core';
import { BaseService } from '../base/base-service';
import { RepartitionConflict } from '../model/repartition-conflict';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Repartition } from '../model/repartition';

@Injectable({
    providedIn: 'root'
})
export class RepartitionConflictService extends BaseService<RepartitionConflict>{

    constructor(httpClient: HttpClient) {
        super(httpClient, 'conflicts');
    }

    create(): RepartitionConflict {
        return null;
    }

    detectConflicts(repartition: Repartition): Observable<RepartitionConflict[]> {
        return this.httpClient.post<RepartitionConflict[]>(`${this.endpoint}/detectForRepartition`, repartition);
    }

    startCRP(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/startcrp`, conflict);
    }

    handleSBF(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/handlesbf`, conflict);
    }

    ignore(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/ignore`, conflict);
    }

    refresh(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/refresh`, conflict);
    }

    pause(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/pause`, conflict);
    }

    undoIgnore(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/undoignore`, conflict);
    }

    setSolved(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/setsolved`, conflict);
    }

    setSolvedOverride(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/setsolvedoverride`, conflict);
    }

    setSolvedBeforeStarted(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/setsolvedbeforestarted`, conflict);
    }

    setFinalSolved(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/setfinalsolved`, conflict);
    }

    resetToPhase2SBF(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/resettophase2sbf`, conflict);
    }

    expire(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/expire`, conflict);
    }
    expired(conflict: RepartitionConflict): Observable<RepartitionConflict> {
        return this.httpClient.put<RepartitionConflict>(`${this.endpoint}/expired`, conflict);
    }


}
