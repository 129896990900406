import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { UploadResult } from '../model/upload-result';
import { DownloadFiles } from '../../rightholder/enum/download-files.enum';
import { ApiUtil } from '../util/api.util';
import { ToastrService } from './toastr.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    private endpoint = `${environment.api_endpoint}/files`;

    constructor(
        private httpClient: HttpClient,
        private toastrService: ToastrService
    ) {
    }

    uploadUgkFile(file: File): Observable<UploadResult> {
        const form = new FormData();
        form.append('file', file, file.name);
        return this.httpClient.post<UploadResult>(`${this.endpoint}/ugk`, form);
    }

    uploadIntomartFile(file: File): Observable<UploadResult> {
        const form = new FormData();
        form.append('file', file, file.name);
        return this.httpClient.post<UploadResult>(`${this.endpoint}/agicoa`, form);
    }

    public downloadFile(filename: DownloadFiles): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.httpClient.get(`${this.endpoint}/${filename}`, {
                responseType: 'blob',
                observe: 'response',
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).subscribe(response => {
                ApiUtil.saveToFileSystem(response);
                this.toastrService.clear();
                resolve(true);
                timer(400).subscribe(() => {
                    this.toastrService.success('PDF opgehaald', 'Succes');
                });

            }, response => {
                console.error(`[FileService] error getting file`, response.error);
                this.toastrService.clear();
                reject(response.statusText);
                timer(400).subscribe(() => {
                    this.toastrService.error('PDF kon niet worden gegenereerd', 'Fout');
                });
            });
        });
    }
}
