import {Component, Input} from '@angular/core';
import {Repartition} from '../../model/repartition';
import {Specification} from '../../model/specification';
import {SpecificationStatus} from '../../enums/specification-status.enum';
import {SpecificationService} from '../../../specification/service/specification.service';
import {ToastrService} from '../../service/toastr.service';
import {TranslateService} from '@ngx-translate/core';
import {Roles} from '../../enums/roles.enum';
import {ReportService} from '../../service/report.service';
import {SessionService} from '../../service/session.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-specification',
    templateUrl: './specification.component.html',
    styleUrls: ['./specification.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatProgressSpinnerModule, DecimalPipe]
})
export class SpecificationComponent {
    @Input() repartition: Repartition;
    @Input() specification: Specification;
    @Input() amountMinusCosts: number;

    saving = false;
    roles: typeof Roles = Roles;

    get actionAllowed(): boolean {
        if (this.sessionService.hasRole(Roles.SBF_CLAIMMANAGEMENT)) {
            return this.specification.status === SpecificationStatus.NA || this.specification.status === SpecificationStatus.CONCEPT
                || this.specification.status === SpecificationStatus.SHOW || this.specification.status === SpecificationStatus.HIDDEN;
        }
        return false;
    }

    constructor(
        private specificationService: SpecificationService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private reportService: ReportService,
        private sessionService: SessionService
    ) {
    }

    setToAdministrativeReasons() {
        this.saving = true;
        this.specification.status = SpecificationStatus.ADMINISTRATIVE_REASON;
        this.specificationService.update(this.specification).subscribe(value => {
            if (value) {
                this.saving = false;
            }
        });
    }

    approveSpecification() {
        this.saving = true;
        this.specificationService.approve(this.specification.id, this.repartition.id).subscribe(value => {
            if (value) {
                this.specification.status = SpecificationStatus.APPROVED;
            }
        });
    }

    acceptSpecification() {
        this.saving = true;
        this.specificationService.accept(this.specification.id).subscribe(value => {
            this.saving = false;
            switch (value.result) {
                case true: {
                    this.translateService.get('EXPORT.EXACT.FINISHED').subscribe(translation => {
                        this.toastrService.success('${translation}', 'Specificatie accepteren');
                    });
                    this.specificationService.findById(this.specification.id).subscribe(spec => {
                        this.specification = spec;
                    });
                    break;
                }
                case false: {
                    this.translateService.get(value.errorMessage.toUpperCase()).subscribe(translation => {
                        this.toastrService.error(`${translation}`, 'Specificatie accepteren');
                    });
                    break;
                }
            }
        }, error => {
            this.saving = false;
            console.error(`[SpecificationEditComponent] error accepting specification`, error.error);
            this.toastrService.error('Accepteren specificatie is niet gelukt', 'Specificatie accepteren');
        });
    }

    downloadPdf() {
        this.reportService.downloadSpecificationPdf(this.specification);
    }

    get canAccept() {
        if (this.specification.status === SpecificationStatus.APPROVED) {
            if (this.sessionService.hasRole(Roles.RIGHTHOLDER_FINANCIAL) ||
                this.sessionService.hasRole(Roles.RIGHTHOLDER_CLAIMS) ||
                this.sessionService.hasRole(Roles.RIGHTHOLDER_MANAGER)) {
                return true;
            } else if (this.sessionService.hasRole(Roles.SBF_CLAIMMANAGEMENT) && this.sessionService.isManagedRightHolder) {
                return true;
            }
        }
        return false;
    }
}
