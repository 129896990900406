import { ConflictAction } from '../enums/conflict-action.enum';
import { ConflictStatus } from '../enums/conflict-status.enum';
import { DirectorStatus } from '../enums/director-status.enum';
import { DocumentType } from '../enums/document-type.enum';
import { Roles } from '../enums/roles.enum';
import { ConflictAssetStatus } from '../enums/conflict-asset-status';
import { Dir } from '@angular/cdk/bidi';

export class HtmlUtil {

    public static showDocumentType(documentType): string {
        if (typeof documentType === 'string') {
            documentType = (<any>DocumentType)[documentType]
        }
        switch (documentType) {
            case DocumentType.ID:
                return 'Legitimatiebewijs';
            case DocumentType.KVK:
                return 'KvK inschrijving';
            case DocumentType.CONFIRMATION:
                return 'Bevestigings document';
            case DocumentType.DEBIT_CARD:
                return 'Bankpas';
            case DocumentType.REQUEST_ADVISOR:
                return 'Aanvraag adviseur';
            case DocumentType.SETTLEMENT_AGREEMENT:
                return 'Vaststellingsovereenkomst';
            case DocumentType.SUMMONS:
                return 'Dagvaarding';
            case DocumentType.JUDGMENT:
                return 'Uitspraak';
            case DocumentType.CONFLICT_DOCUMENT:
                return 'Conflictdocument';
            case DocumentType.CLAIM_PROOF:
                return 'Bewijs voor claim';
            default:
                return 'Onbekend';
        }
    }

    public static showDocumentDescription(docType: DocumentType): string {
        switch (docType) {
            case DocumentType.REQUEST_ADVISOR:
                return 'officieel verzoek tot het aanstellen van een bindend adviseur';
            case DocumentType.SETTLEMENT_AGREEMENT:
                return 'ondertekend document waarin het akkoord tussen partijen staat beschreven';
            case DocumentType.SUMMONS:
                return 'een kopie van de inleidende dagvaarding';
            case DocumentType.JUDGMENT:
                return 'kopie van de officiële uitspraak';
            case DocumentType.CONFLICT_DOCUMENT:
                return 'indien u uw claim wilt intrekken of aanpassen';
            case DocumentType.CLAIM_PROOF:
                return 'document waaruit blijkt dat uw claim gerechtvaardigd is';
            default:
                return 'Onbekend';
        }
    }

    public static showAmpStatus(statusRaw: string): string {
        switch (statusRaw) {
            case 'OEA':
                return 'Succesvol geidentificeerd';
            case 'FA':
                return 'voorlopig geannuleerd';
            case 'GA':
                return 'Order verlopen';
            case 'OGB':
                return 'Niet succesvol geidentificeerd';
            case 'OEACHECK':
                return 'Niet volledig geidentificeerd';
            case 'OVAA':
                return 'Openstaande order';
            case 'OPLANNING':
                return 'Order proces gestart';
            case 'VAA':
                return 'Openstaande order voor IoL';
            case 'OFB':
                return 'Doorlopen app process';
            case 'OFA':
                return 'App process niet doorlopen, order naar VAA (op locatie) - bv na aantal mislukte pogingen NFC';
            case 'CA':
                return 'Afspraak gemaakt op locatie';
            case 'FA':
                return 'Voorlopig geannuleerd';
            case 'HM':
                return 'Herinnering verstuurd naar klant';
            case 'HM2':
                return 'Laatste herinnering verstuurd';
            case 'CANCELED':
                return 'Order geannuleerd';
            case 'TVAA':
                return 'Order ontvangen van opdrachtgever, moet nog verwerkt worden';
            default:
                return statusRaw+' unknown';
        }
    }
    public static showRoleText(role: string): string {
        switch (role) {
            case Roles.RIGHTHOLDER_MANAGER:
                return 'Manager';
            case Roles.RIGHTHOLDER_FINANCIAL:
                return 'Financial';
            case Roles.RIGHTHOLDER_CLAIMS:
                return 'Claims';
            default:
                return '';
        }
    }

    public static showChosenAction(action: ConflictAction): string {
        switch (action) {
            case ConflictAction.UPDATE:
                return 'aanpassen van de claim';
            case ConflictAction.MAINTAIN_MEDIATION_SBF:
                return 'bemiddeling door SBF';
            case ConflictAction.MAINTAIN_MEDIATION_ADVISOR:
                return 'bemiddeling door een adviseur';
            case ConflictAction.MAINTAIN_LAWSUIT:
                return 'een rechtszaak';
        }
    }

    public static showStatusAsAction(status: ConflictStatus): string {
        switch (status) {
            case ConflictStatus.PHASE_TWO_ADVISOR:
                return 'bemiddeling door een adviseur';
            case ConflictStatus.PHASE_TWO_LAWSUIT:
                return 'een rechtszaak';
        }
    }

    public static showObligationByStatus(status: ConflictStatus): string {
        switch (status) {
            case ConflictStatus.PHASE_TWO_ADVISOR:
                return 'het verzoek tot aanstelling van een adviseur';
            case ConflictStatus.PHASE_TWO_LAWSUIT:
                return 'de inleidende dagvaarding';
        }
    }

    public static showAssetStatus(status: ConflictAssetStatus): string {
        switch (status) {
            case ConflictAssetStatus.INIT:
                return 'Geüpload';
            case ConflictAssetStatus.APPROVED:
                return 'Goedgekeurd';
            case ConflictAssetStatus.REJECTED:
                return 'Afgekeurd';
            case ConflictAssetStatus.HOLD:
                return 'Gepauzeerd';
        }
    }

    public static showDirectorStatus(status: DirectorStatus): string {
        switch (status) {
            case DirectorStatus.MODIFIED:
                return 'Gewijzigd';
            case DirectorStatus.APPROVED:
                return 'Goedgekeurd';
            case DirectorStatus.DELETED:
                return 'Verwijderd';
            case DirectorStatus.AMP_FAIL:
                return 'AMP aanvraag mislukt';
            case DirectorStatus.AMP_REQUESTED:
                return 'AMP aanvraag gedaan';
            case DirectorStatus.AMP_REJECTED:
                return 'AMP aanvraag afgewezen';
        }
    }
}
