import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appDragDrop]',
    standalone: true
})
export class DragDropDirective {
    @Output() fileDropped = new EventEmitter<any>();

    @HostBinding('style.opacity') private opacity: string;
    @HostBinding('style.border') private border: string;

    constructor() {
        this.setDefault();
    }

    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.opacity = '0.7';
        this.border = '4px dashed #777';
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.setDefault();
    }

    @HostListener('drop', ['$event'])
    public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.setDefault();
        const files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }

    setDefault() {
        this.opacity = '1';
        this.border = '4px dashed #ddd';
    }

}
