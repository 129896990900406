import {BehaviorSubject, Observable} from 'rxjs';
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {finalize, map} from 'rxjs/operators';
import {BaseService} from './base-service';
import {Pagination} from '../model/pagination';
import {BaseModel} from './base-model';
import {SearchFieldGroup} from './search/search-field-group';
import {RestSearchUtil} from '../util/rest-search-util';

export class BaseDatasource<M extends BaseModel> implements DataSource<M> {
    private dataSubject = new BehaviorSubject<M[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public records = 0;

    constructor(private baseApi: BaseService<M>) {
    }

    public get loading$(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }

    public get subject$(): Observable<M[]> {
        return this.dataSubject.asObservable();
    }

    public get data(): M[] {
        return this.dataSubject.getValue();
    }

    public set data(data: M[]) {
        this.dataSubject.next(data);
    }

    connect(collectionViewer: CollectionViewer): Observable<M[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }

    load(filter: SearchFieldGroup, pagination: Pagination) {
        this.loadingSubject.next(true);

        const searchRequest = RestSearchUtil.createMatSearchRequest(pagination, filter);
        this.baseApi.findByRequest(searchRequest).pipe(
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(listResult => {
            this.dataSubject.next(listResult.data);
            this.records = listResult.records;
        });
    }
}
