<div class="sbf-menu">
    <div>
        <ul class="sbf-navbar">
            <li class="sbf-navbar-item" *ngIf="showRightHolderLink">
                <span *ngIf="currentRightHolder">
                    <a routerLinkActive="active" [routerLink]="[base(), currentRightHolder.id]">
                        {{rightHolderLinkText}}
                    </a>
                </span>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.RIGHTHOLDER_SEARCH">
                <a routerLinkActive="active" routerLink="/rightholders">{{ rightHolderSearchText }}</a>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.SBF_DATAMANAGEMENT">
                <a routerLinkActive="active" routerLink="/fair/period/list">Fair Repartities</a>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.CLAIMS_LIST">
                <a routerLinkActive="active" routerLink="/claims">Claims</a>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.WORK_VIEW">
                <!--                <span *ngIf="!currentRightHolder">-->
                <a routerLinkActive="active" routerLink="/works">{{workLinkText}}</a>
                <!--                </span>-->
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.REPARTITION_VIEW">
                <a routerLinkActive="active" routerLink="/repartitions">Repartities</a>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.IMPORTFILES_VIEW">
                <a routerLinkActive="active" routerLink="/importfiles">Dagen</a>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.BROADCAST_SEARCH">
                <a routerLinkActive="active" routerLink="/broadcasts">Uitzendingen</a>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.EXPORT_VIEW">
                <a routerLinkActive="active" routerLink="/exports">Exports</a>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.SBF_DATAMANAGEMENT">
                <a routerLinkActive="active" routerLink="/channels">Kanalen</a>
            </li>

            <li class="sbf-navbar-item"
                *ngxPermissionsOnly="[roles.SBF_DATAMANAGEMENT,roles.SBF_DATAENTRY,roles.SBF_GENERALMANAGEMENT,roles.SBF_CLAIMMANAGEMENT,roles.SBF_ACCOUNTMANAGEMENT]">
                <a routerLinkActive="active" routerLink="/prospects">Prospects</a>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="[roles.SBF_DATAMANAGEMENT,roles.SBF_GENERALMANAGEMENT]">
                <a routerLinkActive="active" routerLink="/fair">FAIR</a>
            </li>


            <li class="sbf-navbar-item" *ngxPermissionsOnly="[roles.MAINTENANCE, roles.MAINTENANCE_REPORTS]">
                <a routerLinkActive="active" routerLink="/maintenance">{{'TEXT.MENU.MANAGEMENTANDCONTROL' |
                    translate}}</a>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="[roles.SBF_GENERALMANAGEMENT, roles.SBF_DATAMANAGEMENT]">
                <a routerLinkActive="active" routerLink="/faircbo">FairCBO</a>
            </li>
        </ul>
    </div>

    <span class="spacer text-right" *ngIf="showSwitch">
        <span class="spacer text-right" *ngxPermissionsOnly="roles.RIGHTHOLDER_SWITCH">Selecteer:</span>
        <button [matMenuTriggerFor]="menuRightHolder" mat-button
            *ngxPermissionsOnly="roles.RIGHTHOLDER_SWITCH">{{currentRightHolder?.description}}</button>

        <mat-menu #menuRightHolder="matMenu" class="rightholder-select">
            <button mat-menu-item *ngFor="let rh of allowedRightHolders" [ngClass]="{'active': isRightHolderActive(rh)}"
                [matTooltip]="rh.description.length > 40 ? rh.description : undefined"
                (click)="switchRightHolder(rh)">{{rh.description}}</button>
        </mat-menu>
    </span>
</div>
