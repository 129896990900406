import { RepartitionConflictClaim } from '../../shared/model/repartition-conflict-claim';
import { ConflictUtil } from '../../shared/util/conflict-util';
import { ConflictAction } from '../enums/conflict-action.enum';

export class ConflictClaimUtil {
    public static actionDescription(conflictClaim: RepartitionConflictClaim): string {
        if (conflictClaim.chosenAction == null) {

            if (ConflictUtil.isInactive(conflictClaim.conflict)) {
                return '';
            }
            return 'Nog geen keuze gemaakt';
        }

        if (conflictClaim.chosenAction.conflictAction === ConflictAction.UPDATE) {
            return `Claimpercentage aanpassen naar ${conflictClaim.newPercentage}%`
        }

        return conflictClaim.chosenAction.description;
    }

    public static tooltip(claim: RepartitionConflictClaim): string {
        if (ConflictUtil.isInactive(claim.conflict) || claim.chosenAction != null) {
            return 'Bekijk details';
        }
        return 'Klik om op te lossen';
    }

    public static choiceStyle(claim: RepartitionConflictClaim): object {
        if (claim.chosenAction == null) {
            return {
                'color': '#C0C0C0',
                'font-weight': '400',
                'font-size': '12px',
            };
        }
    }
}
