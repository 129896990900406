import { Injectable } from '@angular/core';
import { BaseService } from '../base/base-service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from './toastr.service';
import { Observable } from 'rxjs';
import { RepartitionConflictAsset } from "../model/repartition-conflict-asset";
import { ConflictAssetStatus } from '../enums/conflict-asset-status';

@Injectable({
    providedIn: 'root'
})
// @ts-ignore
export class RepartitionConflictAssetService extends BaseService<RepartitionConflictAsset>{

    constructor(httpClient: HttpClient, private toastrService: ToastrService) {
        super(httpClient, 'conflictassets');
    }

    create(): RepartitionConflictAsset {
        return {
            conflict: null,
            rightHolder: null,
            asset: null,
            status: ConflictAssetStatus.INIT
        };
    }

    uploaded(conflictAsset: RepartitionConflictAsset): Observable<RepartitionConflictAsset> {
        return this.httpClient.post<RepartitionConflictAsset>(`${this.endpoint}/uploaded`, conflictAsset);
    }
    approve(conflictAsset: RepartitionConflictAsset): Observable<RepartitionConflictAsset> {
        return this.httpClient.put<RepartitionConflictAsset>(`${this.endpoint}/approve`, conflictAsset);
    }
    reject(conflictAsset: RepartitionConflictAsset): Observable<RepartitionConflictAsset> {
        return this.httpClient.put<RepartitionConflictAsset>(`${this.endpoint}/reject`, conflictAsset);
    }
}
