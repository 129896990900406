<div class="specification-row">
    <table>
        <tbody>
        <tr>
            <td>Totaalbedrag inclusief kosteninhouding:</td>
            <td class="cell-align-right">{{specification.specificationBaseExcl | number: '1.2-2'}}</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>{{specification.vatPercentage | number: '1.0-2'}}% BTW over uitkering:</td>
            <td class="cell-align-right single-bottom-line">{{specification.specificationBaseVat | number: '1.2-2'}}</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>Subtotaal:</td>
            <td class="cell-align-right">{{specification.specificationBaseIncl | number: '1.2-2'}}</td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td colspan="3">&nbsp;</td>
        </tr>
        <tr>
            <td>Kosteninhouding {{specification.mainCostsPercentage | number: '1.0-2'}}% over {{specification.specificationBaseExcl | number: '1.2-2'}}:</td>
            <td class="cell-align-right">{{specification.mainCostsExcl | number: '1.2-2'}}</td>
            <td>-</td>
        </tr>
        <tr>
            <td>{{specification.vatPercentage | number: '1.0-2'}}% BTW over kosten:</td>
            <td class="cell-align-right single-bottom-line">{{specification.mainCostsVat | number: '1.2-2'}}</td>
            <td>-</td>
        </tr>
        <tr>
            <td>Subtotaal:</td>
            <td class="cell-align-right">{{specification.mainCostsIncl | number: '1.2-2'}}</td>
            <td>-</td>
        </tr>
        <tr>
            <td colspan="3">&nbsp;</td>
        </tr>
        <tr *ngIf="specification.socuCostsPercentage > 0">
            <td>Socu kosten {{specification.socuCostsPercentage}}%:</td>
            <td class="cell-align-right">{{specification.socuCostsExcl | number: '1.2-2'}}</td>
            <td>&nbsp;</td>
        </tr>
        <tr *ngIf="specification.socuCostsPercentage > 0">
            <td colspan="3">&nbsp;</td>
        </tr>
        <tr class="specification-total">
            <td>Totaal:</td>
            <td class="cell-align-right double-bottom-line">{{specification.specificationTotal | number: '1.2-2'}}</td>
            <td>&nbsp;</td>
        </tr>
        <!--        #SBFP-759-->
        <!--        <tr class="specification-without-costs"> -->
        <!--            <td>Bedrag minus kosten:</td>-->
        <!--            <td class="cell-align-right">{{amountMinusCosts | number: '1.2-2'}}</td>-->
        <!--            <td>&nbsp;</td>-->
        <!--        </tr>-->
        </tbody>
    </table>

    <div class="specification-actions">
        <button mat-flat-button color="primary" (click)="setToAdministrativeReasons()" [disabled]="saving" *ngIf="actionAllowed">
            <span *ngIf="!saving">Administratieve reden</span>
            <mat-progress-spinner color="accent" mode="indeterminate" diameter="35" *ngIf="saving"></mat-progress-spinner>
        </button>

        <button mat-flat-button color="primary" (click)="approveSpecification()" [disabled]="saving" *ngIf="actionAllowed">
            <span *ngIf="!saving">Goedkeuren</span>
            <mat-progress-spinner color="accent" mode="indeterminate" diameter="35" *ngIf="saving"></mat-progress-spinner>
        </button>

        <button mat-flat-button color="primary" (click)="acceptSpecification()" [disabled]="saving" *ngIf="canAccept">
            <span *ngIf="!saving">Accepteren</span>
            <mat-progress-spinner color="accent" mode="indeterminate" diameter="35" *ngIf="saving"></mat-progress-spinner>
        </button>

        <button mat-flat-button color="primary" (click)="downloadPdf()">Download PDF</button>
    </div>
</div>
