import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { RepartitionConflict } from 'src/app/shared/model/repartition-conflict';
import { RightHolder } from 'src/app/shared/model/rightholder';
import { RepartitionConflictClaim } from 'src/app/shared/model/repartition-conflict-claim';
import { RepartitionConflictClaimService } from 'src/app/shared/service/repartition-conflict-claim.service';
import { ToastrService } from 'src/app/shared/service/toastr.service';
import { Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DownloadFiles } from "../../../../rightholder/enum/download-files.enum";
import { Asset } from "../../../model/asset";
import { DocumentType } from "../../../enums/document-type.enum";
import { ConflictUtil } from 'src/app/shared/util/conflict-util';
import { RightHolderService } from "../../../../rightholder/service/rightholder.service";
import { Roles } from "../../../enums/roles.enum";
import { DialogService } from "../../dialog.service";
import { RepartitionConflictActionLogService } from "../../../service/repartition-conflict-action-log.service";
import { RepartitionConflictActionLog } from "../../../model/repartition-conflict-action-log";
import { ConflictStatus } from "../../../enums/conflict-status.enum";
import { RepartitionConflictAssetService } from "../../../service/repartition-conflict-asset.service";
import { ConflictClaimDialogComponent } from "../conflict-claim-dialog.component";
import { ConflictAssetStatus } from 'src/app/shared/enums/conflict-asset-status';
import { MatTableModule } from '@angular/material/table';
import { DragDropDirective } from '../../../directives/drag-drop.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { OnlyNumberDirective } from '../../../directives/only-number.directive';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgTemplateOutlet, DatePipe } from '@angular/common';

type ActionFn = (choice: string, conflictClaim: RepartitionConflictClaim) => Observable<RepartitionConflictClaim>

/*
    WARNING:
    This component is kept here to keep Phase 2 logic already developed, like uploading documents etc.

    Phase One logic is also still here but has been refactored into src/app/rightholder/rightholder-edit/rightholder-conflict-panel
*/
@Component({
    selector: 'app-claim-decision',
    templateUrl: './claim-decision.component.html',
    styleUrls: ['./claim-decision.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, MatIconModule, NgTemplateOutlet, MatGridListModule, FormsModule, ReactiveFormsModule, MatRadioModule, MatTooltipModule, MatButtonModule, MatFormFieldModule, MatInputModule, OnlyNumberDirective, MatSelectModule, MatOptionModule, DragDropDirective, MatTableModule, DatePipe]
})
export class ClaimDecisionComponent implements OnInit {
    roles: typeof Roles = Roles;
    rhConflictClaim: RepartitionConflictClaim;
    log: RepartitionConflictActionLog;

    @Input() rightHolder: RightHolder;
    @Input() conflict: RepartitionConflict;

    uploadForm: UntypedFormGroup;
    chooseForm: UntypedFormGroup;
    @ViewChild('fileUpload') fileUpload;

    documentTypes: typeof DocumentType = DocumentType;
    downloadFiles = DownloadFiles;
    assets: Asset[] = [];

    @Output() claimUpdateEvent: EventEmitter<number> = new EventEmitter();

    newPercentage: number;

    hasChooseSolution: boolean = true;
    hasClaimAdjust: boolean = false;
    hasClaimWithdraw: boolean = false;
    maintainDisabled: boolean = false;
    adjustDisabled: boolean = false;
    hasUpload: boolean = false;
    hasText: boolean = false;
    hasLog: boolean = false;
    hasUploadStatus: boolean = false;
    textType: number;

    constructor(private formBuilder: UntypedFormBuilder,
        private conflictClaimDialogComponent: ConflictClaimDialogComponent,
        private conflictClaimService: RepartitionConflictClaimService,
        private conflictAssetService: RepartitionConflictAssetService,
        private logService: RepartitionConflictActionLogService,
        private rightHolderService: RightHolderService,
        private dialogService: DialogService,
        private toastrService: ToastrService) {
        this.rhConflictClaim = this.conflictClaimService.create();
        this.uploadForm = this.formBuilder.group({
            documentType: [null, Validators.required]
        });
        this.chooseForm = this.formBuilder.group({
            solution: [null, Validators.required]
        });
    }

    displayedColumns = [
        'date',
        'actiondesc',
        'by',
        'endstate'
    ];
    logs: RepartitionConflictActionLog[];

    pageSizeOptions: number[] = [5, 10];

    ngOnInit(): void {
        this.uploadForm.controls.documentType.setValue(DocumentType.CONFLICT_DOCUMENT)
        this.conflictClaimService.getClaimFor(this.rightHolder, this.conflict).subscribe(rcc => {
            this.rhConflictClaim = rcc
            if (this.isPhase2()) {
                this.hasUpload = true; // phase2 should always allow upload
                let v = ConflictStatus[this.rhConflictClaim.chosenAction.id].replace('_PHASE2', '')
                this.chooseForm.controls.solution.setValue(v);
                // if (v.startsWith('MAINTAIN')) {
                //     this.maintainDisabled = true;
                // }
                // if (v == ConflictStatus[ConflictStatus.PHASE_TWO]) {
                //     this.adjustDisabled = true;
                //     this.chooseForm.controls.solution.setValue('UPDATED');
                // }
            }
        });

        this.updateLogs();
        this.loadAssets();
    }

    daysBeforeDeadline(conflict: RepartitionConflict): string {
        return ConflictUtil.daysBeforeDeadline(conflict);
    }

    isFinished(conflict: RepartitionConflict): boolean {
        return ConflictUtil.isInactive(conflict);
    }

    updateLogs() {
        this.logService.getFor(this.rightHolder, this.conflict).subscribe(logs => {
            this.logs = logs
        });
    }

    decisionMade(): boolean {
        return this.rhConflictClaim.newPercentage !== null;
    }

    isPhase2(): boolean {
        this.hasLog = true;
        return ConflictUtil.isPhaseTwo(this.conflict);
    }

    isAlreadySolved(): boolean {
        return this.rhConflictClaim.newPercentage == null && this.conflict.conflictStatus.conflictStatus === ConflictStatus.SOLVED;
    }

    decision(): string {
        let s = '';
        if (this.logs) {
            s = ' : ' + this.logs[0].action.description
        }

        if (this.rhConflictClaim.newPercentage === 0) {
            return "de claim in te trekken";
        }

        if (this.rhConflictClaim.newPercentage === this.rhConflictClaim.mainClaim.percentage) {
            return `het claimpercentage te behouden op ${this.rhConflictClaim.newPercentage}%` + s;
        }

        if (this.rhConflictClaim.newPercentage !== this.rhConflictClaim.mainClaim.percentage) {
            return `het claimpercentage aan te passen naar ${this.rhConflictClaim.newPercentage}%` + s;
        }
    }

    chooseSolution(value): void {
        console.log("value = " + value);
        switch (value) {
            case "UPDATE":
                this.hasClaimAdjust = true;
                this.hasClaimWithdraw = false;
                this.hasText = this.hasUpload = false;
                break;
            case "RETRACT":
                this.hasClaimWithdraw = true;
                this.hasClaimAdjust = false;
                this.hasText = this.hasUpload = false;
                break;
            case "MAINTAIN_MEDIATION_SBF":
            case "MAINTAIN_MEDIATION_ADVISOR":
            case "MAINTAIN_LAWSUIT":
                this.hasClaimWithdraw = false;
                this.hasClaimAdjust = false;
                this.hasText = true;
                this.hasUpload = this.isPhase2();
                this.textType = value;
                break;
        }
        console.log("this.hasClaimAdjust = " + this.hasClaimAdjust);
    }

    canAdjust(): boolean {
        return (this.newPercentage != this.rhConflictClaim.mainClaim.percentage) && (this.newPercentage >= 0 && this.newPercentage <= 100);
    }

    maintain() {
        switch (this.chooseForm.get('solution').value) {
            case 'MAINTAIN_MEDIATION_SBF':
                this.rhConflictClaim.newPercentage = this.rhConflictClaim.mainClaim.percentage;
                console.log("Conflict claim new perc: " + this.rhConflictClaim.newPercentage);
                this.doAction(null, this.conflictClaimService.maintainSBF.bind(this.conflictClaimService), this.rhConflictClaim, 'Claim behouden: bemiddeling door SBF', 'Fout tijdens behouden claim');
                break;
            case 'MAINTAIN_MEDIATION_ADVISOR':
                this.doAction(null, this.conflictClaimService.maintainAdvisor.bind(this.conflictClaimService), this.rhConflictClaim, 'Claim behouden: bemiddeling door advideur', 'Fout tijdens behouden claim');
                break;
            case 'MAINTAIN_LAWSUIT':
                this.doAction(null, this.conflictClaimService.maintainLawsuit.bind(this.conflictClaimService), this.rhConflictClaim, 'Claim behouden: rechtszaak', 'Fout tijdens behouden claim');
                // this.doAction(this.chooseForm.get('solution').value + (this.isPhase2() ? '_PHASE2' : ''), this.conflictClaimService.maintain.bind(this.conflictClaimService), this.rhConflictClaim, 'Wijziging verwerkt', 'Fout tijdens wijzigen');
                break;
        }
    }


    retract() {
        this.rhConflictClaim.newPercentage = 0;
        this.doAction(null, this.conflictClaimService.retract.bind(this.conflictClaimService), this.rhConflictClaim, 'Claim voor dit conflict is ingetrokken', 'Fout tijdens intrekken claim');
    }

    setNewPercentage() {
        this.rhConflictClaim.newPercentage = this.newPercentage;
        this.doAction(null, this.conflictClaimService.setNewPercentage.bind(this.conflictClaimService), this.rhConflictClaim, 'Claimpercentage voor dit conflict is aangepast', 'Fout tijdens aanpassen claim');
    }

    doAction(choice: string, actionFn: ActionFn, conflictClaim: RepartitionConflictClaim, successMessage: string, errorMessage: string) {
        return actionFn(choice, conflictClaim).subscribe(
            () => this.toastrService.success(successMessage, ''),
            () => {
                this.toastrService.error(errorMessage, '');
                this.rhConflictClaim.newPercentage = null;
            },
            () => {
                this.conflictClaimService.getClaimFor(this.rightHolder, this.conflict).subscribe(rcc =>
                    this.rhConflictClaim = rcc);
                this.claimUpdateEvent.emit(this.rhConflictClaim.id);
            }
        )
    }

    droppedFile(event) {
        for (const element of event) {
            this.uploadFile(element);
        }
    }

    uploadDocument() {
        this.uploadFile(this.fileUpload.nativeElement.files[0]);
    }

    uploadFile(element: File) {
        // do the upload
        this.rightHolderService.upload(
            this.rightHolder.id, this.uploadForm.controls.documentType.value, element)
            .subscribe(value => {
                this.hasUploadStatus = true;
                this.loadAssets();

                this.claimUpdateEvent.emit(this.rhConflictClaim.id);

                // send a mail about the upload, add the assetId to put in the log which will be created
                let conflictAsset = this.conflictAssetService.create();
                conflictAsset.conflict = this.conflict;
                conflictAsset.rightHolder = this.rightHolder;
                conflictAsset.asset = value;
                conflictAsset.status = ConflictAssetStatus.INIT;

                this.conflictAssetService.uploaded(conflictAsset).subscribe(
                    val => {
                        this.updateLogs();
                    }
                );


            }, error => {
                let errorMessage = error.error;
                if (error.status === 0) {
                    errorMessage = 'Onbekende error';
                }
                this.toastrService.error(`Upload Mislukt: ${errorMessage}`, 'Uploaden');
                console.error('[ClaimDecisionComponent] can not upload: ', error.error);
            });
    }

    get documentType() {
        return this.uploadForm.controls.documentType.value;
    }

    loadAssets() {
        this.rightHolderService.assets(this.rightHolder.id).subscribe(value => {
            this.assets = value;
        });
    }

    help(slug: string) {
        if (this.isPhase2()) {
            slug = slug + '_phase2';
        }
        this.conflictClaimDialogComponent.help(slug);
    }

}
