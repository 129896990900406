<form class="search-form" (ngSubmit)="doSearch()" [formGroup]="filterForm">
    <mat-card-content>
        <mat-form-field class="search-work-id">
            <mat-label>Werk ID</mat-label>
            <input matInput formControlName="id">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Titel</mat-label>
            <input matInput formControlName="workTitle.title">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Subtitel</mat-label>
            <input matInput formControlName="workTitle.subTitle">
        </mat-form-field>

        <mat-form-field>
            <mat-label>ISAN</mat-label>
            <input matInput formControlName="isan">
        </mat-form-field>

        <mat-form-field>
            <mat-label>AGICOA</mat-label>
            <input matInput formControlName="agicoa">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Genre</mat-label>
            <mat-select formControlName="genre.id">
                <mat-option *ngFor="let genre of genres" [value]="genre.id">
                    <span translate> {{genre.description | uppercase}} </span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Categorie</mat-label>
            <mat-select formControlName="category.id">
                <mat-option *ngFor="let category of categories" [value]="category.id"><span translate>{{category.description | uppercase}}</span></mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Werktype</mat-label>
            <mat-select formControlName="workType.id">
                <mat-option *ngFor="let workType of workTypes" [value]="workType.id">
                    <span translate>{{workType.description | uppercase}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Werk niveau</mat-label>
            <mat-select formControlName="level">
                <mat-option [value]="1">Hoofdfilm</mat-option>
                <mat-option [value]="2">Seizoen</mat-option>
                <mat-option [value]="3">Episode</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select formControlName="workStatus.id">
                <mat-option *ngFor="let status of workStatus; let i = index" [value]="i">
                    <span translate>LABEL.WORKSTATUS.{{status}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

    </mat-card-content>

    <mat-card-actions>
        <button mat-flat-button type="button" (click)="doReset()">Reset</button>
        <button mat-flat-button type="submit" color="primary">Zoeken</button>
    </mat-card-actions>
</form>
