import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxTimepickerFieldComponent, NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, FormsModule, ReactiveFormsModule, NgxMaterialTimepickerModule]
})
export class DateTimePickerComponent implements OnInit {
    date: UntypedFormControl;
    @Input() inputTime = '00:00';
    @Input() inputDate: Date = new Date();
    @Output() outputDate = new EventEmitter<Date>();
    @ViewChild(NgxTimepickerFieldComponent) timePicker: NgxTimepickerFieldComponent;
    @ViewChild(MatDatepicker) datePicker: MatDatepicker<Date>;

    constructor() {
    }

    ngOnInit() {
        this.date = new UntypedFormControl(this.inputDate);
    }

    emitDate() {
        const outputDate = new Date(this.date.value);
        const hour = this.timePicker.timepickerTime.match('^[^:]*');
        const minutes = this.timePicker.timepickerTime.match('[^:]*$');
        outputDate.setHours(+hour.toString(), +minutes.toString());
        this.outputDate.emit(outputDate);
    }
}
