<table mat-table [dataSource]="dataSource" [ngClass]="{'sbf-only': isSBF}">
    <ng-container matColumnDef="subtitle">
        <th mat-header-cell *matHeaderCellDef>Subtitel</th>
        <td mat-cell *matCellDef="let conflictClaim">
            {{conflictClaim.mainClaim.workRightType.work.originalTitle.subTitle}}
        </td>
    </ng-container>

    <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef>Gelegd op</th>
        <td mat-cell *matCellDef="let conflictClaim">
            {{workUtil.workLevelString(conflictClaim.mainClaim.workRightType.work.level)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="rh">
        <th mat-header-cell *matHeaderCellDef>Geclaimd door</th>
        <td mat-cell *matCellDef="let conflictClaim">
            {{conflictClaim.mainClaim.rightHolderByRightHolderId.description}}
        </td>
    </ng-container>

    <ng-container matColumnDef="percentage">
        <th mat-header-cell *matHeaderCellDef>Claim(%)</th>
        <td mat-cell *matCellDef="let conflictClaim">
            {{conflictClaim.mainClaim.percentage}}
        </td>
    </ng-container>

    <ng-container matColumnDef="newPercentage">
        <th mat-header-cell *matHeaderCellDef>Conflict (%)</th>
        <td mat-cell *matCellDef="let conflictClaim">
            {{conflictClaim.newPercentage}}
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Gemaakte keuze in Fase 1</th>
        <td mat-cell *matCellDef="let conflictClaim" [ngStyle]="conflictClaimUtil.choiceStyle(conflictClaim)">
            {{conflictClaimUtil.actionDescription(conflictClaim)}}
        </td>
    </ng-container>

    <ng-container matColumnDef="actionDate">
        <th mat-header-cell *matHeaderCellDef>Datum reactie</th>
        <td mat-cell *matCellDef="let conflictClaim">
            {{conflictClaim.chosenAction == null ? '' : (conflictClaim.lastModified | date: 'shortDate')}}
        </td>
    </ng-container>


    <ng-container matColumnDef="newPercentageSBF">
        <th mat-header-cell *matHeaderCellDef>Besluit (%)</th>
        <td (click)="$event.stopPropagation();" mat-cell *matCellDef="let conflictClaim">
            <input matInput appOnlyNumber="decimal" [disabled]="isSolved" [(ngModel)]="conflictClaim.sbfPercentage" placeholder="{{conflictClaim.sbfPercentage}}">
            <button mat-mini-fab class="set" color="primary" [disabled]="isSolved" (click)="setSbfPercentage(conflictClaim)">Set
            </button>
            <mat-icon [hidden]="!justSet">check_circle_outline</mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="from">
        <th mat-header-cell *matHeaderCellDef>Vanaf</th>
        <td mat-cell *matCellDef="let conflictClaim">
            {{conflictClaim.mainClaim.validFrom | date: 'shortDate'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="until">
        <th mat-header-cell *matHeaderCellDef>Einde</th>
        <td mat-cell *matCellDef="let conflictClaim">
            {{conflictClaim.mainClaim.validUntil | date: 'shortDate'}}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="goToWork(row)"></tr>
</table>