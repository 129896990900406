import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { RightHolder } from '../model/rightholder';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { Roles } from '../enums/roles.enum';
import { RightHolderStatus } from '../enums/right-holder-status.enum';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public defaultRightHolder: RightHolder;
    private allowedRightHoldersSubject: BehaviorSubject<RightHolder[]>;
    private rightHolderSubject: BehaviorSubject<RightHolder>;
    private userNameSubject: BehaviorSubject<string>;
    private showConceptPopup = true;
    private restored = false;

    public get allowedRightHolders$() {
        return this.allowedRightHoldersSubject.asObservable();
    }

    public get rightHolder(): RightHolder {
        return this.rightHolderSubject.getValue();
    }

    public get rightHolder$() {
        return this.rightHolderSubject.asObservable();
    }

    public get userName$() {
        return this.userNameSubject.asObservable();
    }

    public get isManagedRightHolder(): boolean {
        return this.defaultRightHolder && this.defaultRightHolder.id !== this.rightHolder.id;
        // return !this.isSBF && this.defaultRightHolder && (this.defaultRightHolder.id !== this.rightHolder.id || !this.defaultRightHolder.rightHolderIsAgent);
    }

    public get showConceptPopUp(): boolean {
        return this.showConceptPopup;
    }

    public set showConceptPopUp(value: boolean) {
        this.showConceptPopup = value;
    }

    private get userRoles(): string[] {
        return this.keycloakService.getUserRoles(true);
    }

    constructor(
        private userService: UserService,
        private keycloakService: KeycloakService,
        private permissionsService: NgxPermissionsService,
        private router: Router
    ) {
        this.allowedRightHoldersSubject = new BehaviorSubject<RightHolder[]>([]);
        this.rightHolderSubject = new BehaviorSubject<RightHolder>(null);
        this.userNameSubject = new BehaviorSubject<string>(null);

        // Watch keycloak events for rebuilding the session
        this.keycloakService.keycloakEvents$.subscribe(value => {
            if (this.keycloakService.isLoggedIn()) {
                // Set the username
                this.keycloakService.loadUserProfile(false).then(profile => {
                    this.userNameSubject.next(profile.username);
                });

                // Get the default rightHolder for the current user
                this.userService.defaultRightHolder().subscribe(rightHolder => {
                    this.defaultRightHolder = rightHolder;
                    if (this.rightHolderSubject.getValue() === null) {
                        this.rightHolderSubject.next(this.defaultRightHolder);
                    }
                });

                // Get the available rightHolders
                this.userService.allowedRightHolders().subscribe(rightHolders => {
                    this.allowedRightHoldersSubject.next(rightHolders);
                });

                // Set the roles
                this.loadRoles(this.userRoles);
            }
        });
    }

    public logout() {
        this.router.navigate(['/']).finally(() => {
            this.keycloakService.logout();
        });
    }

    /**
     * Is the given rightHolder available in the allowed rightHolders
     * @param rightHolder is this rightHolder allowed
     */
    public isRightHolderAllowed(rightHolder: RightHolder) {
        for (const rh of this.allowedRightHoldersSubject.getValue()) {
            if (rh.id === rightHolder.id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Is the given rightHolder the default rightHolder (owner)
     * @param rightHolder is this the default rightHolder
     */
    public isDefaultRightHolder(rightHolder: RightHolder) {
        if (this.defaultRightHolder) {
            return this.defaultRightHolder.id === rightHolder.id;
        } else {
            return false;
        }
    }

    /**
     * Does this user have the given role
     * @param neededRole does this role exists on the current user
     */
    public hasRole(neededRole: Roles): boolean {
        return this.userRoles.includes(neededRole);
    }

    /**
     * Does this user have the given roles
     * @param neededRoles do these roles exists on the current user
     */
    public hasRoles(neededRoles: Roles[]): boolean {
        neededRoles.forEach(role => {
            if (!this.userRoles.includes(role)) {
                return false;
            }
        });
        return true;
    }

    /**
     * Checks to see if logged in user has an sbf role
     */
    public get isSBF(): boolean {
        return this.userRoles.includes(Roles.SBF_ACCOUNTMANAGEMENT)
            || this.userRoles.includes(Roles.SBF_AUDITOR)
            || this.userRoles.includes(Roles.SBF_CLAIMMANAGEMENT)
            || this.userRoles.includes(Roles.SBF_DATAENTRY)
            || this.userRoles.includes(Roles.SBF_DATAMANAGEMENT)
            || this.userRoles.includes(Roles.SBF_GENERALMANAGEMENT);
    }

    /**
     * Checks to see if logged in user is an approved rightholder
     */
    public get isApprovedRightHolder(): boolean {
        return !this.isConceptRightHolder && (this.userRoles.includes(Roles.RIGHTHOLDER_CLAIMS)
            || this.userRoles.includes(Roles.RIGHTHOLDER_FINANCIAL)
            || this.userRoles.includes(Roles.RIGHTHOLDER_MANAGER));
    }

    /**
     * Checks to see if logged in user is a rightholder in concept fase
     */
    public get isConceptRightHolder(): boolean {
        return this.rightHolder?.status === RightHolderStatus.CONCEPT || this.rightHolder?.status === RightHolderStatus.CONCEPT_RESUBMIT;
    }

    /**
     * Switch the current rightHolder
     * @param rightHolder the rightHolder to switch to
     */
    public switchRightHolder(rightHolder: RightHolder, url?: string) {
        if (this.isSBF || this.isRightHolderAllowed(rightHolder)) {
            this.rightHolderSubject.next(rightHolder);
            localStorage.setItem('impersonatedRightHolder', JSON.stringify(rightHolder));
            this.router.navigate(['/switch', { url: url }]);
        } else {
            this.router.navigate(['/oops']);
        }
    }

    private switchRightHolderAgain() {
        let currentRightHolder = JSON.parse(localStorage.getItem('impersonatedRightHolder'));
        // localStorage.removeItem('impersonatedRightHolder');
        this.switchRightHolder(currentRightHolder, this.router.url);
    }

    private get shouldImpersonate(): boolean {
        if (localStorage.getItem("impersonatedRightHolder") === null) {
            return false;
        }
        try {
            let currentRightHolder = JSON.parse(localStorage.getItem('impersonatedRightHolder'));
            return !this.isDefaultRightHolder(currentRightHolder);
        }
        catch (e) {
            localStorage.removeItem('impersonatedRightHolder');
            return false;
        }
    }

    public restoreImpersonate() {
        if (this.isSBF && this.shouldImpersonate && !this.restored) {
            this.restored = true;
            this.switchRightHolderAgain();
        }

    }


    private loadRoles(roles: string[]) {
        this.permissionsService.flushPermissions();
        this.permissionsService.loadPermissions(roles);
    }
}
