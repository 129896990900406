import {Injectable} from '@angular/core';
import {CacheItem} from '../model/cache-item';

@Injectable({
    providedIn: 'root'
})
export class CacheService {
    private cache: Map<string, CacheItem>;

    constructor() {
        this.cache = new Map();
    }

    public get(key: string, defaultObject?: any): CacheItem {
        if (!this.cache.has(key)) {
            this.cache.set(key, {
                key, item: defaultObject
            });
        }

        return this.cache.get(key);
    }

    public delete(key: string) {
        if (this.cache.has(key)) {
            this.cache.delete(key);
        }
    }

    public has(key: string): boolean {
        return this.cache.has(key);
    }

    public clear() {
        this.cache.clear();
    }
}
