<mat-expansion-panel [expanded]="true" style="margin-top:1rem">
    <mat-expansion-panel-header>
        <mat-panel-title>Documenten</mat-panel-title>
    </mat-expansion-panel-header>

    <span *ngIf="loading" class="spinner">
        <mat-spinner diameter="80" mode="indeterminate"></mat-spinner>
    </span>

    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="lastModified">Datum</th>
            <td mat-cell *matCellDef=" let asset">
                {{asset.lastModified | date: 'short'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="rightholder">
            <th mat-header-cell *matHeaderCellDef>rightholder</th>
            <td mat-cell *matCellDef="let asset">
                {{asset.rightHolder.description}}
            </td>
        </ng-container>

        <ng-container matColumnDef="document">
            <th mat-header-cell *matHeaderCellDef>document</th>
            <td mat-cell *matCellDef="let asset">
                {{asset.asset.fileName}}
            </td>
        </ng-container>

        <ng-container matColumnDef="documentType">
            <th mat-header-cell *matHeaderCellDef>soort</th>
            <td mat-cell *matCellDef="let asset">
                {{htmlUtil.showDocumentType(asset.asset.documentType)}}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>status</th>
            <td mat-cell *matCellDef="let asset">
                {{htmlUtil.showAssetStatus(asset.status)}}
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Acties</th>
            <td mat-cell *matCellDef="let asset">
                <button mat-icon-button type="button" matTooltip="Document downloaden" (click)="downloadDocument(asset)">
                    <mat-icon aria-hidden="false" aria-label="downloaden">download</mat-icon>
                </button>
                <button mat-button mat-stroked-button *ngIf="canPause(asset)" (click)="pauseConflict(asset)" color="primary" class="mat-elevation-z1">
                    <mat-icon>pause_circle</mat-icon>&nbsp;Conflict pauzeren
                </button>
                <button mat-button mat-stroked-button *ngIf="canSolve(asset)" (click)="approve(asset)" color="primary" class="mat-elevation-z1">
                    <mat-icon>check_small</mat-icon>&nbsp;Conflict opgelost
                </button>
                <button *ngIf="canApprove(asset)" mat-button mat-stroked-button (click)="approve(asset)" color="primary" class="mat-elevation-z1">
                    Goedkeuren
                </button>
                <button *ngIf="canReject(asset)" mat-button mat-stroked-button (click)="reject(asset)" color="warn" class="mat-elevation-z1">
                    Afkeuren
                </button>

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [length]="dataSource.records" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
</mat-expansion-panel>