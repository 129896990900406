<div class="sbf-menu">
    <div>
        <ul class="sbf-navbar">
            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.RIGHTHOLDER_VIEW">
                <a routerLinkActive="active" [routerLink]="['/rightholders', currentRightHolder.id]">Mijn SBFonline</a>
            </li>

            <li class="sbf-navbar-item" *ngxPermissionsOnly="roles.WORK_VIEW">
                <a routerLinkActive="active" routerLink="/works">Database alle werken</a>
            </li>
        </ul>
    </div>


    <span class="spacer text-right mat-headline-6" *ngIf="isSBF">
        {{currentRightHolder.description}}
        <button mat-icon-button (click)="resetManagedRightHolder()" matTooltip="Reset rightholder">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </span>

    <span class="spacer text-right" *ngIf="!isSBF">
        <span *ngxPermissionsOnly="roles.RIGHTHOLDER_SWITCH">Selecteer:</span>
        <button [matMenuTriggerFor]="menuRightHolder" mat-button
            *ngxPermissionsOnly="roles.RIGHTHOLDER_SWITCH">{{currentRightHolder?.description}}</button>

        <mat-menu #menuRightHolder="matMenu" class="rightholder-select">
            <button mat-menu-item *ngFor="let rh of allowedRightHolders" [ngClass]="{'active': isRightHolderActive(rh)}"
                [matTooltip]="rh.description.length > 40 ? rh.description : undefined"
                (click)="switchRightHolder(rh)">{{rh.description}}</button>
        </mat-menu>
    </span>
</div>
