<div class="date-time-picker">
    <mat-form-field class="date-picker">
        <mat-label>Eindtijd</mat-label>
        <input matInput [matDatepicker]="datePicker" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker (closed)="emitDate()"></mat-datepicker>
    </mat-form-field>

    <div class="time-picker">
        <ngx-timepicker-field [format]="24" [controlOnly]="true" [defaultTime]="inputTime" (timeChanged)="emitDate()"></ngx-timepicker-field>
    </div>
</div>
