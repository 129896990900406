import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingShadeComponent } from './loading-shade/loading-shade.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { SharedModule } from '../shared.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { BroadcastSearchResultsComponent } from './broadcast-search-results/broadcast-search-results.component';
import { RouterModule } from '@angular/router';
import { WorkSearchResultsComponent } from './work-list-search-results/work-search-results.component';
import { WorkListFilterComponent } from './work-list-filter/work-list-filter.component';
import { SpecificationLinesComponent } from './specification-lines-table/specification-lines.component';
import { SpecificationComponent } from './credit-nota/specification.component';
import { NgxPermissionsRestrictStubModule } from 'ngx-permissions';
import { FinancialDataComponent } from './financial-data/financial-data.component';
import { ConflictBroadcastComponent } from './conflict-broadcast/conflict-broadcast.component';
import { ConflictClaimSearchResultsComponent } from './conflict-claim-search-results/conflict-claim-search-results.component';
import { ConflictStatusBadgeComponent } from './conflict-status-badge/conflict-status-badge.component';

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        SharedModule,
        NgxMaterialTimepickerModule.setOpts('nl-NL'),
        RouterModule,
        NgxPermissionsRestrictStubModule,
        LoadingShadeComponent,
        DateTimePickerComponent,
        BroadcastSearchResultsComponent,
        WorkSearchResultsComponent,
        WorkListFilterComponent,
        SpecificationLinesComponent,
        SpecificationComponent,
        FinancialDataComponent,
        ConflictBroadcastComponent,
        ConflictClaimSearchResultsComponent,
        ConflictStatusBadgeComponent
    ],
    exports: [
        LoadingShadeComponent,
        DateTimePickerComponent,
        BroadcastSearchResultsComponent,
        WorkSearchResultsComponent,
        WorkListFilterComponent,
        SpecificationLinesComponent,
        SpecificationComponent,
        FinancialDataComponent,
        ConflictClaimSearchResultsComponent,
        ConflictBroadcastComponent,
        ConflictStatusBadgeComponent
    ]
})
export class SharedComponentsModule {
}
