import { CurrencyPipe, DatePipe, NgClass } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { FairPeriodStatus } from 'src/app/shared/enums/fair-period-status.enum';
import { FairPeriod } from 'src/app/shared/model/fair-period';
import { FairService } from 'src/app/shared/service/fair.service';
import { FairPeriodStatusBadgeComponent } from 'src/app/shared/component/fair-period-status-badge/fair-period-status-badge.component';

@Component({
  selector: 'app-period',
  standalone: true,
  imports: [MatCardModule, MatTabsModule, MatChipsModule, DatePipe, CurrencyPipe, NgClass, MatDividerModule, MatButtonModule,
    MatTooltipModule, MatIconModule, MatProgressBarModule, RouterLink, FairPeriodStatusBadgeComponent],
  templateUrl: './period.component.html',
  styleUrl: './period.component.scss'
})
export class PeriodComponent implements OnInit {

  @Input() period: FairPeriod;
  creating: boolean = false;
  selTabIndex: number;

  displayedColumns = [
    'dates',
    'rule',
    'minpoints',
    'minkdh',
    'amount',
    'updated',
    'report'
  ];

  constructor(private fairService: FairService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.selTabIndex = this.getInitialTabIndex();
  }

  getInitialTabIndex(): number {
    switch (this.period.status) {
      case FairPeriodStatus.NA:
      case FairPeriodStatus.CREATED:
      case FairPeriodStatus.CREATION_FAILED:
      case FairPeriodStatus.APPROVED:
      case FairPeriodStatus.REJECTED:
      case FairPeriodStatus.RECREATED:
      case FairPeriodStatus.DELETED:
      case FairPeriodStatus.DONE:
        return 0;
      case FairPeriodStatus.CLAIMABLE:
        return 1;
      case FairPeriodStatus.IN_REPARTITION:
        return 2;
      default:
        return 0; // Default case added to handle all possible enum values
    }
  }


  get canDownload(): boolean {
    return ![FairPeriodStatus.CREATION_FAILED, FairPeriodStatus.DELETED].includes(this.period.status);
  }

  get canApprove(): boolean {
    return this.selTabIndex === 0 && [FairPeriodStatus.CREATED, FairPeriodStatus.RECREATED].includes(this.period.status);
  }

  get canDelete(): boolean {
    return this.selTabIndex === 0 && [
      FairPeriodStatus.CREATED,
      FairPeriodStatus.APPROVED,
      FairPeriodStatus.RECREATED,
      FairPeriodStatus.CREATION_FAILED,
      FairPeriodStatus.REJECTED
    ].includes(this.period.status);
  }

  get canReCreate(): boolean {
    return this.selTabIndex === 0 && [
      FairPeriodStatus.CREATED,
      FairPeriodStatus.RECREATED,
      FairPeriodStatus.REJECTED
    ].includes(this.period.status);
  }

  get canCreateWorks(): boolean {
    return this.selTabIndex === 0 && this.period.status === FairPeriodStatus.APPROVED;
  }

  get canCreateImportFile(): boolean {
    return this.selTabIndex === 1 && this.period.status === FairPeriodStatus.CLAIMABLE;
  }

  downloadItemReport() {
    this.fairService.getItemReport(this.period);
  }

  downloadClaimReport() {
    this.fairService.getClaimReport(this.period);
  }

  approve() {
    this.fairService.update({...this.period, status: FairPeriodStatus.APPROVED}).subscribe(value => this.period = value);
  }

  reject() {
    this.fairService.reject(this.period).subscribe(value => this.period = value);
  }

  delete() {
    alert("nog niet geïmplementeerd");
  }

  recreate() {
    this.creating = true;
    this.fairService.reCreateRepertoire(this.period).subscribe({
      next: (value) => {
        this.period = value;
        this.creating = false;
      },
      error: () => {
        this.creating = false;
        // Handle error
      }
    });
  }

  createWorks() {
    this.creating = true;
    this.fairService.createWorksFor(this.period).subscribe({
      next: (value) => {
        this.period = value;
        this.creating = false;
      },
      error: () => {
        this.creating = false;
        // Handle error
      }
    });
  }

  createImportFile() {
    this.creating = true;
    this.fairService.createImportFileFor(this.period)
      .then(() => {
        // Handle success
      })
      .catch(() => {
        // Handle error
      })
      .finally(() => {
        this.creating = false;
      });
  }

  claimsDisabled(): boolean {
    return ![FairPeriodStatus.CLAIMABLE, FairPeriodStatus.IN_REPARTITION, FairPeriodStatus.DONE].includes(this.period.status);
  }

  repartitionDisabled(): boolean {
    return ![FairPeriodStatus.IN_REPARTITION, FairPeriodStatus.DONE].includes(this.period.status);
  }
}
