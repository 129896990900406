<ng-container *ngIf="!isAlreadySolved(); else solved">
    <ng-container *ngIf="!isPhase2() ;else phase2">
        <div *ngIf="decisionMade(); else decisionNotMade">
            <mat-card appearance="raised">
                <mat-card-header>
                    <mat-card-title>Bedankt voor uw keuze</mat-card-title>
                    <mat-card-subtitle>
                        <div class="info">
                            <mat-icon class="blue">info</mat-icon>
                            &nbsp;U heeft ervoor gekozen om {{decision()}}
                        </div>
                    </mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>

        <ng-template #decisionNotMade>
            <div *ngIf="isAlreadySolved(); else makeDecision">
                <mat-card appearance="raised">
                    <mat-card-header>
                        <mat-card-title>Conflict reeds opgelost</mat-card-title>
                        <mat-card-subtitle>
                            <div class="info">
                                <mat-icon class="blue">info</mat-icon>
                                &nbsp;U hoeft geen actie te ondernemen
                            </div>
                        </mat-card-subtitle>
                    </mat-card-header>
                </mat-card>
            </div>
            <ng-template #makeDecision>
                <mat-card appearance="raised">
                    <mat-card-header>
                        <mat-card-title>Beslis over bovenstaande claim namens <em class="accent">{{rightHolder.description}}</em> vóór
                            {{conflict.deadline |
                                date:
                                'shortDate'}} ({{daysBeforeDeadline(conflict)}})
                        </mat-card-title>
                        <mat-card-subtitle>
                            <div class="info">
                                <mat-icon class="blue">info</mat-icon>
                                &nbsp;Een aanpassing heeft alleen betrekking op repartitie&nbsp;
                                <em>{{conflict.repartition.description}}</em>
                                &nbsp;(periode:
                                {{conflict.repartition.periodFrom | date: 'shortDate'}}
                                tot {{conflict.repartition.periodUntil | date: 'shortDate'}})
                            </div>
                        </mat-card-subtitle>
                    </mat-card-header>

                    <ng-container *ngTemplateOutlet="claimTiles">
                        This text is not displayed
                    </ng-container>

                </mat-card>
            </ng-template>
        </ng-template>
    </ng-container>
</ng-container>

<ng-template #phase2>
    <mat-card appearance="raised">
        <mat-card-title>
            <div class="info">
                <mat-icon class="warn">info</mat-icon>
                Fase 2 van het conflict. Lever bestanden aan voor verdere afhandeling of wijzig uw keuze voor {{conflict.deadline |
                    date:
                    'longDate'}} ({{daysBeforeDeadline(conflict)}})
            </div>
        </mat-card-title>
        <ng-container *ngTemplateOutlet="claimTiles">
            This text is not displayed
        </ng-container>
    </mat-card>
</ng-template>
<ng-template #solved>
    Het conflict is opgelost.

</ng-template>

<ng-template #claimTiles>
    <mat-grid-list cols="3" rowHeight="1.4:1" gutterSize="8px">

        <mat-grid-tile *ngIf="hasChooseSolution" class="mat-elevation-z1">
            <mat-grid-tile-header>Kies</mat-grid-tile-header>
            <mat-card-content>
                <form [formGroup]="chooseForm">
                    <mat-radio-group class="vertical" formControlName="solution" (change)="chooseSolution($event.value)" required>
                        <mat-radio-button [disabled]="adjustDisabled" value="UPDATE">Claim {{adjustDisabled?"aangepast":"aanpassen"}} (keuze a)
                        </mat-radio-button>

                        <!-- <mat-radio-button [disabled]="adjustDisabled" value="{{adjustDisabled? 'UPDATED':'UPDATE'}}">Claim {{adjustDisabled?"aangepast":"aanpassen"}} (keuze a)
                        </mat-radio-button> -->
                        <!-- <mat-radio-button *ngIf="adjustDisabled" value="UPDATE">Claim nogmaals aanpassen</mat-radio-button> -->

                        <mat-radio-button value="RETRACT" style="margin-top: 4px">Claim intrekken (keuze b)</mat-radio-button>
                        <mat-radio-button [disabled]="maintainDisabled" value="MAINTAIN_MEDIATION_SBF">Bemiddeling door SBF (keuze c)
                            <mat-icon matTooltip="Klik voor uitleg" matTooltipPosition="above" (click)="help('conflict.help.mediation_sbf')">help</mat-icon>
                        </mat-radio-button>
                        <mat-radio-button [disabled]="maintainDisabled" value="MAINTAIN_MEDIATION_ADVISOR">Bemiddeling door bindend adviseur (keuze d)
                            <mat-icon matTooltip="Klik voor uitleg" matTooltipPosition="right" (click)="help('conflict.help.mediation_advisor')">help</mat-icon>
                        </mat-radio-button>
                        <mat-radio-button [disabled]="maintainDisabled" value="MAINTAIN_LAWSUIT">Voorlegging van geschil aan rechter (keuze e)
                            <mat-icon matTooltip="Klik voor uitleg" (click)="help('conflict.help.lawsuit')">help</mat-icon>
                        </mat-radio-button>
                    </mat-radio-group>
                </form>
            </mat-card-content>
            <mat-grid-tile-footer>
                <button mat-raised-button [disabled]="!hasText" (click)="maintain()" color="primary">Versturen
                </button>
            </mat-grid-tile-footer>
        </mat-grid-tile>

        <mat-grid-tile *ngIf="hasClaimAdjust" class="mat-elevation-z1">
            <mat-grid-tile-header>Claim aanpassen</mat-grid-tile-header>
            <mat-card-content>
                <ul>
                    <li>{{decisionMade() ? "Voorstel" : "Huidig" }} claimpercentage: {{decisionMade() ?  rhConflictClaim.newPercentage :rhConflictClaim.mainClaim.percentage}}%</li>
                    <li>
                        <mat-form-field appearance="fill">
                            <mat-label>Nieuw percentage</mat-label>
                            <input matInput appOnlyNumber="decimal" [(ngModel)]="newPercentage">
                            <span matSuffix>%</span>
                        </mat-form-field>
                    </li>
                </ul>
            </mat-card-content>
            <mat-grid-tile-footer>
                <button mat-raised-button color="primary" [disabled]="!canAdjust()" (click)="setNewPercentage()">Aanpassen
                </button>
            </mat-grid-tile-footer>
        </mat-grid-tile>


        <mat-grid-tile *ngIf="hasClaimWithdraw" class="mat-elevation-z1">
            <mat-grid-tile-header>Claim intrekken</mat-grid-tile-header>
            <mat-card-content>
                <ul>
                    <li>Uw claimpercentage wordt op 0% gezet</li>
                </ul>
            </mat-card-content>
            <mat-grid-tile-footer>
                <button mat-raised-button color="red" (click)="retract()">Intrekken</button>
            </mat-grid-tile-footer>
        </mat-grid-tile>

        <mat-grid-tile *ngIf="hasUpload" class="mat-elevation-z1">
            <mat-grid-tile-header>Upload</mat-grid-tile-header>
            <mat-card-content>
                <form class="form-upload-file" [formGroup]="uploadForm">
                    <mat-form-field>
                        <mat-select formControlName="documentType">
                            <mat-option [value]="documentTypes.CONFLICT_DOCUMENT">Conflict document</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <input hidden type="file" accept=".png,.jpg,.pdf" (change)="uploadDocument()" #fileUpload [disabled]="this.uploadForm.invalid">
                    <button mat-flat-button color="primary" type="button" (click)="fileUpload.click()" [disabled]="!documentType">Upload bestand
                    </button>
                </form>
                <div appDragDrop (fileDropped)="droppedFile($event)" *ngIf="this.uploadForm.valid">
                    <p>Sleep hier uw bestanden heen</p>
                </div>
                <div *ngIf="hasUploadStatus" id="uploadStatus">
                    <b>Gelukt. U kunt het bestand en de status terugzien onder uw Documenten</b>
                </div>
                <mat-card-subtitle *ngIf="!hasUploadStatus" style="margin-top: 0.5rem">Toegestane bestandstypen: .pdf, .jpg, .jpeg en .png</mat-card-subtitle>

            </mat-card-content>
            <mat-grid-tile-footer>

            </mat-grid-tile-footer>
        </mat-grid-tile>


        <mat-grid-tile *ngIf="hasLog" colspan="1" class="rhlog mat-elevation-z1">
            <mat-grid-tile-header>Status</mat-grid-tile-header>

            <table class="logs" mat-table [dataSource]="logs">
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Datum</th>
                    <td mat-cell *matCellDef=" let log">
                        {{log.lastModified | date: 'short'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actiondesc">
                    <th mat-header-cell *matHeaderCellDef>Actie</th>
                    <td mat-cell *matCellDef="let log">
                        {{log.action.description}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="by">
                    <th mat-header-cell *matHeaderCellDef>Door</th>
                    <td mat-cell *matCellDef="let log">
                        {{(log.rightHolder == null)? 'SBF':'U'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="endstate">
                    <th mat-header-cell *matHeaderCellDef>Status</th>
                    <td mat-cell *matCellDef="let log">
                        {{log.action.endStatus.description}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

        </mat-grid-tile>

    </mat-grid-list>
</ng-template>