import { RepartitionConflict } from '../model/repartition-conflict';
import { ConflictStatus } from '../enums/conflict-status.enum';
import * as moment from 'moment';

export class ConflictUtil {
    public static daysBeforeDeadline(conflict: RepartitionConflict): string {
        const deadline = conflict.deadline;
        if (!deadline) { return ''; }

        if (this.isInactive(conflict)) {
            return '';
        }

        const now = moment();
        const end = moment(deadline);
        const diff = end.diff(now, "days") + 1;
        if (diff == 1) { return 'Vandaag!'; }
        if (diff <= 0) { return 'Verlopen!'; }
        return `Nog ${diff} dagen`;
    }

    public static hasStarted(conflict: RepartitionConflict): boolean {
        return conflict.conflictStatus.conflictStatus == ConflictStatus.PHASE_ONE ||
            this.isPhaseTwo(conflict) || this.isPending(conflict);
    }

    public static isInactive(conflict: RepartitionConflict): boolean {
        switch (conflict.conflictStatus.conflictStatus) {
            case ConflictStatus.SOLVED:
            case ConflictStatus.FINAL_SOLVED:
            case ConflictStatus.EXPIRED_UNRESOLVED_PHASE1:
                return true;
        }
        return false;
    }

    public static isPhaseTwo(conflict: RepartitionConflict): boolean {
        switch (conflict.conflictStatus.conflictStatus) {
            case ConflictStatus.PHASE_TWO_SBF:
            case ConflictStatus.PHASE_TWO_ADVISOR:
            case ConflictStatus.PHASE_TWO_LAWSUIT:
                return true;
        }
        return false;
    }

    public static isPending(conflict: RepartitionConflict): boolean {
        switch (conflict.conflictStatus.conflictStatus) {
            case ConflictStatus.PENDING_ADVISOR:
            case ConflictStatus.PENDING_LAWSUIT:
                return true;
        }
        return false;
    }

    public static isPhaseTwoOrPending(conflict: RepartitionConflict): boolean {
        return this.isPhaseTwo(conflict) || this.isPending(conflict);
    }
}

