import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkService } from '../../../work/service/work.service';
import { Pagination } from '../../model/pagination';
import { WorkUtil } from '../../util/work-util';
import { CacheService } from '../../service/cache.service';
import { ListAction } from './list-action';
import { ListActionEvent } from './list-action-event';
import { Work } from '../../model/work';
import { BaseSearchComponent } from '../../base/base-search-component';
import { SearchFieldGroup } from '../../base/search/search-field-group';
import { UntypedFormGroup } from '@angular/forms';
import { SearchOperator } from '../../base/search/search-operator.enum';
import { SearchField } from '../../base/search/search-field';
import { WorkStatus } from '../../enums/work-status.enum';
import { RestSearchUtil } from '../../util/rest-search-util';
import { WorkTypeEnum } from '../../enums/work-type.enum';
import { Router } from "@angular/router";
import { SessionService } from "../../service/session.service";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { LoadingShadeComponent } from '../loading-shade/loading-shade.component';

@Component({
    selector: 'app-work-search-results',
    templateUrl: 'work-search-results.component.html',
    styleUrls: ['work-search-results.component.scss'],
    standalone: true,
    imports: [LoadingShadeComponent, MatTableModule, MatSortModule, TranslateModule, NgFor,
        MatButtonModule, MatPaginatorModule, UpperCasePipe]
})
export class WorkSearchResultsComponent extends BaseSearchComponent<Work> implements OnInit, AfterViewInit {
    @Input() declare filterForm: UntypedFormGroup;
    @Input() displayedColumns: string[];
    @Input() pageSizeOptions: number[] = [10, 20, 50];
    @Input() actions: ListAction[];
    @Input() importFileList = false;
    @Input() rightHolder: number;

    @Output() action: EventEmitter<ListActionEvent> = new EventEmitter<ListActionEvent>();
    @Output() rowClick: EventEmitter<Work> = new EventEmitter<Work>();

    constructor(
        cacheService: CacheService,
        private workService: WorkService,
        private sessionService: SessionService,
        private router: Router
    ) {
        super(workService, cacheService, 'work-list');
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    rightHolderProspectsSearch(rightHolder: number) {
        if (rightHolder != null) {
            this.workService.findByRightHolderWithProspects(rightHolder).subscribe(value => {
                this.dataSource.data = value;
            });
            this.actions = [];
            this.actions.push({ name: 'delete', label: 'Verwijderen' });
            if (!this.displayedColumns.includes('actions')) {
                this.displayedColumns.push('actions');
            }
            return;
        }
    }

    prepareRestSearch(values: any): SearchFieldGroup {
        const group: SearchFieldGroup = {
            type: 'AND',
            fields: [],
            groups: []
        };

        if (values.id) {
            group.fields.push({
                fieldName: 'id',
                operator: SearchOperator.EQ,
                value: values.id
            } as SearchField);
        }

        if (values.workTitle) {
            const searchOperator = (values.titleExact) ? SearchOperator.EQ : SearchOperator.CONTAINS;
            const field = (values.altTitle) ? 'workTitles.title' : 'originalTitle.title';

            group.fields.push({
                fieldName: field,
                operator: searchOperator,
                value: values.workTitle
            } as SearchField);
        }

        if (values.workSubTitle) {
            const searchOperator = (values.subtitleExact) ? SearchOperator.EQ : SearchOperator.CONTAINS;
            group.fields.push({
                fieldName: 'originalTitle.subTitle',
                operator: searchOperator,
                value: values.workSubTitle
            } as SearchField);
        }

        if (values.isan) {
            group.fields.push({
                fieldName: 'isan',
                operator: SearchOperator.EQ,
                value: values.isan
            } as SearchField);
        }

        if (values.genre) {
            group.fields.push({
                fieldName: 'genre.id',
                operator: SearchOperator.EQ,
                value: values.genre
            } as SearchField);
        }

        if (values.category) {
            group.fields.push({
                fieldName: 'category.id',
                operator: SearchOperator.EQ,
                value: values.category
            } as SearchField);
        }

        if (values.workType) {
            group.fields.push({
                fieldName: 'workType.id',
                operator: SearchOperator.EQ,
                value: values.workType
            } as SearchField);
        }

        if (values.preventDVD) {
            group.fields.push({
                fieldName: 'workType.id',
                operator: SearchOperator.IN,
                value: [WorkTypeEnum.KORTE_FILM, WorkTypeEnum.TELEFILM, WorkTypeEnum.SPEELFILM, WorkTypeEnum.SERIE, WorkTypeEnum.VIDEOCLIP]
            } as SearchField);
        }

        if (values.workStatus) {
            if (values.workStatus !== WorkStatus.NA) {
                group.fields.push({
                    fieldName: 'status',
                    operator: SearchOperator.EQ,
                    value: values.workStatus
                } as SearchField);
            }
        }

        if (values.level) {
            if (values.level !== 0) {
                group.fields.push({
                    fieldName: 'level',
                    operator: SearchOperator.EQ,
                    value: values.level
                } as SearchField);
            }
        }

        if (this.importFileList) {
            group.fields.push({
                fieldName: 'status',
                operator: SearchOperator.IN,
                value: [WorkStatus.APPROVED, WorkStatus.MODIFIED]
            } as SearchField);
        }

        return group;
    }

    defaultListForm(): UntypedFormGroup {
        return this.filterForm;
    }

    defaultPagination(): Pagination {
        return new Pagination(0, this.pageSizeOptions[0], 'id', 'asc');
    }

    downloadReport() {
        this.workService.downloadTableExport(RestSearchUtil.createMatSearchRequest(this.defaultPagination(), this.prepareRestSearch({ ...this.filterForm.getRawValue() })));
    }

    onAction(work: Work, action: ListAction) {
        this.action.emit({
            action,
            data: work
        });
    }

    get workUtil() {
        return WorkUtil;
    }

    onRowClick(row: any) {
        if (this.sessionService.isSBF) {
            let newRelativeUrl = this.router.createUrlTree(['/works/' + row.id])
            let baseUrl = window.location.href.replace(this.router.url, '');
            window.open(baseUrl + newRelativeUrl, '_blank');
        } else {
            this.rowClick.emit(row);
        }
    }
}
