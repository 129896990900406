<mat-expansion-panel [expanded]="true" style="margin-top:1rem">
    <mat-expansion-panel-header>
        <mat-panel-title>Log</mat-panel-title>
    </mat-expansion-panel-header>

    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="lastModified">Datum</th>
            <td mat-cell *matCellDef="let log">
                {{log.lastModified | date: 'medium'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="actiondesc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="action.description">Actie</th>
            <td mat-cell *matCellDef="let log">
                {{log.action.description}}
            </td>
        </ng-container>

        <ng-container matColumnDef="party">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="action.conflictParty">Partij</th>
            <td mat-cell *matCellDef="let log">
                {{partyLabels.get(log.action.conflictParty)}}
            </td>
        </ng-container>

        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="user.username">Gebruiker</th>
            <td mat-cell *matCellDef="let log">
                {{log.user?.username}}
            </td>
        </ng-container>

        <ng-container matColumnDef="forRh">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="rightHolder.description">Namens</th>
            <td mat-cell *matCellDef="let log">
                {{log.rightHolder?.description}}
            </td>
        </ng-container>

        <ng-container matColumnDef="endstate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="action.endStatus.description">Eindstatus</th>
            <td mat-cell *matCellDef="let log">
                {{log.action.endStatus.description}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="button-container">
        <button mat-stroked-button color="primary" (click)="downloadLog()" class="container-button">Download
        </button>
        <mat-paginator [length]="dataSource.records" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
        </mat-paginator>
    </div>

</mat-expansion-panel>