import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Repartition } from '../../model/repartition';
import { Specification } from '../../model/specification';
import { SpecificationLineDatasource } from '../../../specification/datasource/specification-line.datasource';
import { merge, Subscription } from 'rxjs';
import { WorkTitle } from '../../model/work-title';
import { Claim } from '../../model/claim';
import { ClaimForRepartition } from '../../model/claim-for-repartition';
import { filter, first, tap } from 'rxjs/operators';
import { SpecificationService } from '../../../specification/service/specification.service';
import { SpecificationLine } from '../../model/specification-line';
import { ClaimForRepartitionService } from '../../service/claim-for-repartition.service';
import { WorkTitleService } from '../../../work/service/work-title.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { UntypedFormBuilder } from '@angular/forms';
import { RepartitionService } from '../../../repartition/service/repartition.service';
import { ActivatedRoute } from '@angular/router';
import { Location, UpperCasePipe, CurrencyPipe, DatePipe } from '@angular/common';
import { Pagination } from '../../model/pagination';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { LoadingShadeComponent } from '../loading-shade/loading-shade.component';

@Component({
    selector: 'app-specification-lines',
    templateUrl: './specification-lines.component.html',
    styleUrls: ['./specification-lines.component.scss'],
    standalone: true,
    imports: [LoadingShadeComponent, MatTableModule, TranslateModule, MatPaginatorModule, UpperCasePipe, CurrencyPipe, DatePipe]
})
export class SpecificationLinesComponent implements OnInit, OnDestroy {
    workTitleMap: Map<number, WorkTitle>;
    claimForRepartitionMap: Map<number, ClaimForRepartition>;
    loading = true;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    @Input() repartition: Repartition;
    @Input() specification: Specification;
    lineDatasource: SpecificationLineDatasource;
    lineDataSubscription: Subscription;

    showLinesColumns = [
        'title',
        'subTitle',
        'country',
        'channel',
        'timeStart',
        'duration',
        'corporation',
        'rightType',
        'percentage',
        'showAmount'
    ];

    amountMinusCosts = 0;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private repartitionService: RepartitionService,
        private route: ActivatedRoute,
        private specificationService: SpecificationService,
        private claimForRepartitionService: ClaimForRepartitionService,
        private workTitleService: WorkTitleService,
        private location: Location
    ) {
        this.claimForRepartitionMap = new Map<number, ClaimForRepartition>();
        this.workTitleMap = new Map<number, WorkTitle>();
        this.lineDatasource = new SpecificationLineDatasource(specificationService);

        this.lineDatasource.loading.pipe(
            filter(value => !value),
            first()
        ).subscribe(value => {
            this.loading = value;
            this.amountMinusCosts = this.calculateAmountMinusCosts();
        });

        this.lineDataSubscription = this.lineDatasource.data$.subscribe(lines => {
            this.loadWorkTitles(lines);
            this.loadClaimForRepetition(lines);
        });

    }

    ngOnDestroy(): void {
        this.lineDataSubscription.unsubscribe();
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        merge(this.paginator.page).pipe(
            tap(() => this.loadData())
        ).subscribe();

        this.loadData();
    }

    private loadData() {
        const pagination = new Pagination(this.paginator.pageIndex + 1, this.paginator.pageSize, undefined, undefined);
        this.lineDatasource.load(this.specification.id, pagination);
    }

    private loadClaimForRepetition(lines: SpecificationLine[]) {
        const cfrIds = new Set(lines.map(value => value.claimForRepartitionId)
            .filter((value, index, array) => !this.claimForRepartitionMap.has(value)));

        if (cfrIds && cfrIds.size > 0) {
            this.claimForRepartitionService.findByIds(cfrIds).subscribe(claimForRepartitions => {
                claimForRepartitions.forEach(claimForRepartition => {
                    this.claimForRepartitionMap.set(claimForRepartition.id, claimForRepartition);
                });
            });
        }
    }

    private loadWorkTitles(lines: SpecificationLine[]) {
        const workIds = new Set(lines.map(value => value.workId)
            .filter((value, index, array) => !this.workTitleMap.has(value)));
        if (workIds && workIds.size > 0) {
            this.workTitleService.findByWorks(workIds).subscribe(workTitles => {
                workTitles.forEach(workTitle => {
                    this.workTitleMap.set(workTitle.workId, workTitle);
                });
            });
        }
    }

    calculateAmountMinusCosts(): number {
        let total = 0;
        if (this.lineDatasource.allDataPaged) {
            this.lineDatasource.allDataPaged.forEach(pages => {
                pages.forEach(specificationLine => {
                    total += specificationLine.amount;
                });
            });
        }
        return total;
    }


    goBack() {
        this.location.back();
    }

}
