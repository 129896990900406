import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RepartitionConflictActionLog } from '../model/repartition-conflict-action-log';
import { BaseService } from '../base/base-service';
import {RightHolder} from "../model/rightholder";
import {RepartitionConflict} from "../model/repartition-conflict";
import {Observable} from "rxjs";
import {RepartitionConflictClaim} from "../model/repartition-conflict-claim";
import {Asset} from "../model/asset";

@Injectable({
    providedIn: 'root'
})
export class RepartitionConflictActionLogService extends BaseService<RepartitionConflictActionLog> {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'actionlogs');
    }

    create(): RepartitionConflictActionLog {
        return null;
    }

    getFor(rightHolder: RightHolder, conflict: RepartitionConflict): Observable<RepartitionConflictActionLog[]> {
        return this.httpClient.get<RepartitionConflictActionLog[]>(`${this.endpoint}/rh/${rightHolder.id}/conflict/${conflict.id}`);
    }


    approve(log: RepartitionConflictActionLog) {
        return this.httpClient.post<RepartitionConflictActionLog>(`${this.endpoint}/approve`,log);
    }

    disapprove(log: RepartitionConflictActionLog) {
        return this.httpClient.post<RepartitionConflictActionLog>(`${this.endpoint}/disapprove`,log);
    }


}
