import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Specification} from '../../shared/model/specification';
import {Repartition} from '../../shared/model/repartition';
import {SpecificationLine} from '../../shared/model/specification-line';
import {BaseService} from '../../shared/base/base-service';
import {AcceptSpecification} from '../model/accept-specification';

@Injectable({
    providedIn: 'root'
})
export class SpecificationService extends BaseService<Specification> {
    constructor(httpClient: HttpClient) {
        super(httpClient, 'specifications');
    }

    create(): Specification {
        return {};
    }

    public findByRepartition(repartition: Repartition): Observable<Specification[]> {
        return this.httpClient.get<Specification[]>(`${this.endpoint}/repartition/${repartition.id}`);
    }

    public findByRightHolder(rightholder: number): Observable<Specification[]> {
        return this.httpClient.get<Specification[]>(`${this.endpoint}/rightholder/${rightholder}`);
    }

    public getSpecificationLines(id: number): Observable<SpecificationLine[]> {
        return this.httpClient.get<SpecificationLine[]>(`${this.endpoint}/${id}/specificationlines`);
    }

    public approve(specification: number, repartition: number): Observable<boolean> {
        const httpParams = new HttpParams().append('specification', specification.toString()).append('repartition', repartition.toString());
        return this.httpClient.get<boolean>(`${this.endpoint}/approve`, {
            params: httpParams
        });
    }

    public accept(specification: number): Observable<AcceptSpecification> {
        return this.httpClient.get<AcceptSpecification>(`${this.endpoint}/${specification}/accept`);
    }

    public resendEmail(specification: number): Observable<number> {
        return this.httpClient.get<number>(`${this.endpoint}/${specification}/resend`);
    }
}
