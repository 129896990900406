import { Component, OnInit, Input } from '@angular/core';
import { RepartitionConflict } from '../../model/repartition-conflict';
import { ConflictStatus } from '../../enums/conflict-status.enum';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-conflict-status-badge',
    templateUrl: './conflict-status-badge.component.html',
    styleUrls: ['./conflict-status-badge.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class ConflictStatusBadgeComponent implements OnInit {

    @Input() conflict: RepartitionConflict;

    constructor() { }

    ngOnInit(): void {
    }

    style(): string {
        switch (this.conflict.conflictStatus.conflictStatus) {
            case ConflictStatus.DETECTED: return 'detected';
            case ConflictStatus.PHASE_ONE: return 'phase-one';
            case ConflictStatus.PHASE_TWO_SBF:
            case ConflictStatus.PHASE_TWO_ADVISOR:
            case ConflictStatus.PHASE_TWO_LAWSUIT:
                return 'phase-two';
            case ConflictStatus.SOLVED: return 'solved';
            case ConflictStatus.IGNORED: return 'ignored';
            case ConflictStatus.UNDER_SBF: return 'under-sbf';
            case ConflictStatus.EXPIRED_UNRESOLVED_PHASE1:
            case ConflictStatus.EXPIRED_UNRESOLVED_PHASE2:
                return 'expired-unsolved';
            case ConflictStatus.FINAL_SOLVED:
            case ConflictStatus.SOLVED_BY_SBF:
                return 'success';
            case ConflictStatus.PENDING_ADVISOR:
            case ConflictStatus.PENDING_LAWSUIT:
                return 'pending';
        }
        return '';
    }
}
