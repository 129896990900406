import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Broadcast } from '../../model/broadcast';
import { UntypedFormGroup } from '@angular/forms';
import { BroadcastService } from '../../service/broadcast.service';
import { Pagination } from '../../model/pagination';
import { BroadcastStatus } from '../../enums/broadcast-status.enum';
import { BaseSearchComponent } from '../../base/base-search-component';
import { CacheService } from '../../service/cache.service';
import { SearchFieldGroup } from '../../base/search/search-field-group';
import { SearchOperator } from '../../base/search/search-operator.enum';
import { SearchField } from '../../base/search/search-field';
import { Work } from '../../model/work';
import { RestSearchUtil } from '../../util/rest-search-util';
import { UpperCasePipe, DatePipe } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { LoadingShadeComponent } from '../loading-shade/loading-shade.component';

@Component({
    selector: 'app-broadcast-search-results',
    templateUrl: './broadcast-search-results.component.html',
    styleUrls: ['./broadcast-search-results.component.scss'],
    standalone: true,
    imports: [LoadingShadeComponent, MatTableModule, MatSortModule, TranslateModule, MatIconModule,
        MatButtonModule, RouterLink, MatPaginatorModule, UpperCasePipe, DatePipe]
})
export class BroadcastSearchResultsComponent extends BaseSearchComponent<Broadcast> implements OnInit, AfterViewInit {
    @Input() work: Work;
    @Input() displayedColumns: string[];
    @Input() declare filterForm: UntypedFormGroup;
    @Input() pageSizeOptions: number[] = [10, 20, 50];

    broadcastStatusValid: BroadcastStatus[] = [BroadcastStatus.CONCEPT, BroadcastStatus.APPROVED];

    constructor(
        cacheService: CacheService,
        private broadcastService: BroadcastService
    ) {
        super(broadcastService, cacheService, 'broadcast-list');
    }

    ngOnInit() {
        super.ngOnInit();
    }

    prepareRestSearch(values: any): SearchFieldGroup {
        const group: SearchFieldGroup = {
            type: 'AND',
            fields: [],
            groups: []
        };

        const titleSearchOp = values.titleExact === true ? SearchOperator.EQ : SearchOperator.CONTAINS;
        const subTitleSearchOp = values.subTitleExact === true ? SearchOperator.EQ : SearchOperator.CONTAINS;
        const workId = this.work ? this.work.id : values.workId;

        if (values.title) {
            group.fields.push({
                fieldName: 'title',
                operator: titleSearchOp,
                value: values.title,
                caseInsensitive: true
            } as SearchField);
        }
        if (values.subTitle) {
            group.fields.push({
                fieldName: 'subTitle',
                operator: subTitleSearchOp,
                value: values.subTitle,
                caseInsensitive: true
            } as SearchField);
        }
        if (values.timeStart) {
            group.fields.push({
                fieldName: 'timeStart',
                operator: SearchOperator.GE,
                value: values.timeStart
            } as SearchField);
        }
        if (values.timeEnd) {
            group.fields.push({
                fieldName: 'timeEnd',
                operator: SearchOperator.LE,
                value: values.timeEnd
            } as SearchField);
        }
        if (values.broadcastType) {
            group.fields.push({
                fieldName: 'broadcastType.id',
                operator: SearchOperator.EQ,
                value: values.broadcastType
            } as SearchField);
        }
        if (values.country) {
            group.fields.push({
                fieldName: 'country.id',
                operator: SearchOperator.EQ,
                value: values.country
            } as SearchField);
        }
        if (values.channel) {
            group.fields.push({
                fieldName: 'channel.id',
                operator: SearchOperator.EQ,
                value: values.channel
            } as SearchField);
        }
        if (values.broadcastStatus) {
            if (values.broadcastStatus !== BroadcastStatus.NA) {
                group.fields.push({
                    fieldName: 'status',
                    operator: SearchOperator.EQ,
                    value: values.broadcastStatus
                } as SearchField);
            }
        }
        if (workId) {
            group.fields.push({
                fieldName: 'work.id',
                operator: SearchOperator.EQ,
                value: workId
            } as SearchField);
        }
        if (values.workLevel) {
            group.fields.push({
                fieldName: 'work.level',
                operator: SearchOperator.EQ,
                value: values.workLevel
            } as SearchField);
        }
        if (values.workType) {
            group.fields.push({
                fieldName: 'work.workType',
                operator: SearchOperator.EQ,
                value: values.workType
            } as SearchField);
        }
        if (values.workStatus && values.workStatus != 'NA') {
            console.log(values.workStatus)
            group.fields.push({
                fieldName: 'work.status',
                operator: SearchOperator.EQ,
                value: values.workStatus
            } as SearchField);
        }

        return group;
    }

    downloadReport() {
        this.broadcastService.downloadTableExport(RestSearchUtil.createMatSearchRequest(this.defaultPagination(), this.prepareRestSearch({ ...this.filterForm.getRawValue() })));
    }

    defaultListForm(): UntypedFormGroup {
        return this.filterForm;
    }

    defaultPagination(): Pagination {
        return new Pagination(0, this.pageSizeOptions[0], 'id', 'asc');
    }

    statusIcon(status: BroadcastStatus) {
        if (status === BroadcastStatus.APPROVED) {
            return 'done';
        } else {
            return 'clear';
        }
    }

    showRepartitionIds(repartitinoIds: number[]) {
        return repartitinoIds.join();
    }
}
