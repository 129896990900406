import {Component, Inject, ViewChild} from '@angular/core';
import {Specification} from '../../model/specification';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Repartition} from '../../model/repartition';
import {SpecificationLinesComponent} from '../../component/specification-lines-table/specification-lines.component';
import {Router} from '@angular/router';
import {SpecialTreatmentRepartition} from '../../enums/special-treatment-repartition.enum';
import { NgIf, UpperCasePipe, DatePipe } from '@angular/common';
import { FinancialDataComponent } from '../../component/financial-data/financial-data.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SpecificationComponent } from '../../component/credit-nota/specification.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-specification-dialog',
    templateUrl: './specification-dialog.component.html',
    styleUrls: ['./specification-dialog.component.scss'],
    standalone: true,
    imports: [MatCardModule, TranslateModule, MatButtonModule, MatTooltipModule, MatIconModule, SpecificationComponent, MatExpansionModule, FinancialDataComponent, NgIf, SpecificationLinesComponent, UpperCasePipe, DatePipe]
})
export class SpecificationDialogComponent {
    specification: Specification;
    repartition: Repartition;
    showSpecificationLines = true;

    @ViewChild(SpecificationLinesComponent)
    specificationLinesTableComponent: SpecificationLinesComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<SpecificationDialogComponent>,
        private router: Router
    ) {
        this.specification = data.specification;
        this.repartition = data.repartition;
        if (this.repartition.specialTreatment === SpecialTreatmentRepartition.NOT_SPECIFIED) {
            this.showSpecificationLines = false;
        }
    }

    get amountMinusCosts() {
        if (this.specificationLinesTableComponent != null) {
            return this.specificationLinesTableComponent.amountMinusCosts;
        }
        return 0;
    }

    goToSpecification() {
        this.dialogRef.close();
        this.router.navigate(['/specifications', this.specification.id]);
    }

    close() {
        this.dialogRef.close();
    }
}
