import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { FairPeriod } from 'src/app/shared/model/fair-period';
import { FairRepertoireRule } from 'src/app/shared/model/fair-repertoire-rule';
import { FairService } from 'src/app/shared/service/fair.service';
import { ToastrService } from 'src/app/shared/service/toastr.service';
import {
  MatDialogRef,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { FairPeriodStatus } from 'src/app/shared/enums/fair-period-status.enum';

@Component({
  selector: 'app-create-period-dialog',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatFormFieldModule, ReactiveFormsModule,
    MatDatepickerModule, MatInputModule, MatSelectModule, MatIconModule, MatProgressBarModule,
    MatDialogContent, MatDialogActions],
  templateUrl: './create-period-dialog.component.html',
  styleUrl: './create-period-dialog.component.scss'
})
export class CreatePeriodDialogComponent {
  readonly minDate = new Date();
  creating = false;
  rules: FairRepertoireRule[];

  periodForm: FormGroup;
  fairPeriod: FairPeriod;

  constructor(
    private dialogRef: MatDialogRef<CreatePeriodDialogComponent>,
    private fairService: FairService,
    private toastrService: ToastrService,
    formBuilder: FormBuilder,
  ) {
    this.periodForm = formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
      minKDH: ['0', [Validators.required, Validators.min(0.0)]],
      minPoints: ['0', [Validators.required, Validators.min(0.0)]],
      description: ['', Validators.required],
      repertoireRule: [null, Validators.required],
      firstPayment: [this.minDate, Validators.required],
      calcAmount: ['10000', [Validators.required, Validators.min(10000)]],
      status: FairPeriodStatus.NA
    })
  }

  ngOnInit(): void {
    this.fairService.getRepertoireRules().subscribe(data => {
      this.rules = data;
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  resetForm() {
    this.periodForm.reset();
  }

  createPeriod(): void {
    var fairPeriod = this.periodForm.getRawValue();
    const title = 'Aanmaken repertoire'
    this.toastrService.info('Aanmaken repertoire gestart...', title);
    this.creating = true;
    this.fairService.createRepertoire(fairPeriod).subscribe((result) => {
      this.creating = false;
      if (result.status === FairPeriodStatus.CREATED) {
        this.toastrService.success('Aanmaken geslaagd', title);
      } else {
        this.toastrService.error(`Aanmaken mislukt`, title);
      }
      this.dialogRef.close(result);
    });
  }
}
