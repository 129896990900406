import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RightHolder } from '../model/rightholder';
import { User } from '../model/user';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private endpoint = `${environment.api_endpoint}/users`;

    constructor(private httpClient: HttpClient) {
    }

    public findUser(email: string, username: string): Observable<User> {
        return this.httpClient.get<User>(`${this.endpoint}/${email}/${username}`);
    }

    public defaultRightHolder(): Observable<RightHolder> {
        return this.httpClient.get<RightHolder>(`${this.endpoint}/current/rightholders/default`);
    }

    public allowedRightHolders(): Observable<RightHolder[]> {
        return this.httpClient.get<RightHolder[]>(`${this.endpoint}/current/rightholders/allowed`);
    }

    public updateLastLogin(username: string) {
        this.httpClient.get<void>(`${this.endpoint}/lastlogin`).subscribe(() => {
        }, error => {
            console.error(`[UserService] error update last login for: ${username}`, error.error);
        });
    }

    public syncToKeycloak(username: string) {
        this.httpClient.get<void>(`${this.endpoint}/sync/${username}`).subscribe(() => {
        }, error => {
            console.error(`[UserService] error while sync to keycloak: ${username}`, error.error);
        });
    }

    public hasOTP(username: string): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.endpoint}/otp/${username}`);
    }

    public removeOTP(username: string): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.endpoint}/otp/${username}`);
    }

    public block(username: string): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.endpoint}/block`, username);
    }
    public unblock(username: string): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.endpoint}/unblock`, username);
    }

}
