import {NgModule} from '@angular/core';
import {YesNoDialogComponent} from './yes-no/yes-no-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {CreateLedgerDialogComponent} from './create-ledger/create-ledger-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {DirectivesModule} from '../directives/directives.module';
import {SpecificationDialogComponent} from './specification-dialog/specification-dialog.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared.module';
import {SharedComponentsModule} from '../component/shared-components.module';
import {RouterModule} from '@angular/router';
import {ConflictClaimDialogComponent} from './conflict-claim-dialog/conflict-claim-dialog.component';
import {ClaimDecisionComponent} from './conflict-claim-dialog/claim-decision/claim-decision.component';
import {ConflictHistoryComponent} from './conflict-claim-dialog/conflict-history/conflict-history.component';
import {ConflictFilesComponent} from "./conflict-claim-dialog/conflict-files/conflict-files.component";
import {HelpDialogComponent} from "./conflict-claim-dialog/help-dialog/help-dialog.component";

const dialogs = [
    CreateLedgerDialogComponent,
    YesNoDialogComponent,
    SpecificationDialogComponent,
    ConflictClaimDialogComponent,
    HelpDialogComponent,
];

@NgModule({
    imports: [
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        DirectivesModule,
        CommonModule,
        SharedModule,
        SharedComponentsModule,
        RouterModule,
        ...dialogs,
        ClaimDecisionComponent,
        ConflictHistoryComponent,
        ConflictFilesComponent
    ],
    providers: [],
    exports: [
        ...dialogs
    ]
})
export class DialogModule {
}
