<div class="financial-data-div">
    <table>
        <tbody>
        <tr>
            <td>{{'label.financialdata.BankType' | uppercase | translate}}</td>
            <td class="cell-align-right"></td>
            <td>{{'label.BankType.' +  financialdata?.bankType | uppercase | translate}}</td>
        </tr>
        <tr>
            <td>{{'label.financialdata.BankName' | uppercase | translate}}</td>
            <td class="cell-align-right"></td>
            <td>{{financialdata?.bankName}}</td>
        </tr>
        <tr>
            <td>{{'label.financialdata.AccountName' | uppercase | translate}}</td>
            <td class="cell-align-right"></td>
            <td>{{financialdata?.accountName}}</td>
        </tr>
        <tr>
            <td>{{'label.financialdata.AccountNumber' | uppercase | translate}}</td>
            <td class="cell-align-right"></td>
            <td>{{financialdata?.accountNumber}}</td>
        </tr>
        <tr>
            <td>{{'label.financialdata.VatObliged' | uppercase | translate}}</td>
            <td class="cell-align-right"></td>
            <td><mat-icon svgIcon="check" *ngIf="financialdata?.vatObliged"></mat-icon></td>
        </tr>
        </tbody>
    </table>
</div>
