import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CacheService} from '../../service/cache.service';
import {Genre} from '../../model/genre';
import {Category} from '../../model/category';
import {WorkType} from '../../model/work-type';
import {ActivatedRoute} from '@angular/router';
import {WorkStatus} from '../../enums/work-status.enum';
import {timer} from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, UpperCasePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-work-list-filter',
    templateUrl: 'work-list-filter.component.html',
    styleUrls: ['work-list-filter.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatInputModule, MatSelectModule, NgFor, MatOptionModule, TranslateModule, MatButtonModule, UpperCasePipe]
})
export class WorkListFilterComponent implements OnInit {
    filterForm: UntypedFormGroup;

    categories: Category[];
    genres: Genre[];
    workStatus: string[] = Object.keys(WorkStatus);
    workTypes: WorkType[];

    @Input() cachePrefix = 'work-list';

    @Output() search: EventEmitter<any> = new EventEmitter<any>();
    @Output() reset: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private route: ActivatedRoute,
        private cacheService: CacheService
    ) {
        this.workTypes = this.route.snapshot.data.workTypes;
        this.genres = this.route.snapshot.data.genres;
        this.categories = this.route.snapshot.data.categories;
    }

    ngOnInit(): void {
        this.createSearchForm();
    }

    createSearchForm() {
        const defaultForm = new UntypedFormGroup({
            searchIn: new UntypedFormControl(),
            id: new UntypedFormControl(),
            workTitleTitle: new UntypedFormControl(),
            workTitleSubTitle: new UntypedFormControl(),
            //'workTitle.title': new UntypedFormControl(),
            //'workTitle.subTitle': new UntypedFormControl(),
            isan: new UntypedFormControl(),
            agicoa: new UntypedFormControl(),
            //'genre.id': new UntypedFormControl(),
            genreId: new UntypedFormControl(),
            //'category.id': new UntypedFormControl(),
            categoryId: new UntypedFormControl(),
            //'workType.id': new UntypedFormControl(),
            workTypeId: new UntypedFormControl(),
            level: new UntypedFormControl(1),
            //'workStatus.id': new UntypedFormControl(2),
            workStatusId: new UntypedFormControl(2),
            //'broadcast.year': new UntypedFormControl()
            broadcastYear: new UntypedFormControl()
        });

        this.filterForm = this.cacheService.get(`${this.cachePrefix}-filter`, defaultForm).item;
    }

    public patch(filter: any) {
        if (this.filterForm) {
            // Filter form is already available
            this.filterForm.patchValue(filter);
        } else {
            // Filter form is not yet available (called from ngOnInit other component)
            timer(300).subscribe(() => {
                this.filterForm.patchValue(filter);
            });
        }
    }

    doSearch() {
        this.search.emit(this.filterForm.getRawValue());
    }

    doReset() {
        this.cacheService.delete(`${this.cachePrefix}-filter`);
        this.createSearchForm();
        this.reset.emit(true);
    }
}
