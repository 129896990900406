import {Injectable} from '@angular/core';
import {BaseService} from '../base/base-service';
import {HttpClient} from '@angular/common/http';
import {Template} from "../model/template";

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends BaseService<Template> {
  constructor(httpClient: HttpClient) {
    super(httpClient, 'template');
  }

  create(): Template {
    return {};
  }
}
