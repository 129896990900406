import {Component, Input} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-loading-shade',
    templateUrl: './loading-shade.component.html',
    styleUrls: ['./loading-shade.component.scss'],
    standalone: true,
    imports: [NgIf, MatProgressSpinnerModule]
})
export class LoadingShadeComponent {
    @Input() loading: boolean;
    @Input() loadingText = '';

    constructor() {
    }
}
