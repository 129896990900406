export class WorkUtil {
    public static workLevelString(level: number): string {
        switch (level) {
            case 1: {
                return 'Hoofdfilm';
            }
            case 2: {
                return 'Seizoen';
            }
            case 3: {
                return 'Episode';
            }
        }
    }
}
