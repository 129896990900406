import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {WorkTitle} from '../../shared/model/work-title';

@Injectable({
    providedIn: 'root'
})
export class WorkTitleService {
    private endpoint = `${environment.api_endpoint}/worktitles`;

    constructor(private httpClient: HttpClient) {
    }

    public findById(id: number): Observable<WorkTitle> {
        return this.httpClient.get<WorkTitle>(`${this.endpoint}/${id}`);
    }

    public findByWork(work: number): Observable<WorkTitle[]> {
        return this.httpClient.get<WorkTitle[]>(`${this.endpoint}/byWork/${work}`);
    }

    public findByWorks(workIdSet: Set<number>): Observable<WorkTitle[]> {
        const workIdList = Array.from(workIdSet).map(value => '' + value);
        return this.httpClient.get<WorkTitle[]>(`${this.endpoint}/original`, {
            params: {
                workId: workIdList
            }
        });
    }

    public save(workTitle: WorkTitle): Observable<WorkTitle> {
        return this.httpClient.post<WorkTitle>(this.endpoint, workTitle);
    }

    public create(workTitle: WorkTitle): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.endpoint}/create`, workTitle);
    }

    public update(workTitle: WorkTitle): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.endpoint}/${workTitle.id}`, workTitle);
    }

    public delete(worktitle: number): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.endpoint}/${worktitle}`);
    }

}
