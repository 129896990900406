<mat-card class="middle" appearance="raised">
    <mat-card-header>
        <mat-card-title>FAIR periodes</mat-card-title>
    </mat-card-header>
    <form [formGroup]="filterForm" (ngSubmit)="filter()">
        <div class="container">
            <div class="filters">
                <mat-form-field appearance="outline">
                    <mat-label>Jaar</mat-label>
                    <input type="number" matInput formControlName="year">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                        @for (status of statuses; track $index) {
                        <mat-option [value]="status">
                            {{statusLabel(status)}}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Omschrijving</mat-label>
                    <input type="text" matInput formControlName="description">
                </mat-form-field>

                <button type="submit" mat-stroked-button color="primary">Filteren</button>
                <button type="button" mat-stroked-button (click)="reset()">Reset</button>
            </div>

            <div>
                <button mat-raised-button color="primary" (click)="openDialog()"
                    class="mat-elevation-z1">Aanmaken</button>
            </div>
        </div>
    </form>

</mat-card>

<main>
    <div class="cards">
        @for (fairperiod of filterPeriods; track $index) {
        <app-period [period]="fairperiod"></app-period>
        }
    </div>
</main>
