import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RepartitionConflict } from '../../../model/repartition-conflict';
import { BaseSearchComponent } from 'src/app/shared/base/base-search-component';
import { UntypedFormGroup } from '@angular/forms';
import { SearchFieldGroup } from 'src/app/shared/base/search/search-field-group';
import { Pagination } from 'src/app/shared/model/pagination';
import { UntypedFormBuilder } from '@angular/forms';
import { CacheService } from 'src/app/shared/service/cache.service';
import { SearchOperator } from 'src/app/shared/base/search/search-operator.enum';
import { SearchField } from 'src/app/shared/base/search/search-field';
import { RightHolderService } from "../../../../rightholder/service/rightholder.service";
import { RepartitionConflictAsset } from "../../../model/repartition-conflict-asset";
import { RepartitionConflictAssetService } from "../../../service/repartition-conflict-asset.service";
import { RightHolder } from "../../../model/rightholder";
import { HtmlUtil } from 'src/app/shared/util/html.util';
import { DocumentType } from 'src/app/shared/enums/document-type.enum';
import { RepartitionConflictService } from 'src/app/shared/service/repartition-conflict-service';
import { ConflictAssetStatus } from 'src/app/shared/enums/conflict-asset-status';
import { ToastrService } from 'src/app/shared/service/toastr.service';
import { ConflictStatus } from 'src/app/shared/enums/conflict-status.enum';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, DatePipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'app-conflict-files',
    templateUrl: './conflict-files.component.html',
    styleUrls: ['./conflict-files.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, NgIf, MatProgressSpinnerModule, MatTableModule, MatSortModule, MatButtonModule, MatTooltipModule, MatIconModule, MatPaginatorModule, DatePipe]
})

export class ConflictFilesComponent extends BaseSearchComponent<RepartitionConflictAsset> implements OnInit {

    displayedColumns = [
        'date',
        'rightholder',
        'document',
        'documentType',
        'status',
        'actions'

    ];
    pageSizeOptions: number[] = [5, 10];

    @Input() conflict: RepartitionConflict;
    @Input() rightHolder: RightHolder;
    @Output() assetEvaluateEvent = new EventEmitter<boolean>();

    assets: RepartitionConflictAsset[] = [];
    loading: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private rightHolderService: RightHolderService,
        private assetService: RepartitionConflictAssetService,
        private conflictService: RepartitionConflictService,
        private toastService: ToastrService,
        cacheService: CacheService) {
        super(assetService, cacheService, 'conflict-files');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.search();
    }

    defaultListForm(): UntypedFormGroup {
        return this.formBuilder.group({
            conflict: this.conflict.id
        });
    }

    defaultPagination(): Pagination {
        return new Pagination(0, this.pageSizeOptions[0], 'lastModified', 'desc');
    }

    prepareRestSearch(values: any): SearchFieldGroup {
        const group: SearchFieldGroup = {
            type: 'AND',
            fields: [],
            groups: []
        };

        group.fields.push({
            fieldName: 'conflict.id',
            operator: SearchOperator.EQ,
            value: this.conflict.id,
        } as SearchField);

        return group;
    }

    canSolve(asset: RepartitionConflictAsset): boolean {

        if (asset.status !== ConflictAssetStatus.INIT) {
            return false;
        }

        const status = this.conflict.conflictStatus.conflictStatus;

        if (status === ConflictStatus.PHASE_TWO_SBF) {
            return asset.asset.documentType === DocumentType.SETTLEMENT_AGREEMENT;
        }

        if (status === ConflictStatus.PENDING_ADVISOR || status === ConflictStatus.PENDING_LAWSUIT) {
            return asset.asset.documentType === DocumentType.JUDGMENT;
        }

        return false;
    }

    canPause(asset: RepartitionConflictAsset): boolean {
        return (asset.asset.documentType === DocumentType.REQUEST_ADVISOR || asset.asset.documentType === DocumentType.SUMMONS) &&
            asset.status === ConflictAssetStatus.INIT;
    }

    canApprove(asset: RepartitionConflictAsset): boolean {
        return !this.canSolve(asset) && !this.canPause(asset) && asset.status === ConflictAssetStatus.INIT;
        // return !(asset.asset.documentType === DocumentType.REQUEST_ADVISOR || asset.asset.documentType === DocumentType.SUMMONS) &&
        //     asset.status === ConflictAssetStatus.INIT;
    }

    canReject(asset: RepartitionConflictAsset): boolean {
        return asset.status === ConflictAssetStatus.INIT;
    }


    downloadDocument(asset: RepartitionConflictAsset) {
        this.rightHolderService.download(asset.rightHolder.id, asset.asset);
    }

    approve(asset: RepartitionConflictAsset) {
        this.loading = true;
        this.assetService.approve(asset).subscribe(value => {
            this.search();
            this.loading = false;
            this.assetEvaluateEvent.emit(true);
        });
    }

    reject(asset: RepartitionConflictAsset) {
        this.loading = true;
        this.assetService.reject(asset).subscribe(value => {
            this.search();
            this.loading = false;
            this.assetEvaluateEvent.emit(true);
        });
    }

    pauseConflict(asset: RepartitionConflictAsset) {
        this.assetService.approve(asset).subscribe(() => {
            this.search();
            this.conflictService.pause(this.conflict).subscribe(() => {
                this.loading = false;
                this.toastService.success("Conflict is gepauzeerd", "Pauzeren");
            }, error => {
                this.toastService.error(`Fout: ${error.status}`, "Fout bij pauzeren");
            }, () => {
                this.assetEvaluateEvent.emit(true);
            });
        });
    }

    get htmlUtil() {
        return HtmlUtil;
    }
}
