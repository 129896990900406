import {Component, OnDestroy} from '@angular/core';
import {SessionService} from '../../shared/service/session.service';
import {Subscription} from 'rxjs';
import {DownloadFiles} from '../../rightholder/enum/download-files.enum';
import {FileService} from '../../shared/service/file.service';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
@Component({
    selector: 'app-menu-user',
    templateUrl: 'menu-user.component.html',
    styleUrls: ['menu-user.component.scss'],
    standalone: true,
    imports: [MatIconModule,MatButtonModule,MatMenuModule,MatTooltipModule,MatInputModule]
})
export class MenuUserComponent implements OnDestroy {
    currentUser: string;
    downloadFiles = DownloadFiles;
    usernameSubscription: Subscription;

    constructor(
        private sessionService: SessionService,
        private fileService: FileService
    ) {
        this.usernameSubscription = sessionService.userName$.subscribe(value => {
            this.currentUser = value;
        });
    }

    ngOnDestroy(): void {
        this.usernameSubscription.unsubscribe();
    }

    downloadFile(file: DownloadFiles) {
        this.fileService.downloadFile(file);
    }

    logout() {
        this.sessionService.logout();
    }
}
