<button mat-icon-button type="button" (click)="downloadFile(downloadFiles.FRED_USER_GUIDE)" matTooltip="FReD Gebruiksaanwijzing">
    <mat-icon matSuffix>help</mat-icon>
</button>
<button [matMenuTriggerFor]="menuUser" mat-icon-button [matTooltip]="currentUser" type="button">
    <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #menuUser="matMenu">
    <button (click)="logout()" mat-menu-item>Uitloggen</button>
</mat-menu>
