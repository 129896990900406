<header class="header header-app">
    <mat-toolbar>
        <mat-toolbar-row>
            <div class="sbf-image" style="padding-right: 1em;">
                <img [routerLink]="''" alt="SBF" class="logo" src="./assets/images/sbf_logo.png">
            </div>
            <app-menu-default *ngIf="!isManaged"></app-menu-default>
            <app-menu-right-holder-managed *ngIf="isManaged"></app-menu-right-holder-managed>
            <div class="spacer"></div>
            <app-menu-user></app-menu-user>
            <img *ngIf="isFair" class="logo" width="100" src="./assets/images/fairlogo.png" alt="FAIR" (click)="external('https://faircbo.nl/')">
        </mat-toolbar-row>
    </mat-toolbar>
</header>

<main class="main main-app">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <router-outlet></router-outlet>
</main>
