import {NgModule} from '@angular/core';
import {OnlyNumberDirective} from './only-number.directive';
import {AutofocusDirective} from './auto-focus.directive';
import {DragDropDirective} from './drag-drop.directive';

const directives = [
    AutofocusDirective,
    DragDropDirective,
    OnlyNumberDirective
];


@NgModule({
    imports: [...directives],
    exports: [
        ...directives
    ]
})
export class DirectivesModule {
}
