
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeneralErrorComponent } from './general-error/general-error.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SwitchComponent } from './switch/switch.component';
import { AuthenticationGuard } from './shared/guard/authentication.guard';
import { Roles } from './shared/enums/roles.enum';
import { FairperiodResolverService } from './faircbo/resolvers/fairperiod-resolver.service';
import { RepertoireListComponent } from './faircbo/period/repertoire-list/repertoire-list.component';
import { FairComponent } from './faircbo/fair.component';
import { PeriodClaimListComponent } from './faircbo/period/claim-list/period-claim-list.component';
import { PeriodlistComponent } from './fair/period/list/periodlist/periodlist.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'works',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.WORK_VIEW]
        },
        loadChildren: () => import('./work/work.module').then(m => m.WorkModule),
    },
    {
        path: 'broadcasts',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.BROADCAST_VIEW]
        },
        loadChildren: () => import('./broadcast/broadcast.module').then(m => m.BroadcastModule)
    },
    {
        path: 'channels',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.SBF_DATAMANAGEMENT]
        },
        loadChildren: () => import('./channel/channel.module').then(m => m.ChannelModule)
    },
    {
        path: 'templates',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.SBF_DATAMANAGEMENT]
        },
        loadChildren: () => import('./template/template.module').then(m => m.TemplateModule)
    },
    {
        path: 'claims',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.CLAIMS_VIEW]
        },
        loadChildren: () => import('./claim/claim.module').then(m => m.ClaimModule)
    },
    {
        path: 'rightholders',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.RIGHTHOLDER_VIEW]
        },
        loadChildren: () => import('./rightholder/rightholder.module').then(m => m.RightHolderModule),
    },
    {
        path: 'repartitions',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.REPARTITION_VIEW]
        },
        loadChildren: () => import('./repartition/repartition.module').then(m => m.RepartitionModule)
    },
    {
        path: 'specifications',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.RIGHTHOLDER_VIEW]
        },
        loadChildren: () => import('./specification/specification.module').then(m => m.SpecificationModule)
    },
    {
        path: 'importfiles',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.IMPORTFILES_VIEW]
        },
        loadChildren: () => import('./importfile/importfile.module').then(m => m.ImportfileModule),
    },
    {
        path: 'exports',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.EXPORT_VIEW]
        },
        loadChildren: () => import('./export/export.module').then(m => m.ExportModule)
    },
    {
        path: 'prospects',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [
                Roles.SBF_DATAMANAGEMENT,
                Roles.SBF_DATAENTRY,
                Roles.SBF_GENERALMANAGEMENT,
                Roles.SBF_CLAIMMANAGEMENT,
                Roles.SBF_ACCOUNTMANAGEMENT
            ]
        },
        loadChildren: () => import('./prospect/prospect.module').then(m => m.ProspectModule)
    },
    {
        path: 'maintenance',
        canActivate: [AuthenticationGuard],
        data: {
            roles: [Roles.MAINTENANCE, Roles.MAINTENANCE_REPORTS]
        },
        loadChildren: () => import('./management-and-control/management-and-control.module').then(m => m.ManagementAndControlModule)
    },
    {
        path: 'faircbo',
        canActivate: [AuthenticationGuard],
        resolve: {
            fairperiods: FairperiodResolverService
        },
        component: FairComponent,
        /* TODO:
        data: {
            roles: [Roles.SBF_DATAMANAGEMENT, Roles.FAIR_bla, Roles.PIETER]
        },
        */
    },
    {
        path: 'faircbo/period/items',
        component: RepertoireListComponent
    },
    {
        path: 'fair',
        canActivate: [AuthenticationGuard],
        children: [
            {
                path: 'period/list',
                canActivate: [AuthenticationGuard],
                data: {
                    roles: [Roles.SBF_DATAMANAGEMENT]
                },
                pathMatch: 'full',
                component: PeriodlistComponent,
            }
        ]
        /* TODO:
        data: {
            roles: [Roles.SBF_DATAMANAGEMENT, Roles.FAIR_bla, Roles.PIETER]
        },
        */
    },
    {
        path: 'oops',
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: GeneralErrorComponent
            },
            {
                path: ':status',
                component: GeneralErrorComponent
            }
        ]
    },
    {
        path: 'switch', component: SwitchComponent,
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }), GeneralErrorComponent,
        NotFoundComponent,
        SwitchComponent],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
