import {MatPaginatorIntl} from '@angular/material/paginator';

const rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
        return `0 van ${length}`;
    }

    // Calculate the startIndex
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    length = Math.max(length, 0);
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} van ${length}`;
};


export function getPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = 'Regels per pagina:';
    paginatorIntl.firstPageLabel = 'Eerste pagina';
    paginatorIntl.previousPageLabel = 'Vorige pagina';
    paginatorIntl.nextPageLabel = 'Volgende pagina';
    paginatorIntl.lastPageLabel = 'Laatste pagina';
    paginatorIntl.getRangeLabel = rangeLabel;
    return paginatorIntl;
}
