import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { Work } from '../../shared/model/work';
import { Asset } from '../../shared/model/asset';
import { WorkDetailsPopup } from '../../shared/model/work-details-popup';
import { ApiUtil } from '../../shared/util/api.util';
import { WorksWithClaims } from '../../claim/model/works-with-claims';
import { DatePipe } from '@angular/common';
import { MaintenanceResult } from '../../management-and-control/shared/maintenance-result';
import { ToastrService } from '../../shared/service/toastr.service';
import { BaseService } from '../../shared/base/base-service';
import { SearchRequest } from '../../shared/base/search/search-request';
import { WorkChildren } from '../../shared/model/work-children';


@Injectable({
    providedIn: 'root'
})
export class WorkService extends BaseService<Work> {
    constructor(
        httpClient: HttpClient,
        private datePipe: DatePipe,
        private toastrService: ToastrService
    ) {
        super(httpClient, 'works');
    }

    create(): Work {
        return {};
    }

    public save(work: Work): Observable<Work> {
        return this.httpClient.post<Work>(this.endpoint, work);
    }

    public createWork(work: Work): Observable<number> {
        return this.httpClient.post<number>(`${this.endpoint}`, work);
    }

    public findByRightHolder(rightholder: number): Observable<Work[]> {
        return this.httpClient.get<Work[]>(`${this.endpoint}/byRightHolder/${rightholder}`);
    }

    public findByRightHolderWithProspects(rightholder: number): Observable<Work[]> {
        return this.httpClient.get<Work[]>(`${this.endpoint}/byRightHolderWithProspects/${rightholder}`);
    }

    public merge(toKeep: number, toMerge: number): Observable<boolean> {
        const httpParams: HttpParams = new HttpParams().append('workToKeep', toKeep.toString()).append('workToMerge', toMerge.toString());
        return this.httpClient.get<boolean>(`${this.endpoint}/merge`, {
            headers: new HttpHeaders({ timeout: `${100000}` }),
            params: httpParams
        });
    }

    public move(toKeep: number, toMove: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.endpoint}/${toKeep}/move/${toMove}`, {
            headers: new HttpHeaders({ timeout: `${100000}` })
        });
    }

    public findDetails(work: number): Observable<WorkDetailsPopup> {
        return this.httpClient.get<WorkDetailsPopup>(`${this.endpoint}/detailspopup/${work}`);
    }

    public upload(work: Work, file: any): Observable<Asset> {
        const form = new FormData();
        form.append('file', file, file.name);
        return this.httpClient.post<Asset>(`${this.endpoint}/${work.id}/upload`, form);
    }

    public removeAsset(asset: Asset): Observable<boolean> {
        return this.httpClient.delete<boolean>(`${this.endpoint}/asset/${asset.uuid}`);
    }

    public downloadTableExport(searchRequest: SearchRequest): Promise<boolean> {
        this.toastrService.info("Ophalen werklijst", "Download");
        return new Promise<boolean>((resolve, reject) => {
            this.httpClient.post(`${this.endpoint}/tableExport`, searchRequest, {
                responseType: 'blob',
                observe: 'response',
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).subscribe(response => {
                ApiUtil.saveToFileSystem(response);
                this.toastrService.clear();
                resolve(true);
                timer(400).subscribe(() => {
                    this.toastrService.success('Werklijst opgehaald', null);
                });

            }, response => {
                console.error(`[WorkService] error getting tableExport`, response.error);
                this.toastrService.clear();
                reject(response.statusText);
                timer(400).subscribe(() => {
                    this.toastrService.error('Werklijst kon niet worden opgehaald', `[${response.status}] Error`);
                });
            });
        });
    }

    public findAllAssetsByWork(id: number): Observable<Asset[]> {
        return this.httpClient.get<Asset[]>(`${this.endpoint}/${id}/assets`);
    }

    public findWorksWithClaims(page: number): Observable<WorksWithClaims> {
        return this.httpClient.get<WorksWithClaims>(`${this.endpoint}/worksWithClaims/${page}`);
    }

    public percentage(workId: number, rightTypeId: number, validFrom: Date, validUntil: Date, claimId: number): Observable<number> {
        let params = new HttpParams();
        if (validFrom) {
            params = params.append('from', this.datePipe.transform(validFrom, 'yyyy-MM-dd'));
        }
        if (validUntil) {
            params = params.append('until', this.datePipe.transform(validUntil, 'yyyy-MM-dd'));
        }
        if (claimId) {
            params = params.append('skipeClaimId', claimId.toString());
        }

        return this.httpClient.get<number>(`${this.endpoint}/${workId}/percentage/${rightTypeId}`, {
            params
        });
    }

    public moveEpisodes(file: any): Observable<MaintenanceResult> {
        const form = new FormData();
        form.append('file', file);
        return this.httpClient.post<MaintenanceResult>(`${this.endpoint}/bulkMove`, form);
    }

    public findParentsForWork(id: number): Observable<Work[]> {
        return this.httpClient.get<Work[]>(`${this.endpoint}/${id}/parents`);
    }

    public transformToSeries(id: number): Observable<void> {
        return this.httpClient.get<void>(`${this.endpoint}/${id}/toSeries`);
    }

    public transformToMain(id: number): Observable<void> {
        return this.httpClient.get<void>(`${this.endpoint}/${id}/toMain`);
    }

    public findAllByParent(id: number): Observable<WorkChildren[]> {
        return this.httpClient.get<WorkChildren[]>(`${this.endpoint}/${id}/children`);
    }
}
