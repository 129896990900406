import { Component, OnDestroy } from '@angular/core';
import { Roles } from '../../shared/enums/roles.enum';
import { RightHolder } from '../../shared/model/rightholder';
import { SessionService } from '../../shared/service/session.service';
import { Subscription } from 'rxjs';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import {RouterLink, RouterLinkActive} from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-menu-right-holder-managed',
    templateUrl: 'menu-right-holder-managed.component.html',
    styleUrls: ['../menu-common.scss', 'menu-right-holder-managed.component.scss'],
    standalone: true,
    imports: [NgIf, MatMenuModule, MatFormFieldModule, MatInputModule, NgxPermissionsModule, MatButtonModule,
        MatTooltipModule, NgClass, RouterLink, MatIconModule, NgFor, RouterLinkActive]
})
export class MenuRightHolderManagedComponent implements OnDestroy {
    roles: typeof Roles = Roles;

    allowedRightHolders: RightHolder[];
    currentRightHolder: RightHolder;

    allowedRightHoldersSubscription: Subscription;
    rightHolderSubscription: Subscription;

    get isSBF(): boolean {
        return this.sessionService.isSBF;
    }

    constructor(
        public sessionService: SessionService
    ) {
        this.allowedRightHoldersSubscription = sessionService.allowedRightHolders$.subscribe(value => {
            this.allowedRightHolders = value;
        });

        this.rightHolderSubscription = sessionService.rightHolder$.subscribe(value => {
            this.currentRightHolder = value;
        });
    }

    ngOnDestroy(): void {
        this.allowedRightHoldersSubscription.unsubscribe();
        this.rightHolderSubscription.unsubscribe();
    }

    switchRightHolder(rightHolder: RightHolder) {
        this.sessionService.switchRightHolder(rightHolder);
    }

    isRightHolderActive(rightHolder: RightHolder) {
        if (this.currentRightHolder != null) {
            return this.currentRightHolder.id === rightHolder.id;
        }
    }

    resetManagedRightHolder() {
        localStorage.removeItem('impersonatedRightHolder');
        this.sessionService.switchRightHolder(this.sessionService.defaultRightHolder);
    }
}
