import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-general-error',
    templateUrl: './general-error.component.html',
    styleUrls: ['./general-error.component.scss'],
    standalone: true
})
export class GeneralErrorComponent implements OnInit {
    errorMessage = 'Er is iets misgegaan!';

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit() {
        const status = +this.route.snapshot.paramMap.get('status');
        if (status != null || status === 403) {
            this.errorMessage = 'U heeft geen toegang tot deze pagina';
        }
    }

}
