import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FairPeriod } from 'src/app/shared/model/fair-period';
import { FairService } from 'src/app/shared/service/fair.service';
import { DatePipe, NgFor } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { FairPeriodStatusBadgeComponent } from 'src/app/shared/component/fair-period-status-badge/fair-period-status-badge.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-periodlist',
  standalone: true,
  imports: [
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    MatTableModule,
    DatePipe,
    FairPeriodStatusBadgeComponent,
    MatTooltipModule
  ],
  templateUrl: './periodlist.component.html',
  styleUrl: './periodlist.component.scss'
})
export class PeriodlistComponent implements OnInit {
  searchForm: FormGroup;
  fairPeriods: FairPeriod[] = [];

  constructor(private formBuilder: FormBuilder,
    private fairService: FairService
  ) {}

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      title: ['']
    });

    this.fairService.getAllPeriodByClaimableStatus().subscribe((periods) => {
      this.fairPeriods = periods;
      console.log(this.fairPeriods);
    });

    
  }
}
